import { defineStore } from 'pinia';

import { router } from '@/router';
import { useAlertStore } from '@/stores';

import { UserDetailsUpdate } from '@/apiclient';

import { getApiClient } from '@/apiclient/client';
import jwt_decode from 'jwt-decode';
import { getLevelFromXp, getThresholdForCurrentLevel } from '@/helper';
import * as Sentry from '@sentry/vue';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    // localStorage.getItem can be void, therefore using || so that json.parse does not receive = ill type
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null,
    token: localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token') || '{}') : null,
    returnUrl: null,
    //i18n: useI18n(),
  }),
  getters: {
    isSignedIn(state) {
      return state.token !== null;
    },
    isAdmin(state) {
      return state.user ? !!state.user.is_admin : false;
    },
    nonValidatedToken(state) {
      return state.token;
    },
    requiresOnboarding(state) {
      // check if one of the following fields is not set
      if (!state.user) {
        return false;
      }
      return (
        !state.user.first_name ||
        !state.user.last_name ||
        !state.user.ingame_language ||
        !state.user.native_language ||
        !state.user.country_of_origin
      );
    },
    userId(state) {
      return state.user ? state.user.id : '';
    },
    userFirstName(state) {
      return state.user ? state.user.first_name : '';
    },
    userLastName(state) {
      return state.user ? state.user.last_name : '';
    },
    userFullName(state) {
      return state.user ? this.userFirstName + ' ' + this.userLastName : '';
    },
    userUsername(state) {
      return state.user ? state.user.username : '';
    },
    userEmail(state) {
      return state.user ? state.user.auth_user.email : '';
    },
    userGender(state) {
      return state.user ? state.user.gender : null;
    },
    userAcademicTitle(state) {
      return state.user ? state.user.academic_title : null;
    },
    userJobStatus(state) {
      return state.user ? state.user.job_status : null;
    },
    userBiography(state) {
      return state.user ? state.user.biography : null;
    },
    userInitials(state) {
      // TS problem: https://github.com/vuejs/pinia/discussions/1076
      // return initials based on userFirstName and userLastName
      // check if length of userFirstName and userLastName is > 0
      var firstInitial = '';
      var lastInitial = '';

      if (state.user && state.user.first_name && state.user.first_name.length > 0) {
        firstInitial = state.user.first_name[0].toUpperCase();
      }
      if (state.user && state.user.last_name && state.user.last_name.length > 0) {
        lastInitial = state.user.last_name[0].toUpperCase();
      }

      return firstInitial + lastInitial;
    },
    userProfileImageSmall(state) {
      return state.user?.profile_image?.small || null;
    },
    userProfileImageMedium(state) {
      return state.user?.profile_image?.medium || null;
    },
    userProfileImageLarge(state) {
      return state.user?.profile_image?.large || null;
    },
    userNativeLanguage(state) {
      return state.user ? state.user.native_language : null;
    },
    userIngameLanguage(state) {
      return state.user ? state.user.ingame_language : null;
    },
    userCountryOfOrigin(state) {
      return state.user ? state.user.country_of_origin : null;
    },
    expertMode(state) {
      return state.user ? state.user.expert_mode : false;
    },
    textToSpeechEnabled(state) {
      return state.user ? state.user.text_to_speech_enabled : false;
    },
    // initiallyObfuscateText(state) {
    //   return state.user ? state.user.initially_obfuscate_text : false;
    // },
    // hideChatHistory(state) {
    //   return state.user ? state.user.hide_chat_history : false;
    // },
    chatEnabled(state) {
      return state.user ? state.user.chat_enabled : false;
    },
    subtitlesEnabled(state) {
      return state.user ? state.user.subtitles_enabled : false;
    },
    userXp(state) {
      return state.user ? state.user.experience_points : null;
    },
    userLevel(state) {
      return state.user ? getLevelFromXp(state.user.experience_points) : null;
    },
  },
  actions: {
    async setToken(token) {
      // this function should only be called by login function
      console.debug('Setting token ' + JSON.stringify(token));
      const expiresAt = this.getExpirationDateOfToken(token);
      console.debug('Token expires at ' + expiresAt);
      this.token = token;
      localStorage.setItem('token', JSON.stringify(token));
      await this.fetchUserDetails();
    },
    async getValidatedToken() {
      // gets token from state and will refresh token if it is about to expire
      if (!this.token) {
        return null;
      }

      // get expiration date of token
      // refresh token if it is about to expire (<= 15 minutes)
      if (this.tokenHasExpired(this.token, 15)) {
        // token has expired

        // first try to refresh token
        const refreshedToken = await this.getRefreshedToken();
        if (refreshedToken) {
          // token was refreshed, update state and return token
          await this.setToken(refreshedToken);
          console.debug('Token refreshed');
        } else {
          // cannot refresh token, redirect to login page
          this.logout('/account/sign-in');
          return null;
        }
      }
      return this.token;
    },
    tokenHasExpired(token, minMinutesToExpiration: number = 15) {
      if (!token) {
        return true;
      }

      const tokenExpiresAtDate = this.getExpirationDateOfToken(token);
      const now = new Date();
      const expired = tokenExpiresAtDate.getTime() < now.getTime() + minMinutesToExpiration * 60 * 1000;
      if (expired) {
        console.debug('Token expired/is about to expire on ' + tokenExpiresAtDate);
      }
      return expired;
    },
    getExpirationDateOfToken(token) {
      const { exp } = jwt_decode(token.access_token);
      return new Date(exp * 1000);
    },
    async login(username: string, password: string, showError: boolean = false, t: any = null) {
      try {
        console.debug('Trying to log in with ' + username + ' ' + password);
        const loginResponse = await (
          await getApiClient(false)
        ).authentication.generateOrRefreshAccessToken({
          grant_type: 'password',
          username: username,
          password: password,
        });

        // set token in local storage and fetch user details
        await this.setToken(loginResponse['token']);

        // set Sentry user context
        Sentry.setUser({
          id: this.userId,
          fullName: this.userFullName,
          email: this.userEmail,
        });

        // redirect to previous url or default to home page
        // check if users requires onboarding
        if (this.requiresOnboarding) {
          await router.push('/onboarding');
        } else {
          await router.push(this.returnUrl || '/home');
        }
        if (!!loginResponse['notifications'] && !!t) {
          const alertStore = useAlertStore();
          for (const notification of loginResponse['notifications']) {
            if (notification.type === 'XP') {
              alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
            }
            // check if login has leveled up the user. Note: this has to be handled here as changes occur before
            // user properties are set, so cannot recognize it in the components (e.g., App.vue)
            let level = getLevelFromXp(this.user.experience_points);
            if (this.user.experience_points - notification.xp - getThresholdForCurrentLevel(level) < 0) {
              alertStore.xp(t('message.levelUp', level), t('message.levelUpTitle'));
            }
          }
        }
      } catch (error) {
        console.error('Login failed');
        console.error(JSON.stringify(error));
        if (showError) {
          const alertStore = useAlertStore();
          alertStore.error(error.body?.message || 'An error occurred while logging in.');
        }

        throw error;
      }
    },
    async getRefreshedToken() {
      if (!this.token) {
        return null;
      }

      try {
        console.debug('Trying to refresh token');
        // we do not validate the token here when getting the API client
        const loginResponse = await (
          await getApiClient(false)
        ).authentication.generateOrRefreshAccessToken({
          grant_type: 'refresh_token',
          refresh_token: this.token.refresh_token,
        });

        return loginResponse['token'];
      } catch (error) {
        console.error('Refresh token failed');
        console.error(JSON.stringify(error));
        // cannot refresh token, redirect to login page
        return null;
      }
    },
    async fetchUserDetails() {
      try {
        console.debug('trying to load user details');
        const user = await (await getApiClient()).users.getCurrentUser();
        // update pinia state
        this.user = user;
        console.debug('Fetched: ' + JSON.stringify(user));

        // store user details in local storage
        localStorage.setItem('user', JSON.stringify(user));
      } catch (error) {
        console.debug('Cannot fetch user details');
        console.debug(error);
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async refetchUserDetails() {
      try {
        console.debug('trying to refetch user details');
        const user = await (await getApiClient()).users.getCurrentUser();
        // update pinia state
        this.user = user;
        console.debug('Refetched: ' + JSON.stringify(user));

        // store user details in local storage
        localStorage.setItem('user', JSON.stringify(user));
      } catch (error) {
        console.debug('Cannot refetch user details');
        console.debug(error);
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async updateUserDetails(changes: UserDetailsUpdate) {
      const alertStore = useAlertStore();
      try {
        console.debug('trying to update user details');
        console.debug(changes);
        const user = await (await getApiClient()).users.updateUser(this.user.id, changes);
        // update the users i18n language
        //this.i18n.locale.value = user.native_language;

        // update pinia state
        this.user = user;
        console.debug('Updated user details: ' + JSON.stringify(user));

        // store user details in local storage
        localStorage.setItem('user', JSON.stringify(user));
        alertStore.success('Änderungen gespeichert');
      } catch (error) {
        console.debug('Cannot update user details');
        console.debug(error);
        alertStore.error(error);
      }
    },
    async logout(redirectToPage: string = '/') {
      console.debug('Sign out user');

      this.token = null;
      this.user = null;

      localStorage.removeItem('token');
      localStorage.removeItem('user');

      await router.push(redirectToPage);

      console.debug('Signed out');
    },
  },
});
