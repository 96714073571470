<script setup>
import { useAuthStore, useLanguageStore, useCountryStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { ref, onMounted, onBeforeMount, watch, nextTick, defineProps } from 'vue';
import { getApiClient } from '@/apiclient/client';
import ProfileImage from '@/components/ProfileImage.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import { Field, Form, ErrorMessage, configure, useForm, useIsSubmitting } from 'vee-validate';
import LanguageDropdown from '@/components/LanguageDropdown.vue';
import LanguageAndCountryDropdown from '@/components/dropdowns/LanguageAndCountryDropdown.vue';
import 'vue-advanced-cropper/dist/style.css';
import { router } from '@/router';
import _ from 'lodash';
import Tooltip from '@/components/Tooltip.vue';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { useI18n } from 'vue-i18n';
import IngameProgressCard from '@/views/home/IngameProgressCard.vue';

const { t, locale } = useI18n();

const authStore = useAuthStore();
const userProfileForm = ref(null);

let initialValues = {};
const isLoading = ref(false);
const isSubmitting = ref(false);
const isUploading = ref(false);

const languageStore = useLanguageStore();
const countryStore = useCountryStore();

const { languages } = storeToRefs(languageStore);
const { countries } = storeToRefs(countryStore);

const cropper = ref(null); // Reference to the cropper component
const image = ref({ src: null, type: null });

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

// // This function is used to detect the actual image type,
// function getMimeType(file, fallback = null) {
//   const byteArray = new Uint8Array(file).subarray(0, 4);
//   let header = '';
//   for (let i = 0; i < byteArray.length; i++) {
//     header += byteArray[i].toString(16);
//   }
//   switch (header) {
//     case '89504e47':
//       return 'image/png';
//     case '47494638':
//       return 'image/gif';
//     case 'ffd8ffe0':
//     case 'ffd8ffe1':
//     case 'ffd8ffe2':
//     case 'ffd8ffe3':
//     case 'ffd8ffe8':
//       return 'image/jpeg';
//     default:
//       return fallback;
//   }
// }

// function loadImage(event) {
//   // Reference to the DOM input element
//   const { files } = event.target;
//   // Ensure that you have a file before attempting to read it
//   if (files && files[0]) {
//     // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
//     if (this.image) {
//       URL.revokeObjectURL(this.image.src);
//     }
//     // 2. Create the blob link to the file to optimize performance:
//     const blob = URL.createObjectURL(files[0]);

//     // 3. The steps below are designated to determine a file mime type to use it during the
//     // getting of a cropped image from the canvas. You can replace it them by the following string,
//     // but the type will be derived from the extension and it can lead to an incorrect result:
//     //
//     // this.image = {
//     //    src: blob;
//     //    type: files[0].type
//     // }

//     // Create a new FileReader to read this image binary data
//     const reader = new FileReader();
//     // Define a callback function to run, when FileReader finishes its job
//     reader.onload = (e) => {
//       // Note: arrow function used here, so that "this.image" refers to the image of Vue component
//       this.image.src = blob;
//       this.image.type = getMimeType(e.target.result, files[0].type);
//     };
//     // Start the reader job - read file as a data url (base64 format)
//     reader.readAsArrayBuffer(files[0]);
//   }
// }

// async function uploadImage(event) {
//   if (isUploading.value) {
//     return; // Prevent multiple submissions
//   }
//   isUploading.value = true;
//   const { canvas } = cropper.value.getResult();
//   if (canvas) {
//     canvas.toBlob(async (blob) => {
//       const data = {
//         image: blob,
//         // type: image.value.type
//       };
//       console.debug(data);
//       (await getApiClient()).users
//         .uploadProfileImage(authStore.userId, data)
//         .then(async () => {
//           console.log('successfully uploaded profile image');
//           await fetchUserDetails();
//           isUploading.value = false;
//         })
//         .catch((error) => {
//           console.log('Error while uploading profile image: ' + error);
//           isUploading.value = false;
//         });
//     }, image.value.type);
//   }
// }

// function handleProfileEdit(values, { setErrors, controlledValues }) {
//   if (isSubmitting.value) {
//     return; // Prevent multiple submissions
//   }
//   isSubmitting.value = true;

//   // transform academicTitle and jobStatus to null if empty string
//   if (controlledValues.academicTitle === '') {
//     controlledValues.academicTitle = null;
//   }
//   if (controlledValues.jobStatus === '') {
//     controlledValues.jobStatus = null;
//   }

//   // unticked checkbox is undefined, not false
//   controlledValues.expertMode = !!controlledValues.expertMode;
//   controlledValues.textToSpeechEnabled = !!controlledValues.textToSpeechEnabled;
//   if (controlledValues.textToSpeechEnabled) {
//     controlledValues.initiallyObfuscateText = !!controlledValues.initiallyObfuscateText;
//   } else {
//     controlledValues.initiallyObfuscateText = false;
//   }
//   controlledValues.hideChatHistory = !!controlledValues.hideChatHistory;

//   authStore
//     .updateUserDetails({
//       first_name: controlledValues.firstName,
//       last_name: controlledValues.lastName,
//       username: controlledValues.username,
//       gender: controlledValues.gender,
//       academic_title: controlledValues.academicTitle,
//       job_status: controlledValues.jobStatus,
//       biography: controlledValues.biography,
//       profile_image: null,
//       native_language: controlledValues.nativeLanguage,
//       ingame_language: controlledValues.ingameLanguage,
//       expert_mode: controlledValues.expertMode,
//       text_to_speech_enabled: controlledValues.textToSpeechEnabled,
//       initially_obfuscate_text: controlledValues.initiallyObfuscateText,
//       hide_chat_history: controlledValues.hideChatHistory,
//     })
//     .then(async () => {
//       initialValues = controlledValues;
//       isSubmitting.value = false;
//       // await router.push("/home");
//     })
//     .catch((error) => {
//       isSubmitting.value = false;
//     });
// }

onMounted(async () => {
  isLoading.value = true;
  await Promise.all([authStore.fetchUserDetails(), languageStore.loadLanguages(), countryStore.loadCountries()]);
  isLoading.value = false;

  await nextTick(() => {
    HSStaticMethods.autoInit();
  });
});

// tab 1
const form1 = useForm({
  validationSchema: {
    firstName: 'required|max:255',
    lastName: 'required|max:255',
    username: 'alpha_dash|max:32',
    email: 'required|email',
    gender: '',
    jobStatus: '',
    academicTitle: '',
    biography: 'max:1000',
  },
  initialValues: {
    firstName: authStore.userFirstName,
    lastName: authStore.userLastName,
    username: authStore.userUsername,
    email: authStore.userEmail,
    gender: authStore.userGender,
    jobStatus: authStore.userJobStatus,
    academicTitle: authStore.userAcademicTitle,
    biography: authStore.userBiography,
  },
});
const [firstName, firstNameAttrs] = form1.defineField('firstName');
const [lastName, lastNameAttrs] = form1.defineField('lastName');
const [username, usernameAttrs] = form1.defineField('username');
const [email, emailAttrs] = form1.defineField('email');
const [gender, genderAttrs] = form1.defineField('gender');
const [jobStatus, jobStatusAttrs] = form1.defineField('jobStatus');
const [academicTitle, academicTitleAttrs] = form1.defineField('academicTitle');
const [biography, biographyAttrs] = form1.defineField('biography');

// tab 2
const form2 = useForm({
  validationSchema: {
    nativeLanguage: 'required',
    ingameLanguage: 'required',
    countryOfOrigin: 'required',
  },
  initialValues: {
    nativeLanguage: authStore.userNativeLanguage,
    ingameLanguage: authStore.userIngameLanguage,
    countryOfOrigin: authStore.userCountryOfOrigin,
  },
});
const [nativeLanguage, nativeLanguageAttrs] = form2.defineField('nativeLanguage');
const [ingameLanguage, ingameLanguageAttrs] = form2.defineField('ingameLanguage');
const [countryOfOrigin, countryOfOriginAttrs] = form2.defineField('countryOfOrigin');

// tab 3
const form3 = useForm({
  validationSchema: {
    textToSpeechEnabled: '',
    chatEnabled: '',
    subtitlesEnabled: '',
    expertMode: '',
  },
  initialValues: {
    textToSpeechEnabled: authStore.textToSpeechEnabled,
    chatEnabled: authStore.chatEnabled,
    subtitlesEnabled: authStore.subtitlesEnabled,
    expertMode: authStore.expertMode,
  },
});
const [textToSpeechEnabled, textToSpeechEnabledAttrs] = form3.defineField('textToSpeechEnabled');
const [chatEnabled, chatEnabledAttrs] = form3.defineField('chatEnabled');
const [subtitlesEnabled, subtitlesEnabledAttrs] = form3.defineField('subtitlesEnabled');
const [expertMode, expertModeAttrs] = form3.defineField('expertMode');

const onSubmitProfile = form1.handleSubmit.withControlled(async (values) => {
  console.log(values);
  if (!isSubmitting.value) {
    authStore
      .updateUserDetails({
        first_name: values.firstName,
        last_name: values.lastName,
        username: values.username,
        gender: values.gender,
        academic_title: values.academicTitle,
        job_status: values.jobStatus,
        biography: values.biography,
        profile_image: null,
      })
      .then(async () => {
        isSubmitting.value = false;
      })
      .catch((error) => {
        isSubmitting.value = false;
      });
  }
});

const onSubmitLanguagesAndCountries = form2.handleSubmit.withControlled(async (values) => {
  console.log(values);
  let originalLanguage = authStore.userNativeLanguage;
  if (!isSubmitting.value) {
    authStore
      .updateUserDetails({
        native_language: values.nativeLanguage,
        ingame_language: values.ingameLanguage,
        country_of_origin: values.countryOfOrigin,
      })
      .then(async () => {
        isSubmitting.value = false;
        if (values.nativeLanguage !== originalLanguage) {
          await router.go(0); // to let changed UI language take effect
        }
      })
      .catch((error) => {
        isSubmitting.value = false;
      });
  }
});

const onSubmitIngameSettings = form3.handleSubmit.withControlled(async (values) => {
  console.log(values);
  if (!isSubmitting.value) {
    authStore
      .updateUserDetails({
        text_to_speech_enabled: values.textToSpeechEnabled,
        initially_obfuscate_text: values.initiallyObfuscateText,
        hide_chat_history: values.hideChatHistory,
        expert_mode: values.expertMode,
        chat_enabled: values.chatEnabled,
        subtitles_enabled: values.subtitlesEnabled,
      })
      .then(async () => {
        isSubmitting.value = false;
      })
      .catch((error) => {
        isSubmitting.value = false;
      });
  }
});

function readyForSubmit(form) {
  // dirty = at least one field has been updated
  // valid = all fields are valid => not working on prd, commented out (RS 08.08.2024)
  return form.meta.value.valid; // form.meta.value.dirty &&
}
</script>

<template>
  <div v-if="isLoading" class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
    <div
      class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
      role="status"
      aria-label="loading"
    />
  </div>
  <!-- Card Section -->
  <main
    id="scroll-container"
    ref="scrollContainer"
    v-if="!isLoading"
    class="w-full overflow-auto"
    :style="{ height: `calc(100dvh - ${outerHeaderHeight}px)` }"
  >
    <!-- added this outer container to fix scrolling issue -->

    <div
      class="flex-col flex overflow-visible min-w-fit w-fit h-fit min-h-fit max-w-6xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
    >
      <nav class="flex gap-x-1" aria-label="Tabs" role="tablist" aria-orientation="horizontal">
        <button
          type="button"
          class="hs-tab-active:bg-blue-600 hs-tab-active:text-white hs-tab-active:hover:text-white hs-tab-active:dark:text-white py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm font-medium text-center text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-neutral-300 active"
          id="tabs-item-1"
          aria-selected="true"
          data-hs-tab="#tabs-1"
          aria-controls="tabs-1"
          role="tab"
        >
          {{ $t('message.profile') }}
        </button>
        <button
          type="button"
          class="hs-tab-active:bg-blue-600 hs-tab-active:text-white hs-tab-active:hover:text-white hs-tab-active:dark:text-white py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm font-medium text-center text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-neutral-300"
          id="tabs-item-2"
          aria-selected="false"
          data-hs-tab="#tabs-2"
          aria-controls="tabs-2"
          role="tab"
        >
          {{ $t('message.languagesAndCountries') }}
        </button>
        <button
          type="button"
          class="hs-tab-active:bg-blue-600 hs-tab-active:text-white hs-tab-active:hover:text-white hs-tab-active:dark:text-white py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm font-medium text-center text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-neutral-300"
          id="tabs-item-3"
          aria-selected="false"
          data-hs-tab="#tabs-3"
          aria-controls="tabs-3"
          role="tab"
        >
          {{ $t('message.ingameSettings') }}
        </button>
      </nav>

      <div class="mt-3">
        <div id="tabs-1" role="tabpanel" aria-labelledby="tabs-item-1">
          <!-- Card -->
          <div class="relative min-w-max bg-white rounded-xl shadow p-4 sm:p-7 dark:bg-neutral-900">
            <div class="mb-8">
              <h2 class="text-xl font-bold text-gray-800 dark:text-gray-200">
                Dein <span class="text-blue-600">Profil</span>
                <!-- TODO i18n -->
              </h2>
              <p class="text-sm text-gray-600 dark:text-gray-400">Verwalte Deine persönlichen Einstellungen.</p>
              <!-- TODO i18n -->
            </div>

            <div class="absolute top-5 right-4 sm:right-7">
              <IngameProgressCard />
            </div>

            <div>
              <router-link
                to="/account/change-password"
                class="text-blue-600 hover:text-blue-700 hover:underline text-sm"
              >
                {{ $t('message.changePassword') }}
              </router-link>
            </div>

            <form @submit="onSubmitProfile">
              <div class="child grid grid-cols-1 divide-y">
                <!-- Grid: User base data + social -->
                <div class="child py-8 grid sm:grid-cols-12 gap-4">
                  <!-- Profile image -->
                  <div class="sm:col-span-3">
                    <label class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200">{{
                      $t('message.profileImage')
                    }}</label>
                  </div>
                  <div class="sm:col-span-9">
                    <div class="flex items-center gap-5">
                      <ProfileImage
                        :image="authStore.userProfileImageSmall"
                        :initials="authStore.userInitials"
                        :showIngameLevel="false"
                      />

                      <div class="flex gap-x-2">
                        <div>
                          <!-- <button
                        type="button"
                        data-hs-overlay="#upload-profile-image-modal"
                        class="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                      >
                        <svg
                          class="w-3 h-3"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                          />
                          <path
                            d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"
                          />
                        </svg>
                        Bild hochladen
                      </button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End row -->

                  <!-- academic title -->
                  <div class="sm:col-span-3">
                    <label for="academicTitle" class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200">
                      {{ $t('message.academicTitle') }}
                    </label>
                  </div>
                  <div class="sm:col-span-9">
                    <div class="sm:flex">
                      <select
                        v-model="academicTitle"
                        v-bind="academicTitleAttrs"
                        class="py-2 px-3 pr-11 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
                      >
                        <option :value="null">keinen</option>
                        <option value="DR">Dr.</option>
                        <option value="PROF_DR">Prof. Dr.</option>
                      </select>
                    </div>
                  </div>
                  <!-- End row -->

                  <!-- name -->
                  <div class="sm:col-span-3">
                    <label
                      for="af-account-full-name"
                      class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                    >
                      {{ $t('message.name') }}
                    </label>
                  </div>
                  <div class="sm:col-span-9">
                    <div class="sm:flex">
                      <input
                        v-model="firstName"
                        v-bind="firstNameAttrs"
                        type="text"
                        class="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm -mt-px -ml-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-l-lg sm:mt-0 sm:first:ml-0 sm:first:rounded-tr-none sm:last:rounded-bl-none sm:last:rounded-r-lg text-sm relative focus:z-10 focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
                        :class="{
                          'border-red-500 focus:border-red-500 focus:ring-red-500': form1.errors.value.firstName,
                        }"
                        :placeholder="$t('message.firstName')"
                      />
                      <input
                        v-model="lastName"
                        v-bind="lastNameAttrs"
                        type="text"
                        class="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm -mt-px -ml-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-l-lg sm:mt-0 sm:first:ml-0 sm:first:rounded-tr-none sm:last:rounded-bl-none sm:last:rounded-r-lg text-sm relative focus:z-10 focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
                        :placeholder="$t('message.lastName')"
                      />
                    </div>
                  </div>
                  <!-- End row -->

                  <!-- username -->
                  <div class="sm:col-span-3">
                    <label for="username" class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200">
                      {{ $t('message.username') }}
                    </label>
                  </div>
                  <div class="sm:col-span-9">
                    <input
                      id="username"
                      v-model="username"
                      v-bind="usernameAttrs"
                      type="text"
                      class="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
                    />
                  </div>
                  <!-- End row -->

                  <!-- email -->
                  <div class="sm:col-span-3">
                    <label for="af-account-email" class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200">
                      {{ $t('message.email') }}
                    </label>
                  </div>
                  <div class="sm:col-span-9">
                    <input
                      id="af-account-email"
                      v-model="email"
                      v-bind="emailAttrs"
                      disabled
                      type="email"
                      class="py-2 px-3 pr-11 block w-full text-gray-400 border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
                    />
                  </div>
                  <!-- End row -->

                  <!-- sex -->
                  <div class="sm:col-span-3">
                    <label
                      for="af-account-gender-checkbox"
                      class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"
                    >
                      {{ $t('message.gender') }}
                    </label>
                  </div>
                  <div class="sm:col-span-9">
                    <div class="sm:flex">
                      <label
                        for="af-account-gender-checkbox"
                        class="flex py-2 px-3 block w-full border border-gray-200 shadow-sm -mt-px -ml-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-l-lg sm:mt-0 sm:first:ml-0 sm:first:rounded-tr-none sm:last:rounded-bl-none sm:last:rounded-r-lg text-sm relative focus:z-10 focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
                      >
                        <input
                          type="radio"
                          v-model="gender"
                          v-bind="genderAttrs"
                          value="MALE"
                          class="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 pointer-events-none focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                          id="af-account-gender-checkbox"
                        />
                        <span class="text-sm text-gray-500 ml-3 dark:text-gray-400">{{
                          $t('message.genderMale')
                        }}</span>
                      </label>

                      <label
                        for="af-account-gender-checkbox-female"
                        class="flex py-2 px-3 block w-full border border-gray-200 shadow-sm -mt-px -ml-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-l-lg sm:mt-0 sm:first:ml-0 sm:first:rounded-tr-none sm:last:rounded-bl-none sm:last:rounded-r-lg text-sm relative focus:z-10 focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
                      >
                        <input
                          type="radio"
                          v-model="gender"
                          v-bind="genderAttrs"
                          value="FEMALE"
                          class="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 pointer-events-none focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                          id="af-account-gender-checkbox-female"
                        />
                        <span class="text-sm text-gray-500 ml-3 dark:text-gray-400">{{
                          $t('message.genderFemale')
                        }}</span>
                      </label>

                      <label
                        for="af-account-gender-checkbox-other"
                        class="flex py-2 px-3 block w-full border border-gray-200 shadow-sm -mt-px -ml-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-l-lg sm:mt-0 sm:first:ml-0 sm:first:rounded-tr-none sm:last:rounded-bl-none sm:last:rounded-r-lg text-sm relative focus:z-10 focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
                      >
                        <input
                          type="radio"
                          v-model="gender"
                          v-bind="genderAttrs"
                          value="DIVERSE"
                          class="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 pointer-events-none focus:ring-blue-600 dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                          id="af-account-gender-checkbox-other"
                        />
                        <span class="text-sm text-gray-500 ml-3 dark:text-gray-400">{{
                          $t('message.genderDiverse')
                        }}</span>
                      </label>
                    </div>
                  </div>
                  <!-- End row -->

                  <!-- job status -->
                  <div class="sm:col-span-3">
                    <label for="jobStatus" class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200">
                      {{ $t('message.jobStatus') }}
                    </label>
                  </div>
                  <div class="sm:col-span-9">
                    <div class="sm:flex">
                      <select
                        v-model="jobStatus"
                        v-bind="jobStatusAttrs"
                        class="py-2 px-3 pr-11 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
                      >
                        <option :value="null">{{ $t('message.notSpecified') }}</option>
                        <option value="STUDENT">{{ $t('message.student') }}</option>
                        <option value="PHYSICIAN">{{ $t('message.physician') }}</option>
                        <option value="NURSE">{{ $t('message.nurse') }}</option>
                        <option value="TEACHER">{{ $t('message.teacher') }}</option>
                        <option value="OTHER">{{ $t('message.somethingDifferent') }}</option>
                      </select>
                    </div>
                  </div>
                  <!-- end row -->

                  <!-- about -->
                  <div class="sm:col-span-3">
                    <label for="af-account-bio" class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200">
                      {{ $t('message.aboutYou') }}
                    </label>
                  </div>
                  <div class="sm:col-span-9">
                    <textarea
                      v-model="biography"
                      v-bind="biographyAttrs"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400"
                      rows="6"
                      :placeholder="$t('message.tellSomethingAboutYou')"
                    />
                  </div>
                  <!-- End row -->
                </div>
                <!-- End grid ser base data + social -->
              </div>

              <div class="mt-8">
                <ProgressButton
                  :disabled="!readyForSubmit(form1)"
                  :isSubmitting="isSubmitting"
                  type="submit"
                  class="ml-auto"
                >
                  {{ $t('message.save') }}
                </ProgressButton>
              </div>
            </form>
          </div>
          <!-- End Card -->
        </div>

        <div class="hidden" id="tabs-2" role="tabpanel" aria-labelledby="tabs-item-2">
          <!-- Card -->
          <div class="min-w-max bg-white rounded-xl shadow p-4 sm:p-7 dark:bg-neutral-900">
            <div class="mb-8">
              <h2 class="text-xl font-bold text-gray-800 dark:text-gray-200">
                Dein <span class="text-blue-600">Profil</span>
                <!-- TODO i18n -->
              </h2>
              <p class="text-sm text-gray-600 dark:text-gray-400">Verwalte Deine persönlichen Einstellungen.</p>
              <!-- TODO i18n -->
            </div>

            <form @submit="onSubmitLanguagesAndCountries">
              <div class="child grid grid-cols-1 divide-y">
                <!-- Grid: User base data + social -->
                <div class="child py-8 grid sm:grid-cols-12 gap-4">
                  <!-- user native language -->
                  <div class="sm:col-span-6">
                    <label for="countryOfOrigin" class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200">
                      {{ $t('message.yourLanguage') }}
                    </label>
                  </div>
                  <div class="sm:col-span-6">
                    <div class="space-y-3">
                      <LanguageAndCountryDropdown
                        v-if="!isLoading"
                        v-model="nativeLanguage"
                        :isCountry="false"
                        :items="languages"
                      />
                      <p class="text-sm text-red-600 mt-2">{{ form2.errors.value.nativeLanguage }}</p>
                    </div>
                  </div>
                  <!-- end row -->

                  <!-- case language -->
                  <div class="sm:col-span-6">
                    <label for="ingameLanguage" class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200">
                      {{ $t('message.yourLanguageToLearn') }}
                    </label>
                  </div>
                  <div class="sm:col-span-6">
                    <div class="space-y-3">
                      <LanguageAndCountryDropdown
                        v-if="!isLoading"
                        v-model="ingameLanguage"
                        :isCountry="false"
                        :items="languages"
                      />
                      <p class="text-sm text-red-600 mt-2">{{ form2.errors.value.ingameLanguage }}</p>
                    </div>
                  </div>
                  <!-- end row -->

                  <!-- case language -->
                  <div class="sm:col-span-6">
                    <label for="ingameLanguage" class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200">
                      {{ $t('message.yourCountryOfOrigin') }}
                    </label>
                  </div>
                  <div class="sm:col-span-6">
                    <div class="space-y-3">
                      <LanguageAndCountryDropdown
                        v-if="!isLoading"
                        v-model="countryOfOrigin"
                        :isCountry="true"
                        :items="countries"
                      />
                      <p class="text-sm text-red-600 mt-2">{{ form2.errors.value.countryOfOrigin }}</p>
                    </div>
                  </div>
                  <!-- end row -->

                  <!-- End grid game settings -->
                </div>
              </div>

              <div class="mt-8">
                <ProgressButton
                  :disabled="!readyForSubmit(form2)"
                  :isSubmitting="isSubmitting"
                  type="submit"
                  class="ml-auto"
                >
                  {{ $t('message.save') }}
                </ProgressButton>
              </div>
            </form>
          </div>
          <!-- End Card -->
        </div>

        <div class="hidden" id="tabs-3" role="tabpanel" aria-labelledby="tabs-item-3">
          <!-- Card -->
          <div class="min-w-max bg-white rounded-xl shadow p-4 sm:p-7 dark:bg-neutral-900">
            <div class="mb-8">
              <h2 class="text-xl font-bold text-gray-800 dark:text-gray-200">
                Deine <span class="text-blue-600">Spieleinstellungen</span>
              </h2>
              <p class="text-sm text-gray-600 dark:text-gray-400">Verwalte Deine persönlichen Einstellungen.</p>
            </div>

            <form @submit="onSubmitIngameSettings">
              <div class="child grid grid-cols-1 divide-y">
                <!-- Grid listening comprehension settings -->

                <div class="child py-8 grid sm:grid-cols-12 gap-5">
                  <div class="sm:col-span-12 inline-block text-sm font-bold">Hörverstehen</div>
                  <!-- TTS on/off -->
                  <div class="sm:col-span-6 inline-block text-sm text-gray-800 items-center dark:text-gray-200">
                    <label for="textToSpeechEnabled"> Audioausgabe aktivieren </label>
                  </div>
                  <div class="sm:col-span-6 flex items-center">
                    <input
                      type="checkbox"
                      v-model="textToSpeechEnabled"
                      v-bind="textToSpeechEnabledAttrs"
                      :value="true"
                      class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                      id="hs-default-checkbox"
                    />
                  </div>
                  <!-- End row -->

                  <!--                  &lt;!&ndash; Blur text &ndash;&gt;-->
                  <!--                  <div class="sm:col-span-3">-->
                  <!--                    <label-->
                  <!--                      for="initiallyObfuscateText"-->
                  <!--                      class="inline-block text-sm text-gray-800 mt-2.5 dark:text-gray-200"-->
                  <!--                    >-->
                  <!--                      Textausgabe verbergen-->
                  <!--                    </label>-->
                  <!--                  </div>-->
                  <!--                  <div class="sm:col-span-9">-->
                  <!--                    <div class="flex">-->
                  <!--                      <input-->
                  <!--                        type="checkbox"-->
                  <!--                        v-model="initiallyObfuscateText"-->
                  <!--                        v-bind="initiallyObfuscateTextAttrs"-->
                  <!--                        :value="true"-->
                  <!--                        class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"-->
                  <!--                        id="hs-default-checkbox"-->
                  <!--                      />-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  &lt;!&ndash; End row &ndash;&gt;-->

                  <!-- Blur text -->
                  <div class="sm:col-span-6 inline-block text-sm text-gray-800 items-center dark:text-gray-200">
                    <label for="subtitlesEnabled"> Untertitel anzeigen </label>
                  </div>
                  <div class="sm:col-span-6 flex items-center">
                    <input
                      type="checkbox"
                      v-model="subtitlesEnabled"
                      v-bind="subtitlesEnabledAttrs"
                      :value="true"
                      class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                      id="hs-default-checkbox"
                    />
                  </div>
                  <!-- End row -->

                  <!-- Blur text -->
                  <div class="sm:col-span-6 inline-block text-sm text-gray-800 items-center dark:text-gray-200">
                    <label for="chatEnabled"> Chat Input anzeigen </label>
                  </div>
                  <div class="sm:col-span-6 flex items-center">
                    <input
                      type="checkbox"
                      v-model="chatEnabled"
                      v-bind="chatEnabledAttrs"
                      :value="false"
                      class="shrink-0 border-gray-200 rounded text-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                      id="hs-default-checkbox"
                    />
                  </div>
                  <!-- End row -->
                </div>
                <!-- End grid listening comprehension settings -->

                <!-- Grid other settings -->
                <div class="child py-8 grid sm:grid-cols-12 gap-5">
                  <div class="sm:col-span-12 inline-block text-sm mt-2.5 font-bold">Sonstige Einstellungen</div>

                  <!-- Chat history on/off -->
                  <!--                  <div class="sm:col-span-3">-->
                  <!--                    <label for="hideChatHistory" class="inline-block text-sm text-gray-800 dark:text-gray-200">-->
                  <!--                      Gesprächsverlauf verbergen-->
                  <!--                    </label>-->
                  <!--                    <Tooltip-->
                  <!--                      :message="`Möchtest du alle alten Nachrichten vom Patienten im Spiel sehen oder nur die aktuellste (aktivieren)?`"-->
                  <!--                    />-->
                  <!--                  </div>-->
                  <!--                  <div class="sm:col-span-9">-->
                  <!--                    <div class="flex">-->
                  <!--                      <input-->
                  <!--                        type="checkbox"-->
                  <!--                        v-model="hideChatHistory"-->
                  <!--                        v-bind="hideChatHistoryAttrs"-->
                  <!--                        :value="true"-->
                  <!--                        class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"-->
                  <!--                        id="hs-default-checkbox"-->
                  <!--                      />-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!-- End row -->

                  <!-- expert mode -->
                  <div class="sm:col-span-6 inline-block text-sm text-gray-800 items-center dark:text-gray-200">
                    <label for="expertMode"> Expert/contributor mode </label>
                    <Tooltip
                      :message="`Antwort und Befunde des Patienten händisch editieren, um den Fall zu verbessern,
                    noch realistischer zu machen oder Besonderheiten und Details für deine Kommilitonen einzufügen.`"
                    />
                  </div>
                  <div class="sm:col-span-6 flex items-center">
                    <input
                      type="checkbox"
                      v-model="expertMode"
                      v-bind="expertModeAttrs"
                      :value="true"
                      class="shrink-0 border-gray-200 rounded text-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-gray-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-800"
                      id="hs-default-checkbox"
                    />
                  </div>
                  <!-- End row -->
                </div>
                <!-- End grid other settings -->
              </div>

              <div class="mt-8">
                <ProgressButton
                  :disabled="!readyForSubmit(form3)"
                  :isSubmitting="isSubmitting"
                  type="submit"
                  class="ml-auto"
                >
                  {{ $t('message.save') }}
                </ProgressButton>
              </div>
            </form>
          </div>
          <!-- End Card -->
        </div>
      </div>
    </div>
  </main>
</template>
