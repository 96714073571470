import { ERWaitingRoom } from '@/views/er-waiting-room';
import { LoggedInRoot } from '@/views/root';
import { Profile } from '@/views/home';
import { CourseChapter, CourseDetails, CourseInteractionList, CourseLibrary, EditCourseChapter } from '@/views/courses';
import { CreateNewCase } from '@/views/new-case';
import { ClinicalNotesColumn } from '@/views/clinical-information-system';
import PatientCard from '@/views/clinical-information-system/PatientCard.vue';
import PatientView from '@/views/clinical-information-system/MockDesktop.vue';
import LearningObjectiveEvaluationCard from '@/components/LearningObjectiveEvaluationCard.vue';
import IngameProgressCard from '@/views/home/IngameProgressCard.vue';
import IngameProgressBadge from '@/views/home/IngameProgressBadge.vue';

export default {
  path: '/home',
  component: LoggedInRoot,
  children: [
    { path: '', component: CourseLibrary },
    { path: '/profile', component: Profile },
    {
      name: 'new-or-edit-case',
      path: '/new-case',
      component: CreateNewCase,
      props: (route: {
        query: {
          isCopy: string;
          isEdit: string;
          isViewOnly: string;
          fromCaseId: string;
          inCaseListId: string;
        };
      }) => ({
        fromCaseId: route.query.fromCaseId,
        editCase: route.query.isEdit === 'true',
        copyCase: route.query.isCopy === 'true',
        viewOnly: route.query.isViewOnly === 'true',
        caseListId: route.query.inCaseListId,
      }),
    },
    // course library
    { path: '/course/:courseId', component: CourseDetails },
    { path: '/chapter/:chapterId', component: CourseChapter },
    { path: '/edit-chapter/:chapterId', component: EditCourseChapter },
    { path: '/my-courses/:userId', component: CourseInteractionList },
    // cases
    { path: '/wartebereich-zna', component: ERWaitingRoom },
    // {
    //   path: '/dev',
    //   component: IngameProgressBadge,
    // },
  ],
};
