export function mapTitle(text: string) {
  if (!text) {
    return '';
  }
  text = text.replace(/PROF_DR/g, 'Prof. Dr.');
  text = text.replace(/DR/g, 'Dr.');
  return text;
}

export function mapJobStatus(job: string) {
  if (!job) {
    return '';
  }
  return job.charAt(0).toUpperCase() + job.slice(1).toLowerCase();
}

export function formatDate(date: string) {
  if (!date) {
    return '';
  }
  const d = new Date(date);
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
}

export function unobfuscateUserName(message: string, first_name: string, last_name: string) {
  if (!message) {
    return '';
  }
  return message.replace('Yvä', first_name).replace('Xöl', last_name);
}
