<script setup>
import MainHeader from '@/components/headers/MainHeader.vue';
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { debounce } from 'lodash';
import { useRouter } from 'vue-router';

const outerHeader = ref(null);
const outerHeaderHeight = ref(0);

const adjustHeight = () => {
  if (outerHeader.value) {
    outerHeaderHeight.value = outerHeader.value.offsetHeight;
  }
};

const debouncedAdjustHeight = debounce(adjustHeight, 200);

onMounted(() => {
  adjustHeight();
  window.addEventListener('resize', debouncedAdjustHeight);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeight);
});

const shouldCache = (route) => {};
</script>

<template>
  <div class="flex flex-col w-screen overflow-hidden" :style="{ height: `100dvh` }">
    <header
      ref="outerHeader"
      class="flex-none sticky top-0 md:justify-start md:flex-nowrap z-40 w-full bg-white text-sm py-3 md:py-0 dark:bg-neutral-900"
    >
      <MainHeader
        :showRootLink="true"
        :showHomeLink="true"
        :showNewCaseDropdown="true"
        :showPatientInteractionListDropdown="true"
        :showCourseInteractionsLink="true"
        :showChallengesAndEventsLink="true"
        :showCommunityLink="true"
        :showAboutUsLink="false"
        :showQuizDropdown="true"
        :showSignInOrEditProfile="true"
      />
    </header>
    <main
      :style="{ height: `calc(100dvh - ${outerHeaderHeight}px)` }"
      class="flex-grow w-full overflow-hidden"
      ref="mainContent"
    >
      <router-view :outer-header-height="outerHeaderHeight" />
    </main>
  </div>
</template>
