<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';
import { getApiClient } from '@/apiclient/client';
import CaseHighlightCard from '@/components/CaseHighlightCard.vue';
import { formatDate, mapJobStatus, mapTitle } from '@/helper';
import ProfileImage from '@/components/ProfileImage.vue';
import DOMPurify from 'dompurify';
import TextEditor from '@/views/courses/TextEditor.vue';
import { useAlertStore, useCourseInteractionStore, useAuthStore, useLanguageStore } from '@/stores';
import SectionMediaUpload from '@/views/courses/SectionMediaUpload.vue';
import MediaHighlightCard from '@/views/courses/MediaHighlightCard.vue';
import { storeToRefs } from 'pinia';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();
const authStore = useAuthStore();
const languageStore = useLanguageStore();
const { userNativeLanguage, userIngameLanguage } = storeToRefs(authStore);

const props = defineProps(['section', 'showNative', 'chapterId']);
const reactiveShowNative = toRef(props, 'showNative'); // Create a ref directly from props

const emit = defineEmits(['showNativeToggled']);
const caseList = ref();
const cases = ref([]);
const media = ref([] as any[]);
const fullWidthIndex = ref(-1);
const initialFetchCompleted = ref(false);
const germanContentContainer = ref(null);
const germanContentContainerHeight = ref(0);
const outerTextContainer = ref(null);
let germanContentContainerResizeObserver = null;

const widthWithNativeShown = ref(1200);
const widthWithoutNativeShown = ref(800);
const padding = ref(100);
const gap = ref(50);

const outerTextContainerWidthWithNativeShown = computed(() => {
  return widthWithNativeShown.value - 2 * padding.value;
});
const outerTextContainerWidthWithoutNativeShown = computed(() => {
  return widthWithoutNativeShown.value - 2 * padding.value;
});

const relativeCharacterLength = computed(() => {
  // This corrects for the fact that cyrilic, arabic and latin alphabet characters have different sizes
  // TODO: automaticlly do this for other languages => save code for alphabet with each language ?
  if (userNativeLanguage.value === 'aeb') return 0.85;
  if (userNativeLanguage.value === 'pes') return 0.85;
  if (userNativeLanguage.value === 'rus' || userNativeLanguage.value === 'tgk') return 1.1;
  return 1.0;
});

const getHeight = () => {
  if (!germanContentContainer.value) {
    console.log('germanContentContainer is not set. Ok if unmounting.');
    return;
  }
  germanContentContainerHeight.value = germanContentContainer.value.offsetHeight;
};

const getWidth = () => {
  const screenWidth = window.innerWidth;
  widthWithNativeShown.value = Math.max(1024, Math.round(screenWidth * 0.75)); // % of screen width with minimum
  widthWithoutNativeShown.value = Math.max(1024, Math.round(screenWidth * 0.62));
};

const germanContainerWidth = computed(() => {
  console.log(outerTextContainerWidthWithNativeShown.value);
  console.log(sanitizedSectionContentsHtml.value.length);
  console.log(translatedContentIfAny.value.length);
  if (!outerTextContainerWidthWithoutNativeShown.value) {
    return 0;
  }
  if (!(sanitizedSectionContentsHtml.value.length + translatedContentIfAny.value.length)) {
    return 0;
  }
  if (!reactiveShowNative.value) {
    console.log('german at full width');
    return outerTextContainerWidthWithoutNativeShown.value;
  }
  if (!translationAvailable.value) {
    return 0.7 * outerTextContainerWidthWithoutNativeShown.value;
  }
  return Math.floor(
    ((outerTextContainerWidthWithNativeShown.value - 2 * gap.value) * sanitizedSectionContentsHtml.value.length) /
      (sanitizedSectionContentsHtml.value.length + translatedContentIfAny.value.length * relativeCharacterLength.value),
  );
});

const nativeContainerInnerWidth = computed(() => {
  if (!outerTextContainerWidthWithNativeShown.value) {
    return 0;
  }
  // return Math.ceil(
  //     (outerTextContainerWidthWithNativeShown.value) * translatedContentIfAny.value.length /
  //     (sanitizedSectionContentsHtml.value.length + translatedContentIfAny.value.length)
  //     ,
  // ) - gap.value
  return outerTextContainerWidthWithNativeShown.value - 2 * gap.value - germanContainerWidth.value;
});

const nativeContainerOuterWidth = computed(() => {
  return nativeContainerInnerWidth.value + gap.value;
});

const nativeContainerTranslation = computed(() => {
  console.log(reactiveShowNative.value);
  if (!reactiveShowNative.value) {
    console.log('showNative false');
    return outerTextContainerWidthWithoutNativeShown.value;
  }
  console.log('showNative true');
  // return germanContainerWidth.value + gap.value;
  return outerTextContainerWidthWithNativeShown.value - nativeContainerOuterWidth.value;
});

const debounceGetHeight = debounce(getHeight, 100);
const debounceGetWidth = debounce(getWidth, 100);

async function fetchCaseList() {
  caseList.value = await (
    await getApiClient()
  ).caseLists.getCaseList(
    (props.section.case_list ? props.section.case_list.id : null)
      ? props.section.case_list.id
      : props.section.case_list_id,
  );
  cases.value = caseList.value.cases;
}

async function fetchMedia() {
  media.value = await (await getApiClient()).sections.getSectionMediaItems(props.section.id);
}

onMounted(async () => {
  await fetchCaseList();
  await fetchMedia();
  await languageStore.loadLanguages(false);
  initialFetchCompleted.value = true;
  await nextTick();
  getHeight(); // initial height calculation
  getWidth();
  window.addEventListener('resize', debounceGetHeight);
  window.addEventListener('resize', debounceGetWidth);
  germanContentContainerResizeObserver = new ResizeObserver(debounceGetHeight);
  germanContentContainerResizeObserver.observe(germanContentContainer.value);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceGetHeight);
  if (!!germanContentContainerResizeObserver.value) {
    germanContentContainerResizeObserver.disconnect();
  }
});

const sectionStarted = computed(() => {
  return courseInteractionStore.sectionStarted(props.section.index);
});

const sectionCompleted = computed(() => {
  return courseInteractionStore.sectionCompleted(props.section.index);
});

const firstCaseStarted = computed(() => {
  return courseInteractionStore.sectionFirstCaseStarted(props.section.index);
});

const sanitizedSectionContentsHtml = computed(() => {
  return DOMPurify.sanitize(props.section.contents);
});

const translationAvailable = computed(() => {
  return !!props.section.content_translations[userNativeLanguage.value];
});

const translatedContentIfAny = computed(() => {
  return translationAvailable.value
    ? DOMPurify.sanitize(props.section.content_translations[userNativeLanguage.value])
    : 'No translation available';
});

async function markSection() {
  if (!sectionStarted.value) {
    await courseInteractionStore.startSectionIfNotAlready(props.section.index);
  } else {
    await courseInteractionStore.toggleSectionCompleted(props.section.index, t);
  }
}

const toggleFullWidth = (index: number) => {
  console.log('section - toggleFullWidth to index: ', index);
  if (fullWidthIndex.value === index) {
    fullWidthIndex.value = -1;
  } else {
    fullWidthIndex.value = index;
  }
};

const untoggleFullWidth = () => {
  fullWidthIndex.value = -1;
};

// TODO i18n
// TODO language icon => user language flag icon ?
</script>

<template>
  <!-- Blog Article -->
  <div
    class="px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-900 dark:border-gray-700 flex justify-center max-w-6xl"
    v-if="!initialFetchCompleted"
  >
    <div class="flex justify-center items-center h-20">
      <div
        class="animate-spin inline-block w-32 h-32 border-4 border-current border-t-transparent text-blue-600 rounded-full"
        role="status"
        aria-label="loading"
      />
    </div>
  </div>
  <div
    v-show="initialFetchCompleted"
    @click="untoggleFullWidth"
    class="mx-auto transition-all duration-500 ease-in-out pt-6 lg:pt-10 pb-12 bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-900 dark:border-gray-700 flex justify-center"
    :style="{ width: showNative ? `${widthWithNativeShown}px` : `${widthWithoutNativeShown}px` }"
  >
    <div class="grow transition-all duration-500 ease-in-out">
      <div
        class="mx-auto justify-between items-center mb-6 transition-all duration-500 ease-in-out"
        :style="{
          width: showNative
            ? `${outerTextContainerWidthWithNativeShown}px`
            : `${outerTextContainerWidthWithoutNativeShown}px`,
        }"
      >
        <div class="w-full sm:items-center gap-x-5 sm:gap-x-3">
          <div class="grow">
            <div class="flex justify-between items-center gap-x-2">
              <!-- Button Group -->
              <div class="inline-flex gap-x-6 items-center">
                <button
                  type="button"
                  class="group py-1.5 px-2.5 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border shadow-sm disabled:opacity-50 disabled:pointer-events-none dark:text-white"
                  @click.capture="markSection"
                  :class="{
                    'border-gray-200 text-gray-200 hover:bg-blue-700 hover:text-white':
                      !sectionCompleted && !sectionStarted,
                    'border-gray-800 text-gray-800 dark:border-gray-700 bg-white hover:bg-gray-50 dark:bg-neutral-900 dark:hover:bg-gray-800':
                      !sectionCompleted && sectionStarted,
                    'text-green-700 border-green-700 dark:border-green-700  bg-green-100 hover:text-green-600 dark:bg-green-900 dark:hover:bg-green-800':
                      sectionCompleted,
                  }"
                >
                  <span v-show="!sectionCompleted && !sectionStarted" class="text-gray-200 material-symbols-outlined">
                    circle
                  </span>
                  <span v-show="!sectionCompleted && sectionStarted" class="material-symbols-outlined">
                    {{ firstCaseStarted ? 'clock_loader_60' : 'clock_loader_40' }}
                  </span>
                  <span v-show="sectionCompleted" class="material-symbols-outlined"> check_circle </span>
                  <span class="block group-hover:hidden">
                    {{ sectionStarted ? (sectionCompleted ? 'Fertig' : 'In Bearbeitung') : 'Noch nicht bearbeitet' }}
                  </span>
                  <span class="hidden group-hover:block">
                    {{
                      sectionStarted
                        ? sectionCompleted
                          ? 'Fertig'
                          : 'Als vollständig bearbeitet markieren'
                        : 'Bearbeitung starten'
                    }}
                  </span>
                </button>
              </div>
              <!-- End Button Group -->

              <div class="flex justify-between items-center gap-x-2">
                <div>
                  <!-- Tooltip -->
                  <div class="hs-tooltip inline-block [--trigger:hover] [--placement:bottom]">
                    <div class="hs-tooltip-toggle sm:mb-1 block text-start cursor-pointer">
                      <span>
                        <span class="text-xs text-gray-400"> by </span>
                        <span class="font-semibold text-gray-800 dark:text-gray-200">
                          {{ props.section.user.academic_title ? mapTitle(props.section.user.academic_title) + ' ' : ''
                          }}{{ props.section.user.first_name }}
                          {{ props.section.user.last_name }}
                        </span>
                      </span>
                      <div class="text-xs text-gray-400">
                        {{ props.section.user.job_status ? mapJobStatus(props.section.user.job_status) : '' }}
                      </div>
                    </div>
                  </div>
                  <!-- End Tooltip -->
                  <ul class="text-xs text-gray-400">
                    <li
                      class="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full dark:text-gray-400 dark:before:bg-gray-600"
                    >
                      {{ formatDate(props.section.created_at) }}
                    </li>
                  </ul>
                </div>
                <div class="flex-shrink-0">
                  <ProfileImage
                    :image="props.section.user.userProfileImageSmall"
                    initials=""
                    size="4rem"
                    :showIngameLevel="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Avatar Media -->

      <!-- Content -->

      <!--      show: {{ showNative }} / {{ reactiveShowNative }}-->
      <!--      {{ sanitizedSectionContentsHtml.length }}-->
      <!--      {{ translatedContentIfAny.length }}-->
      <!--      full width:{{ !!outerTextContainer ? outerTextContainer.offsetWidth : 'N/A' }} german width:{{-->
      <!--        germanContainerWidth-->
      <!--      }}-->
      <!--      native translation:{{ nativeContainerTranslation }}-->

      <div
        class="mx-auto flex space-y-5 transition-all duration-500 ease-in-out md:space-y-8"
        :style="{
          width: showNative
            ? `${outerTextContainerWidthWithNativeShown}px`
            : `${outerTextContainerWidthWithoutNativeShown}px`,
        }"
        ref="outerTextContainer"
      >
        <div
          class="space-y-3 overflow-hidden transition-all duration-500 ease-in-out"
          :style="{
            gap: `${gap}px`,
          }"
        >
          <div>
            <div class="grid items-center min-w-full w-full relative">
              <h2
                class="text-2xl font-bold md:text-3xl dark:text-white text-center row-start-1 col-start-1 flex justify-center items-center"
              >
                <div class="z-10 flex justify-center items-center px-48">
                  {{ props.section.title }}
                </div>
              </h2>
              <div class="row-start-1 col-start-1 flex justify-end items-center gap-x-3">
                <div class="z-10 flex justify-center items-center">
                  <input
                    type="checkbox"
                    id="hs-basic-with-description-checked"
                    class="relative w-[3.25rem] h-7 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:h-6 before:w-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
                    @change="emit('showNativeToggled', !showNative)"
                    :checked="showNative"
                  />
                  <label
                    for="hs-basic-with-description-checked"
                    class="text-sm text-gray-800 ms-3 dark:text-neutral-400"
                  >
                    Show in {{ languageStore.getLanguageNameFromCode(userNativeLanguage) }}
                  </label>
                </div>
              </div>
              <h3 class="text-lg font-bold md:text-xl dark:text-white text-center text-gray-400" v-show="showNative">
                {{
                  !!props.section.title_translations[userNativeLanguage]
                    ? props.section.title_translations[userNativeLanguage]
                    : ''
                }}
              </h3>
            </div>
          </div>

          <!--          <p-->
          <!--              class="text-base text-justify text-gray-800 dark:text-gray-200"-->
          <!--              v-html="sanitizedSectionContentsHtml">-->
          <!--          </p>-->

          <!--          {{ germanContainerWidth }}-->
          <!--          {{ nativeContainerTranslation }}-->

          <div
            class="grid grid-rows-1 max-h-fit h-fit divide-gray-400 justify-between transition-all duration-500 ease-in-out overflow-hidden"
            :style="{
              width: showNative
                ? `${outerTextContainerWidthWithNativeShown}px`
                : `${outerTextContainerWidthWithoutNativeShown}px`,
            }"
          >
            <div
              ref="germanContentContainer"
              class="col-start-1 transition-all duration-500 ease-in-out row-start-1 h-fit max-h-fit text-base text-justify text-gray-800 dark:text-gray-200 overflow-hidden"
              :style="{ width: `${germanContainerWidth}px` }"
            >
              <TextEditor :content="sanitizedSectionContentsHtml" :allowEdit="false" />
            </div>

            <div
              class="col-start-1 inline-flex row-start-1 text-base text-justify items-justify text-gray-400 dark:text-gray-200 transition-all duration-500 ease-in-out overflow-hidden"
              :style="{
                height: showNative ? `auto` : `${germanContentContainerHeight}px`,
                transform: `translateX(${nativeContainerTranslation}px)`,
                width: `${nativeContainerOuterWidth}px`,
                gap: `${gap}px`,
              }"
            >
              <p class="border-s border-s-gray-100"></p>
              <p :style="{ width: `${nativeContainerInnerWidth}px` }">
                <TextEditor :content="translatedContentIfAny" :allowEdit="false" />
              </p>
            </div>
          </div>

          <div>
            <p class="text-base text-justify text-gray-800 dark:text-gray-200 font-semibold pt-8">Medieninhalte</p>
            <p class="text-base text-justify text-gray-400 dark:text-gray-200 font-semibold" v-show="showNative">
              {{ $t('message.mediaContents') }}
            </p>
          </div>
          <div class="grid grid-cols-3 gap-6 auto-rows-min">
            <div
              v-for="(mediaItem, index) in media"
              :key="index"
              class="transition-all duration-500 ease-in-out"
              :class="{
                'col-span-3 row-auto overflow-hidden': fullWidthIndex === index,
              }"
            >
              <MediaHighlightCard
                :mediaId="mediaItem.id"
                :url="mediaItem.media_url"
                :type="mediaItem.media_type"
                :description="mediaItem.description"
                :allowDescriptionEdit="false"
                :maxOnClick="true"
                :isMaximized="fullWidthIndex === index"
                @toggleFullWidth="toggleFullWidth(index)"
              />
            </div>
          </div>

          <div class="pt-8 mb-3">
            <p class="text-base text-justify text-gray-800 dark:text-gray-200 font-semibold">Übungsfälle</p>
            <p class="text-base text-justify text-gray-400 dark:text-gray-200 font-semibold" v-show="showNative">
              {{ $t('message.casesForPractice') }}
            </p>
          </div>
          <div class="grid grid-cols-3 gap-6">
            <CaseHighlightCard
              v-for="case_ in cases"
              :case="case_"
              :sectionId="props.section.id"
              :chapterId="props.chapterId"
              @caseStarted="courseInteractionStore.trackFirstCaseStarted(props.section.index)"
            />
          </div>
        </div>
      </div>
      <!-- End content -->
    </div>
  </div>
  <!-- End Blog Article -->

  <!-- Bottom button group (not sticky as in template) -->
  <div class="inset-x-0 text-center justify-center flex gap-x-2">
    <button
      v-show="sectionStarted"
      type="button"
      @click.capture="markSection"
      class="flex items-center shadow-md rounded-full py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
      :class="{
        'text-white bg-blue-600 hover:bg-blue-700': !sectionCompleted,
        'text-gray-800 bg-white hover:bg-gray-50': sectionCompleted,
      }"
    >
      <span v-show="!sectionCompleted" class="material-symbols-outlined"> check_circle </span>
      {{ sectionCompleted ? 'Markierung als vollständig bearbeitet aufheben' : 'Als vollständig bearbeitet markieren' }}
      <span v-show="showNative" class="text-gray-400 dark:text-gray-200">{{
        sectionCompleted ? $t('message.revokeMarkAsCompleted') : $t('message.markAsCompleted')
      }}</span>
    </button>

    <button
      type="button"
      @click.capture="alertStore.error('Noch nicht unterstützt')"
      class="flex items-center shadow-md rounded-full text-gray-800 bg-white hover:bg-gray-50 py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
    >
      <span class="material-symbols-outlined"> expand_more </span>
      Notizen
      <span v-show="showNative" class="text-gray-400 dark:text-gray-200">{{ $t('message.notes') }}</span>
    </button>

    <button
      type="button"
      @click.capture="emit('showNativeToggled', !showNative)"
      class="flex items-center shadow-md rounded-full text-gray-800 bg-white hover:bg-gray-50 py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
    >
      <span class="material-symbols-outlined"> language </span>
      Übersetzen
      <span v-show="showNative" class="text-gray-400 dark:text-gray-200">{{ $t('message.translate') }}</span>
    </button>
  </div>
  <!-- End Bottom Button Group -->
</template>

<style scoped></style>
