/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CaseIdOnly } from '../models/CaseIdOnly';
import type { CaseListCreate } from '../models/CaseListCreate';
import type { CaseListDetails } from '../models/CaseListDetails';
import type { CaseListOverview } from '../models/CaseListOverview';
import type { CaseListUpdate } from '../models/CaseListUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CaseListsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Public Case Lists
   * @returns CaseListOverview Successful Response
   * @throws ApiError
   */
  public getPublicCaseLists(): CancelablePromise<Array<CaseListOverview>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/case-lists/',
    });
  }

  /**
   * Create Case List
   * @param requestBody
   * @returns CaseListDetails Successful Response
   * @throws ApiError
   */
  public createCaseList(requestBody: CaseListCreate): CancelablePromise<CaseListDetails> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/case-lists/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Case List
   * @param caseListId
   * @param maxNCases
   * @returns CaseListDetails Successful Response
   * @throws ApiError
   */
  public getCaseList(caseListId: string, maxNCases?: number | null): CancelablePromise<CaseListDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/case-lists/{case_list_id}',
      path: {
        case_list_id: caseListId,
      },
      query: {
        max_n_cases: maxNCases,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Case List
   * @param caseListId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteCaseList(caseListId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/case-lists/{case_list_id}',
      path: {
        case_list_id: caseListId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Case List
   * @param caseListId
   * @param requestBody
   * @returns CaseListDetails Successful Response
   * @throws ApiError
   */
  public updateCaseList(caseListId: string, requestBody: CaseListUpdate): CancelablePromise<CaseListDetails> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/case-lists/{case_list_id}',
      path: {
        case_list_id: caseListId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Cases To Case List
   * @param caseListId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public addCasesToCaseList(caseListId: string, requestBody: Array<CaseIdOnly>): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/case-lists/{case_list_id}/cases',
      path: {
        case_list_id: caseListId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Case List Case
   * @param caseListId
   * @param caseId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteCaseListCase(caseListId: string, caseId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/case-lists/{case_list_id}/cases/{case_id}',
      path: {
        case_list_id: caseListId,
        case_id: caseId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
