<script setup>
import { storeToRefs } from 'pinia';
import { useAlertStore } from '@/stores';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const alertStore = useAlertStore();
const { alerts } = storeToRefs(alertStore);
</script>

<template>
  <div class="container">
    <div v-for="alert in alerts" :key="alert.id" class="relative mb-4">
      <div
        class="bg-white border cursor-pointer border-gray-200 rounded-lg shadow-lg p-4 dark:bg-neutral-800 dark:border-neutral-700"
        role="alert"
        tabindex="-1"
        aria-labelledby="hs-discovery-label"
        @click.prevent="alertStore.clearAlert(alert.id)"
      >
        <div class="flex">
          <div class="shrink-0">
            <span
              :class="{
                'text-red-400': alert.type === 'error',
                'text-green-400': alert.type === 'success',
                'text-blue-400': alert.type === 'info',
                'text-orange-500': alert.type === 'xp',
              }"
              class="material-symbols-outlined"
            >
              {{
                alert.type === 'error'
                  ? 'error'
                  : alert.type === 'success'
                  ? 'check_circle'
                  : alert.type === 'xp'
                  ? 'exercise'
                  : 'info'
              }}
            </span>
          </div>
          <div class="ms-3">
            <h3 id="hs-discovery-label" class="text-gray-800 font-semibold dark:text-white">
              {{ t(alert.title) }}
            </h3>
            <p class="mt-2 text-sm text-gray-700 dark:text-neutral-400">
              {{ t(alert.message) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
