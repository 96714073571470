<script setup lang="ts">
import NewCaseDropdown from '@/components/dropdowns/NewCaseDropdown.vue';
import PatientInteractionListDropdown from '@/components/dropdowns/PatientInteractionListDropdown.vue';
import SignInOrEditProfile from '@/components/headers/SignInOrEditProfile.vue';
import {
  useAuthStore,
  useCaseInteractionStore,
  useMcExamInteractionStore,
  useOralExamInteractionStore,
} from '@/stores';
import { storeToRefs } from 'pinia';
import { feedbackIntegration } from '@sentry/vue';

const authStore = useAuthStore();
const oralExamInteractionStore = useOralExamInteractionStore();
const mcExamInteractionStore = useMcExamInteractionStore();
const caseInteractionStore = useCaseInteractionStore();

const { userId } = storeToRefs(authStore);

const props = defineProps({
  showRootLink: {
    type: Boolean,
    default: true,
  },
  showHomeLink: {
    type: Boolean,
    default: true,
  },
  showNewCaseDropdown: {
    type: Boolean,
    default: true,
  },
  showPatientInteractionListDropdown: {
    type: Boolean,
    default: true,
  },
  showChallengesAndEventsLink: {
    type: Boolean,
    default: true,
  },
  showCommunityLink: {
    type: Boolean,
    default: true,
  },
  showAboutUsLink: {
    type: Boolean,
    default: true,
  },
  showSignInOrEditProfile: {
    type: Boolean,
    default: true,
  },
  showCourseInteractionsLink: {
    type: Boolean,
    default: true,
  },
  testModalRef: {
    type: Object,
    default: null,
  },
});

async function openReportBugModal() {
  const feedback = feedbackIntegration({
    // Disable injecting the default widget
    autoInject: false,
    theme: 'light',
    useSentryUser: {
      name: 'fullName',
      email: 'email',
    },
    themeLight: {
      accentBackground: '#2563eb',
    },
    themeDark: {
      accentBackground: '#2563eb',
    },
  });

  const form = await feedback.createForm();
  form.appendToDom();
  form.open();
}
</script>

<template>
  <nav class="max-w-[85rem] w-full mx-auto px-4 md:px-6 lg:px-8" aria-label="Global">
    <div class="relative md:flex md:items-center md:justify-between">
      <div class="flex items-center justify-between">
        <router-link to="/" v-show="showRootLink">
          <a class="flex-none text-xl font-semibold dark:text-white" aria-label="Brand">casuu</a>
          <a class="flex-none text-xl font-semibold text-blue-600" aria-label="Brand">.health</a>
        </router-link>
        <div class="md:hidden">
          <button
            type="button"
            class="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
            data-hs-collapse="#navbar-collapse-with-animation"
            aria-controls="navbar-collapse-with-animation"
            aria-label="Toggle navigation"
          >
            {{ $t('message.menu') }}
            <span class="material-symbols-outlined hs-collapse-open:hidden"> menu </span>
            <span class="material-symbols-outlined hs-collapse-open:block hidden"> close </span>
          </button>
        </div>
      </div>

      <div
        id="navbar-collapse-with-animation"
        class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block"
      >
        <div class="overflow-hidden overflow-y-auto max-h-[75vh] scrollbar-y">
          <div
            class="flex flex-col gap-x-0 mt-5 divide-y divide-dashed divide-gray-200 md:flex-row md:items-center md:justify-end md:gap-x-7 md:mt-0 md:pl-7 md:divide-y-0 md:divide-solid dark:divide-gray-700"
          >
            <!-- Home = alle Kurse -->
            <router-link
              v-show="showHomeLink"
              to="/home"
              class="font-medium text-gray-500 hover:text-gray-400 py-3 md:py-6 dark:text-gray-400 dark:hover:text-gray-500"
            >
              {{ $t('message.home') }}
            </router-link>

            <!-- Meine Kurse -->
            <router-link
              v-show="showCourseInteractionsLink"
              :to="'/my-courses/' + userId"
              class="font-medium text-gray-500 hover:text-gray-400 py-3 md:py-6 dark:text-gray-400 dark:hover:text-gray-500"
            >
              {{ $t('message.myCourses') }}
            </router-link>

            <!-- Neuen Fall erstellen oder wählen etc. -->
            <div
              class="hs-dropdown group [--strategy:static] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:click] py-3 md:py-4"
              v-show="showNewCaseDropdown"
            >
              <button
                type="button"
                class="gap-x-0.5 hs-dropdown-toggle flex items-center w-full text-gray-500 group-hover:text-gray-400 font-medium dark:text-gray-400 dark:group-hover:text-gray-500"
              >
                {{ $t('message.newCase') }}
                <span
                  class="material-symbols-outlined text-2xl block hs-dropdown-open:rotate-180 transition-all transform duration-300 ease-in-out"
                >
                  expand_more
                </span>
              </button>
              <NewCaseDropdown />
            </div>

            <!-- Meine Fälle -->
            <div
              class="hs-dropdown group [--strategy:static] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:click] py-3 md:py-4"
              v-show="showPatientInteractionListDropdown"
            >
              <button
                type="button"
                class="gap-x-0.5 hs-dropdown-toggle flex items-center w-full text-gray-500 group-hover:text-gray-400 font-medium dark:text-gray-400 dark:group-hover:text-gray-500"
              >
                {{ $t('message.myCases') }}
                <span
                  class="material-symbols-outlined text-2xl block hs-dropdown-open:rotate-180 transition-all transform duration-300 ease-in-out"
                >
                  expand_more
                </span>
              </button>
              <PatientInteractionListDropdown />
            </div>

            <!-- Report bug -->
            <button
              class="font-medium text-gray-500 hover:text-gray-400 py-3 md:py-6 dark:text-gray-400 dark:hover:text-gray-500"
              @click="openReportBugModal"
            >
              {{ $t('message.reportABug') }}
            </button>

            <!-- Über uns -->
            <a
              class="font-medium text-gray-500 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500"
              href="/about-us"
              v-show="showAboutUsLink"
            >
              {{ $t('message.aboutUs') }}
            </a>

            <div v-show="showSignInOrEditProfile">
              <SignInOrEditProfile />
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped></style>
