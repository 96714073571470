<script setup>
import CourseHighlightCard from '@/components/CourseHighlightCard.vue';
import { router } from '@/router';
import { nextTick, onMounted, ref } from 'vue';
import { HSStaticMethods } from 'preline';

const props = defineProps({
  courses: {
    type: Array,
    default: () => [],
  },
});

const imgs = ref([]);

onMounted(async () => {
  // load dummy course images
  for (let i = 0; i < 13; i++) {
    // console.log('Importing: ', `@/assets/images/courses/0${i}.png`);
    let num = i < 10 ? `0${i}` : i;
    import(`../assets/images/courses/${num}.png`).then((img) => {
      imgs.value.push(img.default);
    });
  }
  console.log('Loaded course highlight card images: ', imgs.value.length, ' images loaded.');
});
</script>

<template>
  <div
    class="flex min-h-full h-full max-h-full max-w-[85rem] px-4 py-2 sm:px-6 lg:px-8 lg:py-2 mx-auto overflow-visible"
  >
    <!-- Grid -->
    <div
      class="min-h-full h-full max-h-full min-w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 overflow-visible"
    >
      <CourseHighlightCard
        v-for="(course_, index) in courses"
        :course="course_"
        :imgPath="!!course_.id ? (index < 13 ? imgs[index] : null) : null"
        @createNewCourse="$emit('createNewCourse')"
      />
    </div>
    <!-- End Grid -->
  </div>
</template>

<style scoped></style>
