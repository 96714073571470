<script setup lang="ts">
import { computed } from 'vue';
import DOMPurify from 'dompurify';

const emit = defineEmits(['onClick']);
const props = defineProps({
  header: {
    default: '',
    type: String,
  },
  description: {
    default: '',
    type: String,
  },
  icon: {
    default: '',
    type: String,
  },
});

function onClick() {
  emit('onClick');
}

const description = computed(() => {
  return DOMPurify.sanitize(props.description.replace(/casuu/g, "<span style='color: rgb(37 99 235);'>casuu</span>"));
});
</script>

<template>
  <a
    class="group flex gap-x-5 text-gray-800 hover:bg-gray-50 rounded-md p-4 dark:text-gray-200 dark:hover:bg-gray-900"
    @click.stop="onClick"
    href="#"
  >
    <span class="material-symbols-outlined">
      {{ icon }}
    </span>
    <div class="grow">
      <p class="text-base font-medium text-gray-800 dark:text-gray-200">{{ props.header }}</p>
      <p
        class="text-sm text-gray-500 group-hover:text-gray-800 dark:group-hover:text-gray-200"
        v-html="description"
      ></p>
    </div>
  </a>
</template>

<style scoped></style>
