<script setup>
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  value: {
    type: Number,
    default: 0,
  },
  maxValue: {
    type: Number,
    default: null,
  },
  tooltipText: {
    type: String,
    default: null,
  },
});
</script>

<template>
  <!-- Card -->
  <div class="p-4 flex flex-col bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
    <div class="flex justify-between items-center mb-1">
      <h3 class="mt-2 text-2xl sm:text-3xl lg:text-4xl text-gray-800 dark:text-neutral-200">
        <span class="font-semibold">{{ props.value }}</span>
        <span v-if="props.maxValue != null" class="text-gray-500 dark:text-neutral-500">/ {{ props.maxValue }}</span>
      </h3>
    </div>
    <div class="inline-flex items-center gap-x-2">
      <h3 class="text-gray-500 dark:text-neutral-500">
        {{ props.title }}
      </h3>

      <!-- Tooltip -->
      <div v-if="!!props.tooltipText" class="hs-tooltip inline-block">
        <svg
          class="hs-tooltip-toggle flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-500"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path
            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
          />
        </svg>
        <div
          class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 inline-block absolute invisible z-20 w-60 py-1.5 px-2.5 bg-gray-900 text-xs text-white rounded dark:bg-neutral-700"
          role="tooltip"
        >
          {{ props.tooltipText }}
        </div>
      </div>
      <!-- End Tooltip -->
    </div>
  </div>
  <!-- End Card -->
</template>
