/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Chapter } from '../models/Chapter';
import type { ChapterCreate } from '../models/ChapterCreate';
import type { ChapterInteraction } from '../models/ChapterInteraction';
import type { ChapterOverview } from '../models/ChapterOverview';
import type { ChapterUpdate } from '../models/ChapterUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChaptersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Chapter
   * @param chapterId
   * @returns Chapter Successful Response
   * @throws ApiError
   */
  public getChapter(chapterId: string): CancelablePromise<Chapter> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/chapters/{chapter_id}',
      path: {
        chapter_id: chapterId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Chapter
   * @param chapterId
   * @param requestBody
   * @returns ChapterOverview Successful Response
   * @throws ApiError
   */
  public updateChapter(chapterId: string, requestBody: ChapterUpdate): CancelablePromise<ChapterOverview> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/chapters/{chapter_id}',
      path: {
        chapter_id: chapterId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Chapter
   * @param chapterId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteChapter(chapterId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/chapters/{chapter_id}',
      path: {
        chapter_id: chapterId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Chapter By Course
   * @param courseId
   * @param chapterIndex
   * @returns Chapter Successful Response
   * @throws ApiError
   */
  public getChapterByCourse(courseId: string, chapterIndex: number): CancelablePromise<Chapter> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/chapters/from_course/{course_id}/{chapter_index}',
      path: {
        course_id: courseId,
        chapter_index: chapterIndex,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Chapter
   * @param requestBody
   * @returns Chapter Successful Response
   * @throws ApiError
   */
  public createChapter(requestBody: ChapterCreate): CancelablePromise<Chapter> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/chapters/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Fetch Or Create Chapter Interaction
   * @param chapterId
   * @returns ChapterInteraction Successful Response
   * @throws ApiError
   */
  public fetchOrCreateChapterInteraction(chapterId: string): CancelablePromise<ChapterInteraction> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/chapters/{chapter_id}/interact',
      path: {
        chapter_id: chapterId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
