<script setup lang="ts">
import { ref } from 'vue';
import { useCaseInteractionStore } from '@/stores';

const caseInteractionStore = useCaseInteractionStore();

const emits = defineEmits(['onSubmit']);
const inputText = ref('');

const props = defineProps({
  icon: {
    type: String,
    default: 'send',
  },
  placeholderMessage: {
    type: String,
    default: 'Anfordern',
  },
});

function handleSubmit() {
  emits('onSubmit', inputText.value);
  inputText.value = '';
}
</script>

<template>
  <div class="flex relative pl-20 mt-6 max-w-xl">
    <textarea
      @keyup.enter.prevent="handleSubmit"
      v-model="inputText"
      rows="1"
      class="resize-none w-full border-blue-400 border-2 focus:ring-1 blockpy-3 px-4 py-4 bg-blue-50 text-sm text-gray-800 rounded-lg dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
      :class="[inputText ? 'focus:ring-blue-300' : 'focus:ring-gray-300']"
      :placeholder="props.placeholderMessage"
    />
    <div class="absolute inset-y-0 right-1 flex items-center justify-center">
      <button
        @mousedown="handleSubmit"
        class="z-40 rounded-lg lg:h-10 lg:w-10"
        :class="[
          !caseInteractionStore.somthingIsStreaming
            ? inputText
              ? 'bg-blue-300 hover:bg-blue-700 '
              : 'bg-gray-300 hover:bg-blue-300 '
            : 'bg-gray-400 hover:bg-gray-500 focus:z-10 focus:outline-none ',
        ]"
      >
        <span class="material-symbols-outlined text-white text-3xl lg:text-4xl"> {{ props.icon }} </span>
      </button>
    </div>
  </div>
</template>

<style scoped></style>
