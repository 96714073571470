<script setup>
import { ref, computed, reactive, onMounted } from 'vue';
import PatientCarousel from '@/components/CaseCarousel.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { router } from '@/router';
import {
  useCaseStore,
  useCaseInteractionStore,
  usePatientInteractionStore,
  useAttendingInteractionStore,
  useOralExamInteractionStore,
  useMcExamInteractionStore,
} from '@/stores';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const caseStore = useCaseStore();
const { currentCase } = storeToRefs(caseStore);
const isSubmitting = ref(false);

const isLoading = ref(true);
const originChapterId = ref('');

onMounted(async () => {
  await router.isReady();

  const caseInteractionStore = useCaseInteractionStore();
  // reset learning objectives
  caseInteractionStore.resetLearningObjectives();
  // check if we have a sectionId in the query params
  // if so, we need to set the learning objectives for the section
  if (router.currentRoute.value.query.sectionId) {
    const sectionId = router.currentRoute.value.query.sectionId;
    console.debug('Section ID: ' + sectionId);
    // set the learning objectives for the section
    await caseInteractionStore.setLearningObjectivesForSection(sectionId);
  } else {
    console.debug('No section ID in query params');
  }

  if (router.currentRoute.value.query.chapterId) {
    originChapterId.value = router.currentRoute.value.query.chapterId;
    console.debug('Chapter ID: ' + originChapterId.value);
    // for later routing back to origin
  } else {
    console.debug('No Chapter ID in query params');
  }

  console.debug('Loaded learning objectives: ' + JSON.stringify(caseInteractionStore.currentLearningObjectives));

  caseStore
    .setCurrentCase(router.currentRoute.value.params.caseId)
    .then(() => {
      console.debug('Loaded case: ' + currentCase.value.id);
    })
    .catch((error) => {
      console.error('Error while loading case: ' + error);
    })
    .finally(() => {
      isLoading.value = false;
    });
});

async function onStartCaseClick() {
  console.debug('Starting case ' + currentCase.value.id);

  isSubmitting.value = true;
  await router.isReady();

  // user clicked on "start patient interaction" button, so we create a new patient interaction object
  // but we do not start streaming here, this will be done in the patient interaction view.

  // create case interaction
  const caseInteractionStore = useCaseInteractionStore();
  await caseInteractionStore.createCaseInteraction(currentCase.value.id, t);
  // console.debug('Current case interaction ID: ' + caseInteractionStore.currentCaseInteractionId);
  // console.debug('Current case interaction as string: ' + JSON.stringify(caseInteractionStore.currentCaseInteraction));

  // set stores
  // TODO: proper reset functions !
  const patientInteractionStore = usePatientInteractionStore();
  const attendingInteractionStore = useAttendingInteractionStore();
  const oralExamInteractionStore = useOralExamInteractionStore();
  const mcExamInteractionStore = useMcExamInteractionStore();

  await patientInteractionStore.reset();
  await attendingInteractionStore.reset();
  await oralExamInteractionStore.reset();
  await mcExamInteractionStore.reset();

  await Promise.all([
    patientInteractionStore.setCaseInteraction(caseInteractionStore.currentCaseInteraction),
    attendingInteractionStore.setCaseInteraction(caseInteractionStore.currentCaseInteraction),
    oralExamInteractionStore.setCaseInteraction(caseInteractionStore.currentCaseInteraction),
    mcExamInteractionStore.setCaseInteraction(caseInteractionStore.currentCaseInteraction),
  ]);

  if (!!originChapterId.value) {
    router.push({
      path: '/case-interactions/' + caseInteractionStore.currentCaseInteractionId,
      query: {
        originChapterId: originChapterId.value,
      },
    });
  } else {
    router.push('/case-interactions/' + caseInteractionStore.currentCaseInteractionId);
  }
  console.debug('finished onStartCaseClick()');

  isSubmitting.value = false;
}
</script>

<template>
  <div class="mt-5 flex w-screen h-full justify-center items-center gap-x-2">
    <ProgressButton
      :onclick="onStartCaseClick"
      text="Jetzt starten"
      :showProgress="isSubmitting"
      type="button"
      :disabled="isLoading"
    />
    <!-- TODO i18n -->
  </div>
</template>

<style></style>
