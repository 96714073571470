<script setup>
import { useAuthStore } from '@/stores';
import { ref, onMounted, onBeforeMount, reactive, watch } from 'vue';
import { useRouter } from 'vue-router';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore, useOrganizationStore } from '@/stores';
import 'vue-advanced-cropper/dist/style.css';
import UserListItem from '@/components/dashboard/UserListItem.vue';
import _ from 'lodash';
import SimpleStatsCard from '@/components/dashboard/SimpleStatsCard.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { debounce } from 'lodash';

const router = useRouter();
const alertStore = useAlertStore();
const authStore = useAuthStore();
const organizationStore = useOrganizationStore();

const memberSearchTerm = ref('');

onMounted(async () => {
  organizationStore.loadOrganization();
  organizationStore.loadSeats();
  organizationStore.loadMembers();
});

const debouncedMemberSearch = debounce(
  (searchTerm) => {
    // Load users and managers with the search term
    // This will be debounced to prevent too many requests
    // from being sent to the server
    organizationStore.loadMembers(searchTerm);
  },
  500,
  { maxWait: 1000 },
);

watch(memberSearchTerm, (newValue, oldValue) => {
  debouncedMemberSearch(newValue);
});
</script>
<template>
  <main id="content" class="pt-[59px] px-20">
    <div class="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
      <!-- Page Header -->
      <div class="flex justify-between items-center gap-x-5">
        <div class="flex justify-end items-center gap-x-2">
          <ProgressButton text="Benutzer:in hinzufügen" routerLinkTo="/dashboard/users/add" />
        </div>
      </div>
      <!-- End Page Header -->

      <!-- Stats Grid -->
      <div class="grid grid-cols-2 lg:grid-cols-4 gap-2 md:gap-3 xl:gap-5">
        <SimpleStatsCard
          title="Benutzer:innen"
          :value="organizationStore.numberActiveUsers"
          :maxValue="organizationStore.numberUserSeats"
        />
        <SimpleStatsCard
          title="Manager:innen"
          :value="organizationStore.numberActiveManagers"
          :maxValue="organizationStore.numberManagerSeats"
        />
        <SimpleStatsCard title="Eingeladen" :value="organizationStore.numberActiveInvitedUsers" />
        <SimpleStatsCard
          title="Deaktiviert"
          :value="organizationStore.numberDeactivatedUsers + organizationStore.numberDeactivatedManagers"
        />
      </div>
      <!-- End Stats Grid -->

      <!-- Users Table Card -->
      <div
        class="p-5 space-y-4 flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
      >
        <!-- Filter Group -->
        <div class="grid md:grid-cols-2 gap-y-2 md:gap-y-0 md:gap-x-5">
          <div>
            <!-- Search Input -->
            <div class="relative">
              <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                <svg
                  class="flex-shrink-0 size-4 text-gray-500 dark:text-neutral-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="11" cy="11" r="8" />
                  <path d="m21 21-4.3-4.3" />
                </svg>
              </div>
              <input
                v-model="memberSearchTerm"
                type="text"
                class="py-[7px] px-3 ps-10 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
                placeholder="Benutzer durchsuchen"
              />
            </div>
            <!-- End Search Input -->
          </div>
          <!-- End Col -->

          <div class="flex justify-end items-center gap-x-2"></div>
          <!-- End Col -->
        </div>
        <!-- End Filter Group -->

        <div>
          <!-- Tab Content -->
          <div id="hs-pro-tabs-dut-all" role="tabpanel" aria-labelledby="hs-pro-tabs-dut-item-all">
            <!-- Table Section -->
            <div
              class="overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div class="min-w-full inline-block align-middle">
                <!-- Table -->
                <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                  <thead>
                    <tr
                      class="border-t border-gray-200 divide-x divide-gray-200 dark:border-neutral-700 dark:divide-neutral-700"
                    >
                      <th scope="col" class="min-w-[250px]">
                        <!-- Sort Dropdown -->
                        <div class="relative w-full">
                          <p
                            class="px-5 py-2.5 text-start w-fullgap-x-1 text-sm font-normal text-gray-500 dark:text-neutral-500 dark:focus:bg-neutral-700"
                          >
                            Name
                          </p>
                        </div>
                        <!-- End Sort Dropdown -->
                      </th>

                      <th scope="col" class="min-w-48">
                        <!-- Sort Dropdown -->
                        <div class="relative w-full">
                          <p
                            class="px-5 py-2.5 text-start w-fullgap-x-1 text-sm font-normal text-gray-500 dark:text-neutral-500 dark:focus:bg-neutral-700"
                          >
                            Muttersprache
                          </p>
                        </div>
                        <!-- End Sort Dropdown -->
                      </th>

                      <th scope="col" class="min-w-36">
                        <!-- Sort Dropdown -->
                        <div class="relative w-full">
                          <p
                            class="px-5 py-2.5 text-start w-fullgap-x-1 text-sm font-normal text-gray-500 dark:text-neutral-500 dark:focus:bg-neutral-700"
                          >
                            Rolle
                          </p>
                        </div>
                        <!-- End Sort Dropdown -->
                      </th>

                      <th scope="col" class="min-w-36">
                        <!-- Sort Dropdown -->
                        <div class="relative w-full">
                          <p
                            class="px-5 py-2.5 text-start w-fullgap-x-1 text-sm font-normal text-gray-500 dark:text-neutral-500 dark:focus:bg-neutral-700"
                          >
                            Aktiv
                          </p>
                        </div>
                        <!-- End Sort Dropdown -->
                      </th>

                      <th scope="col">
                        <!-- Sort Dropdown -->
                        <div class="relative w-full">
                          <p
                            class="px-5 py-2.5 text-start w-fullgap-x-1 text-sm font-normal text-gray-500 dark:text-neutral-500 dark:focus:bg-neutral-700"
                          >
                            E-Mail
                          </p>
                        </div>
                        <!-- End Sort Dropdown -->
                      </th>

                      <th scope="col"></th>
                    </tr>
                  </thead>

                  <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                    <UserListItem
                      v-for="member in organizationStore.members"
                      :id="member.user.id"
                      :firstName="member.user.first_name"
                      :lastName="member.user.last_name"
                      :isCurrentUser="member.user.id === authStore.userId"
                      :nativeLanguage="member.user.native_language"
                      :email="member.user.auth_user.email"
                      :isManager="member.is_manager"
                      :isActive="member.deactivated_at === null"
                    />
                  </tbody>
                </table>
                <!-- End Table -->
              </div>
            </div>
            <!-- End Table Section -->
          </div>
          <!-- End Tab Content -->
        </div>
      </div>
      <!-- End Users Table Card -->
    </div>
  </main>
</template>
