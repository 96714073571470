<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue';
import CourseSection from '@/views/courses/CourseSection.vue';
import { router } from '@/router';
import { useAlertStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import BreadcrumpElement from '@/components/BreadcrumpElement.vue';

const courseStore = useCourseStore();
const { currentCourse, currentChapter, currentChapterSections, currentChapterTitle } = storeToRefs(courseStore);

const courseInteractionStore = useCourseInteractionStore();

const alertStore = useAlertStore();
const isLoading = ref(false);

const scrollspyElements = ref([] as NodeListOf<Element>);
const activeScrollspyElement = ref(0);
const chapterId = ref('');

const showNative = ref(false);

onBeforeMount(async () => {
  await router.isReady();
  chapterId.value = router.currentRoute.value.params.chapterId;
  console.log('Premount for chapter: #' + chapterId.value + '#');

  await courseStore.setCurrentChapter(chapterId.value);
  if (!currentChapter.value) {
    alertStore.error('Kapitel nicht gefunden.');
    await router.push('/home');
  }
  let courseInteractionOrUndefined = await courseInteractionStore.setCourseInteractionForCurrentCourseIfEnrolled();
  if (!courseInteractionOrUndefined) {
    alertStore.error('Kurs nicht gefunden oder nicht eingeschrieben.');
    await router.push('/home');
  }
  console.log('Chapter index is: ' + currentChapter.value.index);
  await courseInteractionStore.startChapterByIndex(currentChapter.value.index);
});

function mountingHelper() {
  if (!storeLoaded.value) {
    return;
  }
  scrollspyElements.value = document.querySelectorAll('[data-hs-scrollspy-group]');
  // scrollspyElementActive.value = Array(scrollspyElements.value.length).fill(false);
  activeScrollspyElement.value = 0;
  // console.log('scrollspyElements', scrollspyElements.value);
  isLoading.value = false;
}

onMounted(async () => {
  isLoading.value = true;
  await nextTick(async () => {
    mountingHelper();
  });

  // run resetChangesCounters() if storeLoaded already true or once it turns true
  watch(
    () => storeLoaded.value,
    async (newVal: boolean) => {
      if (newVal) {
        mountingHelper();
      }
    },
    { immediate: true },
  );
  console.log('Scrollspy has elements: ' + JSON.stringify(scrollspyElements.value));
});

const pageId = () => {
  return 'chapter-' + courseStore.currentChapterId;
};

const pageHeading = () => {
  return courseStore.currentChapterTitle;
};

const hashtagedId = () => {
  return '#' + pageId();
};

const scrollspyId = () => {
  return '#scrollspy-' + pageId();
};

const headingToId = (heading: string) => {
  // Replace special characters with their corresponding CSS escaped characters
  let selector = heading.replace(/[^a-zA-Z0-9-]/g, (match) => {
    return match.charCodeAt(0).toString(16).toUpperCase() + ' ';
  });

  selector = selector.trim();
  selector = selector.replace(/\s+/g, '-');
  selector = 'section-' + selector; // Add a prefix to avoid starting with a number

  return selector;
};

async function onPreviousChapter() {
  console.debug('Navigating to previous chapter');
  alertStore.error('Navigation zu vorherigem Kapitel wird noch nicht unterstützt.');
}

async function onNextChapter() {
  console.debug('Navigating to next chapter');
  alertStore.error('Navigation zum nächsten Kapitel wird noch nicht unterstützt.');
}

const sortedSections = computed(() => {
  // order by currentChapterSections.value by index
  const sections = currentChapterSections.value;
  if (!sections) {
    return [];
  }
  sections.sort((a, b) => a.index - b.index);
  return sections;
});

const storeLoaded = computed(() => {
  return currentCourse.value !== null && currentChapter.value !== null && currentChapterSections.value !== null;
});

function isActive(index: number) {
  // Check if the element has the hs-scrollspy-active class
  // console.log('isActive:', scrollspyElements.value[index] ? scrollspyElements.value[index].classList : 'no element');
  // if (scrollspyElements.value[index] && scrollspyElements.value[index].classList.contains('active')) {
  //   console.log('Active scrollspy element: ' + index);
  // }
  // console.log('scrollspyElement in isActive : ' + scrollspyElements.value[index])
  // console.log(scrollspyElements.value[index].classList)
  // console.log('type: ' + typeof scrollspyElements.value[index])
  return scrollspyElements.value[index] && scrollspyElements.value[index].classList.contains('active');
}

function monitorScroll() {
  for (let i = 0; i < scrollspyElements.value.length; i++) {
    if (isActive(i)) {
      activeScrollspyElement.value = i;
    }
  }
}
</script>

<template>
  <div
    v-if="storeLoaded"
    :id="pageId()"
    class="max-h-full justify-center min-w-full min-h-full h-full flex-col overflow-auto"
    @scroll="monitorScroll()"
  >
    <!-- Navigation / scrollspy -->
    <h2
      class="min-w-full sticky top-0 text-base text-gray-800 dark:text-gray-200 bg-white dark:bg-neutral-900 pb-4 justify-center flex z-20"
    >
      <div class="max-w-[85rem] w-full mx-auto px-4 md:px-6 lg:px-8 flex justify-between">
        <ol class="flex items-center whitespace-nowrap max-w-[85rem] w-full">
          <!-- level 1 breadcrump: all courses -->
          <BreadcrumpElement label="Kurse" to="/home" />
          <!-- level 2 breadcrump: current course -->
          <BreadcrumpElement :label="currentCourse.title" :to="'/course/' + currentCourse.id" />
          <!-- level 3 breadcump: current section with scrollspy -->
          <li class="inline-flex items-center text-sm z-40">
            <div class="hs-dropdown relative inline-flex [--placement:top-left]">
              <button
                id="hs-breadcrumb-dropdown"
                type="button"
                class="z-20 hs-dropdown-toggle py-1.5 px-2 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 shadow-sm hover:bg-gray-50 text-gray-500 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:text-blue-600"
              >
                {{ currentChapterTitle }}:
                {{
                  sortedSections[activeScrollspyElement] ? sortedSections[activeScrollspyElement].title : 'No section'
                }}
                <svg
                  class="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
                </svg>
              </button>
              <div
                class="hs-dropdown-menu hs-dropdown-open:opacity-100 min-w-fit w-fit hidden z-20 transition-[margin,opacity] opacity-0 duration-300 mb-2 bg-white shadow-md rounded-lg p-2 dark:bg-neutral-800 dark:border dark:border-gray-700 dark:divide-gray-700"
                aria-labelledby="hs-breadcrumb-dropdown"
              >
                <div
                  class="sticky top-20 mt-1"
                  :data-hs-scrollspy="scrollspyId()"
                  :data-hs-scrollspy-scrollable-parent="hashtagedId()"
                >
                  <a
                    v-for="(section, index) in sortedSections"
                    :key="section.index"
                    data-hs-scrollspy-group=""
                    :id="scrollspyElements[index]"
                    :href="'#' + headingToId(section.title)"
                    class="text-sm font-medium leading-6 text-neutral-700 hover:text-neutral-900 focus:outline-none hs-scrollspy-active:text-blue-600 focus:text-blue-600 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-blue-600 dark:hs-scrollspy-active:text-blue-400 active': true"
                  >
                    <p>
                      <!-- NOTE: does not work without the href! -->
                      {{ section.title }}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </h2>

    <!-- End of Navigation -->

    <!-- Avatar -->
    <!--        <div class="flex items-center">-->
    <!--          <div class="flex-shrink-0">-->
    <!--            <span v-if="props.page.user?.profile_image">-->
    <!--              <ProfileImage :image="props.page.user.profile_image.small" :initials="''" />-->
    <!--            </span>-->
    <!--          </div>-->
    <!--          <div class="ms-2.5 sm:ms-4">-->
    <!--            <h4 v-if="props.page.user?.display_clear_name" class="font-semibold text-white">-->
    <!--              by {{ props.page.user.academic_title }} {{ props.page.user.first_name }}-->
    <!--              {{ props.page.user.last_name }}-->
    <!--            </h4>-->
    <!--            <h4 v-else-if="props.page.user" class="font-semibold text-white">by {{ props.page.user.username }}</h4>-->
    <!--            <h4 v-else class="font-semibold text-white">by anonymous user</h4>-->
    <!--            <p class="text-xs text-white/[.8]">{{ formatDate(props.page.created_at) }}</p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!-- End Avatar -->

    <!-- end of header -->

    <!-- sections -->
    <div id="scrollspy-2" class="space-y-4 justify-center py-4">
      <div
        v-for="section in sortedSections"
        :key="section.index"
        :id="headingToId(section.title)"
        class="mx-auto flex-col justify-center"
      >
        <CourseSection
          :section="section"
          :chapterId="chapterId"
          :showNative="showNative"
          @showNativeToggled="showNative = $event"
        />
      </div>
    </div>
    <!-- End of sections -->
    <!-- End of Content -->
  </div>
  <div v-else>
    <div class="pt-20 flex justify-center items-center h-full">
      <div
        class="animate-spin inline-block w-32 h-32 border-4 border-current border-t-transparent text-blue-600 rounded-full"
        role="status"
        aria-label="loading"
      />
    </div>
  </div>
</template>

<style scoped></style>
