<!-- TODO: Replace "translate" text by flag symbol for the targeted other language -->
<!-- TODO 2: If hit, replace text by translated content (if bilingual mode) -- or redirect to product page to buy -->

<!-- TODO: add CSP as additional layer of security against XSS? <meta http-equiv="Content-Security-Policy" content="script-src 'self';"> etc. ? -->

<script>
// component properties
// message: message content
// messageType: "SAY", "LAB", etc
// conversation: store to use for conversation (currently: PATIENT [default], ATTENDING, ORAL-EXAM or MC-EXAM)

import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import DOMPurify from 'dompurify';

import {
  useAttendingInteractionStore,
  useMcExamInteractionStore,
  useOralExamInteractionStore,
  usePatientInteractionStore,
  useAuthStore,
} from '@/stores';
import { countNumberOfSentences, splitSentences, unobfuscateUserName } from '@/helper';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

export default {
  components: { FontAwesomeIcon },
  setup: () => {
    const patientInteractionStore = usePatientInteractionStore();
    const attendingInteractionStore = useAttendingInteractionStore();
    const oralExamInteractionStore = useOralExamInteractionStore();
    const mcExamInteractionStore = useMcExamInteractionStore();
    const authStore = useAuthStore();

    const { patientLanguage } = storeToRefs(patientInteractionStore);
    const { userNativeLanguage: userNative, userFirstName, userLastName } = storeToRefs(authStore);

    return {
      patientInteractionStore,
      attendingInteractionStore,
      oralExamInteractionStore,
      mcExamInteractionStore,
      authStore,
      userNative,
      patientLanguage,
      userFirstName,
      userLastName,
    };
  },
  data: () => ({
    showTranslation: false,
    showDirectTranslation: false,
    showUserInputRecommendation: false,
    showExaminationReport: false,
    showZurueck: false,
    translationLanguage: '',
    translationOption: '',
    recommendationLanguage: '',
    recommendationOption: '',
  }),
  computed: {
    languagePair() {
      return [this.userNative, this.patientLanguage];
    },
    highlightedDirectTranslation() {
      return this.highlightSentence(
        unobfuscateUserName(
          this.message.translations[this.translationLanguage][this.translationOption],
          this.userFirstName,
          this.userLastName,
        ),
        this.markedSentencesOrigin,
        this.totalNumberOfSentencesOriginalChatMessage,
      );
    },
    totalNumberOfSentencesOriginalChatMessage() {
      return countNumberOfSentences(this.message.content['processed_model_output']);
    },
    highlightedExaminationReportTranslation() {
      return this.highlightSentence(
        unobfuscateUserName(
          this.message.translations[this.translationLanguage]['report'],
          this.userFirstName,
          this.userLastName,
        ),
        this.markedSentencesOrigin,
        this.totalNumberOfSentencesOriginalExaminationReport,
      );
    },
    totalNumberOfSentencesOriginalExaminationReport() {
      return countNumberOfSentences(this.message.report);
    },
  },
  props: {
    markedSentencesOrigin: {
      type: Array,
    },
    message: {
      type: Object,
    },
    messageType: {
      type: String,
    },
    conversation: {
      type: String,
    },
  },
  methods: {
    faGlobe() {
      return faGlobe;
    },
    highlightSentence(text, markedSentences, totalNumberOfSentences) {
      if (text === undefined) {
        return '';
      }
      const sentences = splitSentences(text);
      const numSentencesOrig = totalNumberOfSentences;
      const numSentencesTarget = sentences.length;
      const ratio = numSentencesTarget / numSentencesOrig;
      const startSentence = Math.floor(markedSentences[0] * ratio);
      const endSentence = Math.ceil(markedSentences[markedSentences.length - 1] * ratio);
      const highlightedSentences = [];
      // console.log('markedSentences: ' + markedSentences);
      // console.log('sentences: ' + sentences);
      // console.log('startSentence: ' + startSentence);
      // console.log('endSentence: ' + endSentence);
      // TODO: highlight arabaic etc in reversed direction? How to handle?
      for (let i = 0; i < sentences.length; i++) {
        if (i >= startSentence && i <= endSentence) {
          highlightedSentences.push('<span class="bg-yellow-200">' + sentences[i] + '</span>');
        } else {
          highlightedSentences.push(sentences[i]);
        }
      }
      return DOMPurify.sanitize(highlightedSentences.join('')); // to prevent XSS attacks (smuggled into translation)
    },

    async toggleTranslation(message, languagePair) {
      // console.debug('Toggle translation hit')
      this.showTranslation = !this.showTranslation;
      this.$emit('onTranslationToggled', this.showTranslation);
      console.debug(this.showTranslation);
      if (!this.showTranslation) {
        await this.reset(message, this.languagePair);
        return;
      }
      console.debug('Type of message is: ' + this.messageType);
      await this.getTranslation(message, languagePair);
    },

    getOtherElement(list, element, defaultElement = null) {
      console.debug(list);
      console.debug(element);
      if (list.length !== 2) {
        throw new Error('List must contain exactly two elements');
      }

      if (list[0] === element) {
        return list[1];
      } else if (list[1] === element) {
        return list[0];
      } else {
        if (defaultElement !== null) {
          return defaultElement;
        }
        throw new Error('Element not found in the list');
      }
    },

    async reset(message, languagePair) {
      if (this.messageType === 'LAB') {
        const defaultLanguage = message[0].language;
        await this.patientInteractionStore.translateLabSheet(message, defaultLanguage);
      }
      // else nothing to do
    },

    async getTranslation(message, languagePair) {
      console.debug('Conversation is: ' + this.conversation);
      let store = this.patientInteractionStore;
      if (this.conversation === 'PATIENT') {
        store = this.patientInteractionStore;
      } else if (this.conversation === 'ATTENDING') {
        store = this.attendingInteractionStore;
      } else if (this.conversation === 'ORAL-EXAM') {
        store = this.oralExamInteractionStore;
      } else if (this.conversation === 'MC-EXAM') {
        store = this.mcExamInteractionStore;
      } else {
        throw new Error('Conversation not supported');
      }

      console.debug('Message type is: ' + this.messageType);
      // SAY logic
      // - translations of messages from system to user
      if (
        this.messageType === 'DESCRIPTION' ||
        this.messageType === 'SAY-PATIENT' ||
        this.messageType === 'SAY-ATTENDING' ||
        this.messageType === 'SAY-ORAL-EXAM' ||
        this.messageType === 'SAY-MC-EXAM'
      ) {
        const targetLanguage = this.getOtherElement(languagePair, message.language);
        this.translationLanguage = targetLanguage;
        this.translationOption = 'system_response_translation';
        if (this.messageType === 'DESCRIPTION') {
          await store.translateDescMessage(message, this.translationLanguage, this.translationOption);
        } else {
          await store.translateChatMessage(message, this.translationLanguage, this.translationOption);
        }
        // await store.translateChatMessage(message, this.translationLanguage, this.translationOption);
        this.showDirectTranslation = true;
        this.showUserInputRecommendation = false;
        // - translations of messages from user to system
      } else if (this.messageType === 'SAY-USER') {
        console.debug('Original input language: ' + message.content['user_message_language']);
        console.debug('Original output language: ' + message.language);
        console.debug("User's native language: " + this.userNative);
        if (message.content['user_message_language'] === this.userNative) {
          // so user wants translation to get a suggestion
          // how to translate what he wants to say (the message in his native language) in the other language
          const targetLanguage = this.getOtherElement(languagePair, message.content['user_message_language']);
          this.recommendationLanguage = targetLanguage;
          this.recommendationOption = 'user_input_recommendation';
          await store.translateChatMessage(message, this.recommendationLanguage, this.recommendationOption);
          this.showDirectTranslation = false;
          this.showUserInputRecommendation = true;
          // return 'Formulierungsvorschlag: ' + recommendation;
        } // so user tries to speak in a non-native language => he wants to know what he actually said (in his native
        // language) and what he could have said (in the foreign language)
        else {
          const targetLanguage = message.content['user_message_language'];
          this.recommendationLanguage = targetLanguage;
          this.recommendationOption = 'user_input_recommendation';
          console.debug('Getting recommended version for language: ' + targetLanguage);
          await store.translateChatMessage(message, this.recommendationLanguage, this.recommendationOption);

          const nativeLanguage = this.userNative;
          this.translationLanguage = nativeLanguage;
          this.translationOption = 'user_input_translation';
          console.debug('Getting direct translation for language: ' + nativeLanguage);
          await store.translateChatMessage(message, this.translationLanguage, this.translationOption);

          console.debug('Message object is: ' + JSON.stringify(message));
          this.showDirectTranslation = true;
          this.showUserInputRecommendation = true;
          // return 'Direkte Übersetzung: ' + translation + '\n' + 'Formulierungsvorschlag: ' + recommendation;
        }
      }
      // End of SAY logic

      // EXAMINATION logic
      else if (this.messageType === 'EXAMINATION') {
        const targetLanguage = this.getOtherElement(this.languagePair, message.language);
        await store.translateExamination(message, targetLanguage);
        this.translationLanguage = targetLanguage;
        this.showExaminationReport = true;
      }
      // End of EXAMINATION logic

      // LAB logic
      else if (this.messageType === 'LAB') {
        console.debug('Lab is in language: ' + message[0].language);
        const targetLanguage = this.getOtherElement(languagePair, message[0].language);
        console.debug('So translating to: ' + targetLanguage);
        await store.translateLabSheet(message, targetLanguage);
        this.showZurueck = true;
      }
      // End of LAB logic

      // Default
      else {
        this.showZurueck = true;
      }
    },
  },
};
</script>

<template>
  <button
    type="button"
    @click="toggleTranslation(this.message, this.languagePair)"
    class="text-xs text-left py-2 px-3 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent text-gray-500 hover:bg-gray-50 focus:outline-none transition-all text-sm dark:hover:bg-neutral-800 dark:hover:text-gray-400 dark:hover:border-gray-900"
  >
    <div v-if="!this.showTranslation">
      <span class="material-symbols-outlined"> language </span>
    </div>
    <div v-if="this.showTranslation">
      <div v-if="this.showZurueck">Zurück</div>
      <div v-else-if="this.showExaminationReport">
        {{
          this.message.translations[translationLanguage]['name']
            ? this.message.translations[translationLanguage]['name'] + ':'
            : ''
        }}
        <!-- {{ this.message.translations[translationLanguage]['report'] }} -->
        <div v-html="this.highlightedExaminationReportTranslation"></div>
      </div>
      <div v-else>
        <div v-if="this.showDirectTranslation">
          <!-- Direkte Übersetzung: {{ message.translations[translationLanguage][translationOption] }} -->
          Direkte Übersetzung:
          <div v-html="this.highlightedDirectTranslation"></div>
        </div>
        <div v-if="this.showUserInputRecommendation">
          Formulierungsvorschlag: {{ this.message.translations[recommendationLanguage][recommendationOption] }}
        </div>
      </div>
      <!-- {{ message.translations[languagePair[0]]['system_response_translation'] }} -->
    </div>
    <div v-else class="space-x-4">Übersetzen</div>
    <!-- <p>Selected sentences: {{ markedSentencesOrigin }}</p> -->
  </button>
</template>

<style scoped></style>
