<script setup lang="ts">
import { AlertError } from '@/components';
import { computed, onMounted, ref, watch } from 'vue';
import { useAlertStore, useAuthStore } from '@/stores';

import { type IStaticMethods } from 'preline/preline';
import { storeToRefs } from 'pinia';
import { getLevelFromXp } from '@/helper';
const authStore = useAuthStore();
const alertStore = useAlertStore();
const { userLevel } = storeToRefs(authStore);
const originalLevel = ref(null);
const mountingComplete = ref(false);

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

onMounted(() => {
  setTimeout(() => {
    console.log('App.vue:autoInit');
    console.log(window.HSStaticMethods);
    window.HSStaticMethods.autoInit();
  }, 100);
  originalLevel.value = userLevel.value;
  mountingComplete.value = true;
});

watch(
  () => userLevel.value,
  () => {
    if (originalLevel.value === userLevel.value || !mountingComplete.value || userLevel.value === null) {
      return;
    }
    originalLevel.value = userLevel.value;
    console.log('Level up!');
    alertStore.xp(t('message.levelUp', userLevel.value), t('message.levelUpTitle'));
  },
  { immediate: true },
);

onMounted(async () => {});
</script>

<template>
  <div class="app-container flex min-h-full h-full max-h-full w-full min-w-full max-w-full overflow-hidden">
    <div class="absolute min-w-fit top-0 right-0 mt-4 mr-4 z-[120]">
      <AlertError />
    </div>
    <div class="flex max-h-full h-full min-h-full min-w-full w-full max-w-full overflow-hidden">
      <router-view />
    </div>
  </div>
</template>

<style>
@import 'icon_fonts.css';
</style>
