<script setup>
import { computed } from 'vue';
const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: null,
  },
  color: {
    type: String,
    default: 'gray',
  },
});

const colorClasses = computed(() => [
  'bg-' + props.color + '-100',
  'text-' + props.color + '-800',
  'dark:bg-neutral-500/20',
  'dark:text-neutral-500',
]);
</script>

<template>
  <span
    class="py-0.5 ps-1.5 pe-2.5 inline-flex items-center gap-x-1.5 text-xs font-medium rounded-full"
    :class="colorClasses"
  >
    <span v-if="props.icon" class="material-symbols-outlined">{{ props.icon }}</span>
    {{ props.text }}
  </span>
</template>
