<script setup lang="ts">
import { computed } from 'vue';

const taskIcons = {
  MAKE_OPEN_CONVERSATION: 'forum',
  MAKE_CLOSED_EXPLANATION: 'chat-arrow_forward',
  MAKE_CASE_PRESENTATION: 'co_present-arrow_forward',
  RECEIVE_HANDOVER: 'hearing',
  MAKE_HANDOVER: 'feedback',
  RECEIVE_DOCUMENTATION: 'local_library',
  MAKE_DOCUMENTATION: 'edit_note',
};

const props = defineProps({
  taskType: String,
  showText: {
    type: Boolean,
    default: false,
  },
});

const icons = computed(() => {
  let icons = props.taskType ? taskIcons[props.taskType] : null;
  if (icons === null) {
    return ['touch_app'];
  }
  icons = icons.split('-');
  return icons;
});
</script>

<template>
  <span class="flex items-center justify-between text-gray-500">
    <span class="flex items-center">
      <span class="material-symbols-outlined">
        {{ icons[0] }}
      </span>
      <sup class="align-super">
        <span v-for="icon in icons.slice(1)" class="material-symbols-outlined text-sm">
          {{ icon }}
        </span>
      </sup>
    </span>
    <span v-if="showText" class="flex items-center ml-7">
      <span v-if="taskType === 'MAKE_OPEN_CONVERSATION'"
        >Ein offenes Gespräch führen (z.B. Anamnese oder unter Kollegen)</span
      >
      <span v-if="taskType === 'MAKE_CLOSED_EXPLANATION'" class="-ml-3.5"
        >Etwas geschlossen erläutern (z.B. Maßnahmen erklären)</span
      >
      <span v-if="taskType === 'MAKE_CASE_PRESENTATION'" class="-ml-3.5">Einen Fall vorstellen</span>
      <span v-if="taskType === 'RECEIVE_HANDOVER'">Eine Übergabe erhalten</span>
      <span v-if="taskType === 'MAKE_HANDOVER'">Eine Übergabe machen</span>
      <span v-if="taskType === 'RECEIVE_DOCUMENTATION'"
        >Dokumentation oder Unterlagen erhalten (z.B. BZ-Tagesprotokoll)</span
      >
      <span v-if="taskType === 'MAKE_DOCUMENTATION'">Dokumentation erstellen (z.B. Anamnesebogen)</span>
    </span>
  </span>
</template>

<style scoped></style>
