<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { HSAccordion, HSDropdown, HSStaticMethods } from 'preline';
import ProfileImage from '@/components/ProfileImage.vue';
import PersonCard from '@/components/new_case/sections/PersonCard.vue';
import TaskCombinedIcon from '@/components/various/TaskCombinedIcon.vue';
import { updateMinTextareaHeight } from '@/helper';
import { ErrorMessage, useField } from 'vee-validate';
import SettingInput from '@/components/SettingInput.vue';

const taskTypes = [
  'MAKE_OPEN_CONVERSATION',
  'MAKE_CLOSED_EXPLANATION',
  'MAKE_CASE_PRESENTATION',
  'RECEIVE_HANDOVER',
  'MAKE_HANDOVER',
  'RECEIVE_DOCUMENTATION',
  'MAKE_DOCUMENTATION',
]; // TODO load from backend (TaskType enum)

const task = defineModel('task', {
  type: Object,
  required: true,
});
const persons = defineModel('persons', {
  type: Array,
  required: true,
});
const { number, startingFromCompleteCase } = defineProps(['number', 'startingFromCompleteCase']);
const selectedPersonIds = ref([]);
const personalConcernsValid = ref([]);
const personCardRefs = ref([]);

const criterionErrors = ref([]);

const emit = defineEmits(['removeTask', 'closeAllOtherTaskEdits', 'isValid']);
const accordion = ref(null);
const typeDropdown = ref(null);
const isEditing = ref(false);
const scrollContainer = ref(null);

const taskIndexOrCreate = computed(() => {
  return task.value.index !== null ? task.value.index : 'create';
});

const studentRole = ref('Arzt');
const isTouched = ref(false);

const dropdownId = computed(() => {
  return 'hs-dropdown-scene-create-trigger-' + taskIndexOrCreate.value;
});
const accordionId = computed(() => {
  return 'hs-basic-with-arrow-heading-' + taskIndexOrCreate.value;
});
const accordionContentId = computed(() => {
  return 'hs-basic-with-arrow-collapse-' + taskIndexOrCreate.value;
});
const typeDropdownToggleId = computed(() => {
  return 'hs-dropdown-scene-toggle-' + taskIndexOrCreate.value;
});
const typeDropdownId = computed(() => {
  return 'hs-dropdown-scene-' + taskIndexOrCreate.value;
});

const { value: localTitle, errors: titleErrors } = useField('title', 'max:50|required', {
  initialValue: task.value.title,
});
const { value: localDescription, errors: descriptionErrors } = useField('description', 'max:300|required', {
  initialValue: task.value.details.description,
});
const { value: localStartingPoint, errors: startingpointErrors } = useField(
  'details.starting_point',
  'max:500|required',
  {
    initialValue: task.value.details.starting_point,
  },
);
const { value: localType, errors: typeErrors } = useField('type', 'required', {
  initialValue: task.value.type,
});
const { value: localSubtaskTarget, validate: validateSubtaskTarget } = useField('target', 'required|max:150', {
  initialValue: task.value.subtasks[0].details.target,
});

async function emitRemoveTask(index) {
  emit('removeTask', index);
}

function toggleSelectPerson(id, personalConcern, personTaskInvolvement) {
  const idInSelected = selectedPersonIds.value.findIndex((i) => i === id);
  if (idInSelected === -1) {
    selectedPersonIds.value.push(id);
    task.value.person_task_concerns.push({ create_id: id, details: { concern: personalConcern } });
    task.value.person_task_involvements.push({ create_id: id, type: personTaskInvolvement });

    const personIndex = persons.value.findIndex((p) => p.create_id === id);

    personalConcernsValid.value.push(personCardRefs.value[personIndex].value[0].allValid);
    console.log(personalConcernsValid.value);
  } else {
    selectedPersonIds.value.splice(idInSelected, 1);
    const index = task.value.person_task_concerns.findIndex((concern) => concern.create_id === id);
    task.value.person_task_concerns.splice(index, 1);
    task.value.person_task_involvements.splice(index, 1);
    personalConcernsValid.value.splice(idInSelected, 1);
  }

  // const idInSelected = selectedPersonIds.value.findIndex((i) => i === id);
  // if (idInSelected === -1) {
  //   selectedPersonIds.value.push(id);
  // } else {
  //   selectedPersonIds.value.splice(idInSelected, 1);
  // }

  setPersonalConcern(id, personalConcern);
  setPersonTaskInvolvement(id, personTaskInvolvement);

  // console.log(JSON.stringify(task.value.person_task_concerns));

  emit('isValid', allValid.value); // manually trigger
  console.log('toggle emitted (manually triggered as person selected): isValid', allValid.value);
}

const isSelected = (index) => {
  return selectedPersonIds.value.includes(index);
};

const atLeastOnePersonSelected = computed(() => {
  return selectedPersonIds.value.length > 0;
});

function toggleEditTask(event) {
  event.stopPropagation();

  console.log('toggleEditTask');

  if (isEditing.value) {
    // closed for the first time => set isTouch to true
    isTouched.value = true;
  }

  isEditing.value = !isEditing.value;

  if (isEditing.value) {
    console.log('Showing ', accordion.value);
    accordion.value.show();
    emit('closeAllOtherTaskEdits');
    return;
  }
  accordion.value.hide();
}

function closeEdit() {
  if (!isEditing.value) {
    return;
  }
  isEditing.value = false;
  typeDropdown.value.close();
  accordion.value.hide();
}

function setPersonalConcern(create_id, concern) {
  // console.log('Received: ', create_id, concern);
  const index = task.value.person_task_concerns.findIndex((concern) => concern.create_id === create_id);
  if (index === -1) {
    console.log('concern not found for create_id: ', create_id);
    // task.value.person_task_concerns.push({ create_id: create_id, details: { concern: concern } });
  } else {
    console.log('setting concern for index: ', index, ' (create_id: ', create_id, ') to ', concern);
    task.value.person_task_concerns[index].details.concern = concern;
  }

  const personIndex = persons.value.findIndex((p) => p.create_id === create_id);
  const concernIndex = selectedPersonIds.value.findIndex((i) => i === create_id);
  // console.log('personIndex: ', personIndex);
  // console.log('valid? ', personCardRefs.value[personIndex].value[0].allValid);

  if (!!personCardRefs.value[personIndex].value) {
    // not set before mounted, i.e. when setting loaded concerns (which dont have to be validated anyways)
    personalConcernsValid.value[concernIndex] = personCardRefs.value[personIndex].value[0].allValid;
  }

  // console.log(JSON.stringify(task.value.person_task_concerns));
  // console.log('personalConcernsValid: ', personalConcernsValid.value);

  emit('isValid', allValid.value); // manually trigger
  console.log('set concern emitted (manually triggered as concern edited): isValid', allValid.value);
}

function setPersonTaskInvolvement(create_id, involvement) {
  // console.log('Received: ', create_id, involvement);
  const index = task.value.person_task_involvements.findIndex((involvement) => involvement.create_id === create_id);
  if (index === -1) {
    console.log('concern not found for create_id: ', create_id);
    // task.value.person_task_involvements.push({ create_id: create_id, type: involvement });
  } else {
    task.value.person_task_involvements[index].type = involvement;
  }
}

onMounted(async () => {
  await nextTick(async () => {
    HSStaticMethods.autoInit();
    // const el = HSDropdown.getInstance('#' + dropdownId.value);
    accordion.value = new HSAccordion(document.getElementById(accordionId.value));

    console.log('accordion: ', accordion.value);
    console.log('accordingId: ', accordionId.value);
    // console.log('accordion 0: ', accordion.value[0]);
    // console.log('accordion target: ', accordion.value.target);
    // console.log('accordion el: ', accordion.value.el);

    // this is a workaround for https://github.com/htmlstreamofficial/preline/issues/252
    let originalEditState = isEditing.value;
    isEditing.value = true;
    await nextTick();
    if (originalEditState) {
      accordion.value.show();
      emit('closeAllOtherTaskEdits');
    } else {
      console.log('Hiding ', accordion.value);
      accordion.value.hide();
    }
    isEditing.value = originalEditState;
    // end of workaround

    typeDropdown.value = new HSDropdown(document.getElementById(typeDropdownId.value));

    accordion.value.on('close', () => {
      typeDropdown.value.close();
    });

    for (let i = 0; i < persons.value.length; i++) {
      personCardRefs.value.push(ref(null));
    }

    for (let concern of task.value.person_task_concerns) {
      selectedPersonIds.value.push(concern.create_id);
      personalConcernsValid.value.push(true);
      setPersonalConcern(concern.create_id, concern.details.concern);
    }
    for (let involvement of task.value.person_task_involvements) {
      // console.log('involvement: ', involvement);
      // console.log('involvement.create_id: ', involvement.create_id);
      // console.log('involvement.type: ', involvement.type);
      setPersonTaskInvolvement(involvement.create_id, involvement.type);
    }
  });
});

watch(
  () => persons.value,
  (newValue) => {
    // console.log('persons changed', newValue);
    for (let i = 0; i < newValue.length; i++) {
      personCardRefs.value.push(ref(null));
    }
  },
);

function centerPerson(item) {
  const container = scrollContainer.value;

  if (!container) {
    return;
  }

  // Get the container's width and scroll position
  const containerWidth = container.clientWidth;
  const containerScrollLeft = container.scrollLeft;

  // Get the item's width and position relative to the container
  const itemWidth = item.clientWidth;
  const itemOffsetLeft = item.offsetLeft;

  // Calculate the scroll position to center the item
  const scrollTo = itemOffsetLeft - containerWidth / 2 + itemWidth / 2;
  // console.log('scrollTo', scrollTo);

  // Smoothly scroll the container to the calculated position
  container.scroll({
    left: scrollTo,
    behavior: 'smooth',
  });
}

const allValid = computed(() => {
  // console.log('TypeErrors: ', typeErrors.value);
  // console.log('TypeErrors: ', typeErrors.value.length);
  // console.log('TitleErrors: ', titleErrors.value);
  // console.log('TitleErrors: ', titleErrors.value.length);
  // console.log('CriterionErrors: ', criterionErrors.value);
  // console.log('Personal concerns valid: ', personalConcernsValid.value);
  let valid =
    task.value.title !== '' &&
    task.value.title != null &&
    task.value.details.description !== '' &&
    task.value.details.description != null &&
    task.value.type !== '' &&
    task.value.type != null &&
    task.value.subtasks.length > 0 &&
    task.value.subtasks[0].details.target !== '' &&
    titleErrors.value.length === 0 &&
    descriptionErrors.value.length === 0 &&
    typeErrors.value.length === 0 &&
    atLeastOnePersonSelected.value &&
    !criterionErrors.value.some((error) => error.length > 0) &&
    personalConcernsValid.value.every((isValid) => isValid);
  console.log(
    'SceneCard: isValid',
    task.value.title !== '' && task.value.title != null,
    task.value.details.description !== '' && task.value.details.description != null,
    task.value.type !== '' && task.value.type != null,
    task.value.subtasks.length > 0,
    task.value.subtasks.length > 0 ? task.value.subtasks[0].details.target !== '' : 'N/A',
    titleErrors.value.length === 0,
    descriptionErrors.value.length === 0,
    typeErrors.value.length === 0,
    atLeastOnePersonSelected.value,
    !criterionErrors.value.some((error) => error.length > 0),
    personalConcernsValid.value.every((isValid) => isValid),
  );
  console.log('personalConcernsValid', personalConcernsValid.value);
  console.log('SceneCard: isValid', valid);

  return valid;
});

watch(
  () => allValid.value,
  (value) => {
    console.log('SceneCard watcher emitting: isValid', value);
    emit('isValid', value);
  },
);

const allowSelectDateAndTime = computed(() => {
  return true;
  // return task.value.index === 0;
});

defineExpose({
  closeEdit,
});
</script>

<template>
  <div class="hs-accordion-group flex min-w-full w-full max-w-full">
    <div
      @click="toggleEditTask"
      class="hs-accordion group cursor-pointer p-4 min-w-full w-full max-w-full flex flex-col justify-center bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
      :class="{
        active: isEditing,
        'border border-red-600': !allValid && isTouched,
        'hover:bg-gray-50 dark:hover:bg-neutral-700': !isEditing,
      }"
      :id="accordionId"
    >
      <!-- setting 'active' as a conditional class is part of the workaround for https://github.com/htmlstreamofficial/preline/issues/252 -->

      <!-- Task header -->
      <!-- Note: removed "hs-accordion-toggle" to handle it in own function only -->
      <button class="min-w-full w-full max-w-full flex flex-row justify-between">
        <div class="relative flex-grow justify-between gap-x-4">
          <!-- Title + type + desc -->
          <div class="text-start">
            <!-- Title -->
            <div class="grow flex flex-col justify-start text-start">
              <div class="inline-flex items-center gap-x-2 pb-2">
                <h1 class="text-3xl text-gray-800 dark:text-neutral-200 font-light pr-5">{{ number }}.</h1>
                <h3 class="flex flex-grow font-medium text-gray-800 dark:text-neutral-200 pr-1">
                  <span v-if="!isEditing && task.title === null" class="flex max-w-full w-full min-w-full text-left">
                    Zum Bearbeiten klicken
                  </span>
                  <span
                    v-else-if="!isEditing && task.title !== null"
                    class="flex min-h-fit h-fit max-h-fit max-w-full w-full min-w-full"
                  >
                    {{ task.title }}
                  </span>
                  <span class="flex max-w-full w-full min-w-full" v-else>
                    <textarea
                      @click="
                        (event) => {
                          typeDropdown.close();
                          event.stopPropagation();
                        }
                      "
                      @keyup.space.prevent="(event) => event.stopPropagation()"
                      v-model="task.title"
                      :disabled="!isEditing"
                      placeholder="Aufgabentitel wählen"
                      class="resize-none px-0 py-0.5 my-0.5 mr-0.5"
                      @input="
                        (event) => {
                          updateMinTextareaHeight(event.target);
                          localTitle = event.target.value;
                        }
                      "
                      rows="1"
                      :class="{
                        'cursor-text border border-gray-200 rounded-lg bg-white': isEditing,
                        'border-transparent bg-transparent': !isEditing,
                        'border-red-500 focus:border-red-500 focus:ring-red-500':
                          (task.title === '' || task.title == null || titleErrors.length > 0) && isTouched,
                      }"
                      :style="{
                        width: '100%',
                        'box-sizing': 'border-box',
                      }"
                    />
                  </span>
                </h3>
              </div>
              <span v-if="isEditing" class="text-xs text-red-600 mt-1">{{ titleErrors[0] }}</span>
              <span
                v-if="isEditing && (task.title === '' || task.title == null) && titleErrors.length === 0 && isTouched"
                class="text-xs text-red-600"
                >Dies ist ein Pflichtfeld</span
              >
            </div>
            <!-- End title -->

            <!-- Type / Desc -->
            <div class="inline-flex items-start gap-x-2 pr-1 w-full min-w-full max-w-full justify-between">
              <div class="flex-col" :class="{ 'pb-0': isEditing }">
                <div v-show="!!isEditing" class="text-left text-xs text-gray-800 pb-1 pt-2">
                  <label> Typ * </label>
                </div>
                <div class="hs-dropdown" :id="typeDropdownId">
                  <div
                    class="hs-dropdown-toggle flex flex-row items-center flex-shrink-0"
                    :id="typeDropdownToggleId"
                    :class="{
                      'border border-gray-200 rounded-lg bg-white': isEditing,
                      'border-transparent bg-transparent': !isEditing,
                      'border-red-500 focus:border-red-500 focus:ring-red-500':
                        (task.type === '' || task.type == null || typeErrors.length > 0) && isTouched,
                    }"
                    @click="
                      (event) => {
                        isEditing ? event.stopPropagation() : toggleEditTask(event);
                      }
                    "
                  >
                    <TaskCombinedIcon :taskType="task.type" />
                    <span class="text-xs sm:text-sm text-gray-800 dark:text-neutral-500 pr-1" v-show="isEditing">
                      {{ !task.type ? 'Aufgabentyp' : '' }}
                    </span>
                    <span v-show="isEditing" class="material-symbols-outlined"> expand_more </span>
                  </div>
                  <span v-if="isEditing" class="text-xs text-red-600 mt-1">{{ typeErrors[0] }}</span>
                  <span
                    v-if="isEditing && (task.type === '' || task.type == null) && typeErrors.length === 0 && isTouched"
                    class="text-xs text-red-600"
                    >Dies ist ein Pflichtfeld</span
                  >
                  <div
                    class="hs-dropdown-menu z-10 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full"
                    :aria-labelledby="typeDropdownToggleId"
                  >
                    <button
                      v-for="type in taskTypes"
                      class="flex w-full items-center justify-between gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700"
                      @click="
                        (event) => {
                          task.type = type;
                          localType = type; // for validation
                          typeDropdown.close();
                          event.stopPropagation();
                        }
                      "
                    >
                      <TaskCombinedIcon :taskType="type" :showText="true" />
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="flex flex-grow text-xs sm:text-sm text-gray-800 dark:text-neutral-500"
                :class="{
                  'min-w-fit w-[80vH] max-w-[80vH] ': isEditing,
                  'min-w-full w-full max-w-full text-start': !isEditing,
                }"
              >
                <div v-if="!isEditing" class="flex max-w-full w-full min-w-full text-left">
                  {{ task.details?.description }}
                </div>
                <div v-else-if="isEditing" class="flex-col max-w-full w-full min-w-full items-start text-start">
                  <div class="text-left text-xs pb-1 pt-2">
                    <label> Kurzbeschreibung * </label>
                  </div>
                  <textarea
                    @click="
                      (event) => {
                        typeDropdown.close();
                        event.stopPropagation();
                      }
                    "
                    @keyup.space.prevent="(event) => event.stopPropagation()"
                    v-model="task.details.description"
                    :disabled="!isEditing"
                    :placeholder="'Worum geht es bei dieser Aufgabe? (Stichpunkte oder ein kurzer Satz)'"
                    class="resize-none px-0 py-0.5 -mb-1 mr-0.5 text-xs sm:text-sm text-gray-800"
                    @input="
                      (event) => {
                        updateMinTextareaHeight(event.target, 2);
                        localDescription = event.target.value;
                      }
                    "
                    rows="2"
                    :class="{
                      'cursor-text border border-gray-200 rounded-lg bg-white': isEditing,
                      'border-transparent bg-transparent': !isEditing,
                      'border-red-500 focus:border-red-500 focus:ring-red-500':
                        (task.details.description === '' ||
                          task.details.description == null ||
                          descriptionErrors.length > 0) &&
                        isTouched,
                    }"
                    :style="{
                      width: '100%',
                      'box-sizing': 'border-box',
                    }"
                  />
                  <span class="text-xs text-red-600 mt-1">{{ descriptionErrors[0] }}</span>
                  <span
                    v-if="
                      (task.details.description === '' || task.details.description == null) &&
                      descriptionErrors.length === 0 &&
                      isTouched
                    "
                    class="text-xs text-red-600"
                    >Dies ist ein Pflichtfeld</span
                  >
                </div>
              </div>
            </div>
            <!-- End type / desc -->
          </div>
          <!-- End Title + type + desc -->

          <!-- Start date + situation -->
          <div
            class="inline-flex items-start gap-x-2 max-w-full w-full min-w-full pr-1 grow text-xs sm:text-sm text-gray-800 dark:text-neutral-500"
          >
            <div v-show="isEditing && allowSelectDateAndTime">
              <div class="flex-col">
                <div class="text-left text-xs pb-1 pt-2">
                  <label> Zeit & Ort </label>
                </div>
                <div
                  @click="
                    (event) => {
                      typeDropdown.close();
                      event.stopPropagation();
                    }
                  "
                >
                  <!-- once fixed in vue, replace update/value parameters by: -->
                  <!--                  :v-model:location="task.details.location"-->
                  <!--                  :v-model:date="task.details.date"-->
                  <!--                  :v-model:time="task.details.time"-->
                  <SettingInput
                    v-if="!!task?.details"
                    :location="task.details.location"
                    @update:location="task.details.location = $event"
                    :date="task.details.date"
                    @update:date="task.details.date = $event"
                    :time="task.details.time"
                    @update:time="task.details.time = $event"
                    :omitLabels="true"
                  />
                </div>
              </div>
            </div>
            <div v-show="isEditing" class="flex-col min-w-[80vH] w-[80vH] max-w-[80vH]">
              <div class="text-left text-xs pb-1 pt-2">
                <label> Ausgangslage * : Der:die Student:in ... </label>
              </div>
              <div class="items-start text-start">
                <textarea
                  @click="
                    (event) => {
                      typeDropdown.close();
                      event.stopPropagation();
                    }
                  "
                  @keyup.space.prevent="(event) => event.stopPropagation()"
                  v-model="task.details.starting_point"
                  :disabled="!isEditing"
                  :placeholder="
                    'Was findet der gespielte ' +
                    studentRole +
                    ' zu Anfang vor? Was ist zuvor geschehen? Vervollständige den Satz.'
                  "
                  class="resize-none px-0 py-0.5 -mb-1 mr-0.5 text-xs sm:text-sm text-gray-800"
                  @input="
                    (event) => {
                      updateMinTextareaHeight(event.target, 2);
                      localDescription = event.target.value;
                    }
                  "
                  rows="2"
                  :class="{
                    'cursor-text border border-gray-200 rounded-lg bg-white': isEditing,
                    'border-transparent bg-transparent': !isEditing,
                    'border-red-500 focus:border-red-500 focus:ring-red-500':
                      (task.details.starting_point === '' ||
                        task.details.starting_point == null ||
                        startingpointErrors.length > 0) &&
                      isTouched,
                  }"
                  :style="{
                    width: '100%',
                    'box-sizing': 'border-box',
                  }"
                />
                <span class="text-xs text-red-600 mt-1">{{ startingpointErrors[0] }}</span>
                <span
                  v-if="
                    (task.details.starting_point === '' || task.details.starting_point == null) &&
                    startingpointErrors.length === 0 &&
                    isTouched
                  "
                  class="text-xs text-red-600"
                  >Dies ist ein Pflichtfeld</span
                >
              </div>
            </div>
          </div>
          <!-- End date + situation -->
        </div>
        <!-- End Col -->

        <!-- Profile images of selected persons + top-level buttons -->
        <div class="mt-0 min-w-fit w-fit max-w-fit">
          <div class="flex justify-end items-center gap-x-4">
            <div class="flex items-center gap-x-4">
              <div class="flex flex-row pl-4 -mr-2 -space-x-2">
                <div class="relative flex-shrink-0 items-center justify-center" v-for="(person, index) in persons">
                  <img
                    v-if="!!person.profile_image"
                    class="flex h-[38px] w-[38px] sm:w-[46px] sm:h-[46px] mx-auto rounded-full transition-all duration-500"
                    :class="{
                      'w-[38px] sm:w-[46px] opacity-100': selectedPersonIds.includes(person.create_id),
                      'w-0 sm:w-0 opacity-0': !selectedPersonIds.includes(person.create_id),
                    }"
                    :src="person.profile_image.image_urls.small"
                    alt="Image Description"
                  />
                  <span
                    v-else
                    class="material-symbols-outlined flex h-[38px] sm:h-[46px] mx-auto rounded-full bg-white text-gray-800 border border-gray-500 text-5xl items-center justify-center transition-all duration-500"
                    :class="{
                      'w-[38px] sm:w-[46px] opacity-100': selectedPersonIds.includes(person.create_id),
                      'w-0 sm:w-0 opacity-0': !selectedPersonIds.includes(person.create_id),
                    }"
                    :style="{ 'font-weight': '100' }"
                  >
                    person
                  </span>
                </div>
              </div>

              <!-- Buttons rights -->
              <div class="top-0 end-0 flex-col sticky flex justify-center">
                <!-- Delete button -->
                <button
                  class="z-10 flex items-center justify-items-center w-full font-semibold text-start text-gray-800 hover:text-gray-800 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-600 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  :aria-controls="accordionContentId"
                  @click="emitRemoveTask(task.index)"
                >
                  <span
                    class="material-symbols-outlined text-xl text-red-600 hover:text-red-800 block bg-white"
                    :class="{ 'group-hover:bg-gray-50': !isEditing }"
                  >
                    delete
                  </span>
                </button>
                <!-- End Delete button -->
                <!-- Accordion toggle -->
                <button
                  class="flex items-center justify-items-center w-full font-semibold text-start text-gray-800 hover:text-gray-800 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-600 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  :aria-controls="accordionContentId"
                  @click.capture="toggleEditTask"
                >
                  <span class="material-symbols-outlined text-xl" :class="{ hidden: isEditing }"> expand_more </span>
                  <span class="material-symbols-outlined text-xl" :class="{ hidden: !isEditing }"> expand_less </span>
                </button>
                <!-- End accordion toggle -->
              </div>
              <!-- End buttons rights -->
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End profile images of selected persons + top-level buttons -->
      </button>
      <!-- End row = task header -->

      <!-- Additional content = task details -->
      <div
        :id="accordionContentId"
        class="hs-accordion-content w-full overflow-hidden transition-[height] duration-500 flex min-w-full"
        :aria-labelledby="accordionId"
        @click="toggleEditTask"
      >
        <div class="flex-col divide-y">
          <div class="pt-4"></div>
          <div class="pt-2 pb-4 flex-col">
            <div class="text-xs text-gray-800 pb-1 pt-2">Erfolgskriterien *</div>
            <div class="flex flex-wrap">
              <div class="relative" v-for="(criterion, index) in task.subtasks">
                <div class="flex flex-col">
                  <div class="relative">
                    <textarea
                      v-model="task.subtasks[index].details['target']"
                      @click="
                        (event) => {
                          typeDropdown.close();
                          event.stopPropagation();
                        }
                      "
                      @keyup.space.prevent="(event) => event.stopPropagation()"
                      :disabled="!isEditing"
                      :placeholder="index === 0 ? 'z.B. `erfragt die Reiseanamnese´' : ''"
                      class="resize-none overflow-hidden align-top w-64 pl-0 pr-6 py-0.5 mr-2 mb-2 text-sm text-gray-800"
                      @input="
                        async (event) => {
                          await updateMinTextareaHeight(event.target);
                          localSubtaskTarget = event.target.value;
                          let result = await validateSubtaskTarget();
                          criterionErrors[index] = result.errors;
                        }
                      "
                      rows="2"
                      :class="{
                        'cursor-text border border-gray-200 rounded-lg bg-white': isEditing,
                        'border-transparent bg-transparent': !isEditing,
                        'border-red-500 focus:border-red-500 focus:ring-red-500': criterionErrors[index]?.length > 0,
                      }"
                    />
                    <div class="absolute right-0.5 bottom-0 flex items-center justify-center">
                      <span
                        class="material-symbols-outlined text-red-600 hover:text-red-800 text-xl mr-2 mb-2"
                        @click="
                          (event) => {
                            typeDropdown.close();
                            event.stopPropagation();
                            task.subtasks.splice(index, 1);
                          }
                        "
                      >
                        delete
                      </span>
                    </div>
                  </div>
                  <div v-if="criterionErrors[index]?.length > 0 && isTouched" class="text-xs text-red-600 -mt-2">
                    {{ criterionErrors[index][0] }}
                  </div>
                  <div
                    v-else-if="task.subtasks[index].details['target'] === ''"
                    class="text-xs -mt-1"
                    :class="{ 'text-red-600': isTouched, 'text-blue-600': !isTouched }"
                  >
                    Dies ist ein Pflichtfeld
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                class="py-0.5 px-1 rounded-md items-center gap-x-2 text-xs font-medium border border-blue-600 text-blue-600 hover:border-blue-700 hover:text-blue-700 bg-white disabled:opacity-50 disabled:pointer-events-none"
                :class="{
                  'border-blue-600 focus:border-blue-700 focus:ring-blue-700': task.subtasks?.length === 0,
                }"
                @click="
                  (event) => {
                    typeDropdown.close();
                    event.stopPropagation();
                    task.subtasks.push({
                      index: task.subtasks.length,
                      details: {
                        target: '',
                      },
                    });
                  }
                "
              >
                Kriterium hinzufügen
              </button>
            </div>
            <span
              v-show="task.subtasks?.length === 0"
              class="text-xs"
              :class="{ 'text-blue-600': !isTouched, 'text-red-600': isTouched }"
              >Füge mindestens ein Kriterium hinzu, das zu erreichen ist</span
            >
          </div>
          <div class="pt-2 flex-col">
            <div class="text-xs text-gray-800 pb-1 pt-2">Beteiligte Personen markieren *</div>
            <div class="col-span-11 w-full inline-flex overflow-x-auto overflow-y-hidden gap-x-2" ref="scrollContainer">
              <!--        Person carousel (no automate spin)-->
              <PersonCard
                :ref="personCardRefs[index]"
                v-for="(person, index) in persons"
                :key="person.create_id"
                v-model="persons[index]"
                :isSelected="isSelected(person.create_id)"
                @toggleSelect="toggleSelectPerson"
                @editedPersonalConcern="setPersonalConcern(person.create_id, $event)"
                @editedPersonTaskInvolvement="setPersonTaskInvolvement(person.create_id, $event)"
                :initialPersonTaskConcern="
                  task.person_task_concerns.find((concern) => concern.create_id === person.create_id)
                "
                :initialPersonTaskInvolvement="
                  task.person_task_involvements.find((involvement) => involvement.create_id === person.create_id)
                "
                :allowSelect="true"
                :allowEdit="false"
                :allowRemove="false"
                :requireCompleteness="false"
                :startingFromCompleteCase="startingFromCompleteCase"
                :validateEdits="false"
                :validatePersonalConcern="true"
                @click="typeDropdown.close()"
                @centerMe="centerPerson($event.target)"
              />
            </div>
            <span
              v-show="selectedPersonIds?.length === 0"
              class="text-xs"
              :class="{ 'text-blue-600': !isTouched, 'text-red-600': isTouched }"
              >Füge mindestens einen Beteiligten hinzu</span
            >
          </div>
        </div>
      </div>
      <!-- End additional content -->
    </div>
  </div>
</template>

<style scoped>
.disabled {
  pointer-events: none;
}
</style>
