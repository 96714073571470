<script setup lang="ts">
const chatEnabled = defineModel('chatEnabled', {
  type: Boolean,
  required: true,
});
const subtitlesEnabled = defineModel('subtitlesEnabled', {
  type: Boolean,
  required: true,
});
</script>

<template>
  <div
    class="flex-col hidden mt-1 px-1 md:block justify-center items-center gap-2 py-1 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
  >
    <div class="flex justify-center lg:justify-end items-center pb-1 gap-x-1">
      <div class="relative inline-block">
        <input
          v-model="chatEnabled"
          type="checkbox"
          id="hs-large-switch-chat"
          class="peer relative w-[3.25rem] h-7 p-px bg-gray-100 border border-gray-200 text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-0 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-gray-500 checked:border-gray-200 focus:checked:border-gray-200 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-gray-800/30 dark:checked:border-gray-800 dark:focus:ring-offset-gray-800 before:inline-block before:h-6 before:w-6 before:bg-white checked:before:bg-gray-800 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-gray-500"
        />
        <label for="hs-default-switch-soft-with-icons" class="sr-only">switch</label>
        <span
          class="hidden lg:flex peer-checked:text-gray-800 text-gray-800 w-6 h-6 absolute top-0.5 start-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <span class="material-symbols-outlined"> keyboard </span>
        </span>
        <span
          class="flex peer-checked:text-white text-gray-800 w-6 h-6 absolute top-0.5 start-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <span class="material-symbols-outlined"> keyboard </span>
        </span>
        <span
          class="hidden peer-checked:flex peer-checked:text-white w-6 h-6 absolute top-0.5 end-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <svg
            class="flex-shrink-0 h-3 w-3"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </span>
      </div>
    </div>

    <div class="flex justify-center lg:justify-end items-center gap-x-1">
      <div class="relative inline-block">
        <input
          v-model="subtitlesEnabled"
          type="checkbox"
          id="hs-large-switch-subtitles"
          class="peer relative w-[3.25rem] h-7 p-px bg-gray-100 border border-gray-200 text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-0 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-gray-500 checked:border-gray-200 focus:checked:border-gray-200 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-gray-800/30 dark:checked:border-gray-800 dark:focus:ring-offset-gray-800 before:inline-block before:h-6 before:w-6 before:bg-white checked:before:bg-gray-800 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-gray-500"
        />
        <label for="hs-default-switch-soft-with-icons" class="sr-only">switch</label>
        <span
          class="hidden lg:flex peer-checked:text-gray-800 text-gray-800 w-6 h-6 absolute top-0.5 start-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <span class="material-symbols-outlined"> closed_caption </span>
        </span>
        <span
          class="flex peer-checked:text-white text-gray-800 w-6 h-6 absolute top-0.5 start-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <span class="material-symbols-outlined"> closed_caption </span>
        </span>
        <span
          class="hidden peer-checked:flex peer-checked:text-white w-6 h-6 absolute top-0.5 end-0.5 justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500"
        >
          <svg
            class="flex-shrink-0 h-3 w-3"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
