<script setup lang="ts">
import Tooltip from '@/components/Tooltip.vue';
import { onMounted, Ref, ref, watch, computed, reactive, nextTick, toRef } from 'vue';
import { Disease, DiseaseInputForm, DiseaseInputProps, PersonDetailsForm } from '@/components/new_case/typing';
import { updateMinTextareaHeight } from '@/helper';
import { ErrorMessage, Field, useField, useFieldArray, useForm } from 'vee-validate';
import objectHash from 'object-hash';

const diseases = defineModel({
  type: Array as () => Array<Disease>,
  required: false,
});

const emit = defineEmits(['click', 'isValid']);

const { value: localName, validate: validateName } = useField('name', 'max:50|required', {
  initialValue: '',
}); // this is for validity check
const localNames = ref([] as string[]); // this is to reset the modelled values if validity check fails
const { value: localOnset, validate: validateOnset } = useField('onset', 'required', {
  initialValue: '',
});
const localOnsets = ref([] as string[]);
const { value: localStage, validate: validateStage } = useField('stage', 'max:50', {
  initialValue: '',
});
const localStages = ref([] as string[]);
const { value: localRemark, validate: validateRemark } = useField('remark', 'max:500', {
  initialValue: '',
});
const localRemarks = ref([] as string[]);

const remarkTextareaRefs = ref<Array<any>>([]);

const nameErrors = ref([]);
const onsetErrors = ref([]);
const stageErrors = ref([]);
const remarkErrors = ref([]);

const props = defineProps({
  omitLabels: {
    type: Boolean,
    default: false,
  },
  minNumberOfDiseases: {
    type: Number,
    default: 0,
  },
});

onMounted(async () => {
  if (diseases.value === undefined) {
    return;
  }
  await nextTick();
  await resetIfEmpty();
});

const addEntry = async () => {
  // Add a new entry to the array
  let tempLocalName = '';
  let tempLocalOnset = '';
  diseases.value.push({ name: tempLocalName, onset: tempLocalOnset, stage: '', remark: '' });
  // localName.value = tempLocalName;
  // localOnset.value = tempLocalOnset;
  // nameErrors.value.push((await validateName()).errors);
  // onsetErrors.value.push((await validateOnset()).errors);
  // stageErrors.value.push([]);
  // remarkErrors.value.push([]);
  // localNames.value.push(tempLocalName);
  // localOnsets.value.push(tempLocalOnset);
  // localStages.value.push('');
  // localRemarks.value.push('');
};

const removeEntry = (index: number) => {
  diseases.value.splice(index, 1);
  nameErrors.value.splice(index, 1);
  onsetErrors.value.splice(index, 1);
  stageErrors.value.splice(index, 1);
  remarkErrors.value.splice(index, 1);
  localNames.value.splice(index, 1);
  localOnsets.value.splice(index, 1);
  localStages.value.splice(index, 1);
  localRemarks.value.splice(index, 1);
};

const resetIfEmpty = async () => {
  if (diseases.value?.length === 0) {
    await resetAll();
  }
};

const resetAll = async () => {
  if (!!diseases.value) {
    diseases.value.length = 0;
  }
  nameErrors.value.length = 0;
  onsetErrors.value.length = 0;
  stageErrors.value.length = 0;
  remarkErrors.value.length = 0;
  localNames.value.length = 0;
  localOnsets.value.length = 0;
  localStages.value.length = 0;
  localRemarks.value.length = 0;
  for (let i = 0; i < props.minNumberOfDiseases; i++) {
    await addEntry();
  }
};

async function initIfEmpty() {
  console.log('initIfEmpty');
  if (diseases.value?.length === 0) {
    await resetAll();
  }
}

watch(
  () => nameErrors.value,
  (value) => {
    console.log('nameErrors updated to: ', value);
  },
);

const isValid = computed(() => {
  if (diseases.value === undefined && props.minNumberOfDiseases > 0) {
    return false;
  }
  if (diseases.value === undefined && props.minNumberOfDiseases === 0) {
    return true;
  }
  let anyOsetMissing = diseases.value.some((disease) => disease.name.length === 0);
  let anyNameMissing = diseases.value.some((disease) => disease.onset.length === 0);
  let isInvalid =
    nameErrors.value.some((errors) => errors.length > 0) ||
    onsetErrors.value.some((errors) => errors.length > 0) ||
    stageErrors.value.some((errors) => errors.length > 0) ||
    remarkErrors.value.some((errors) => errors.length > 0) ||
    anyOsetMissing ||
    anyNameMissing;
  // console.log('nameErrors', nameErrors.value);
  // console.log('onsetErrors', onsetErrors.value);
  // console.log('stageErrors', stageErrors.value);
  // console.log('remarkErrors', remarkErrors.value);
  // console.log(nameErrors.value.some((errors) => errors.length > 0));
  // console.log(onsetErrors.value.some((errors) => errors.length > 0));
  // console.log(stageErrors.value.some((errors) => errors.length > 0));
  // console.log(remarkErrors.value.some((errors) => errors.length > 0));
  // console.log(anyOsetMissing);
  // console.log(anyNameMissing);
  // console.log('DiseaseInput: isValid', !isInvalid);
  // emit('isValid', !isInvalid);
  // console.log('emittted');
  return !isInvalid;
});

watch(
  () => isValid.value,
  (value) => {
    emit('isValid', value);
  },
);

const diseasesArrayHashed = computed(() => {
  return objectHash(diseases.value ? diseases.value : []);
});

const setRemarkTextareaRef = (el, index) => {
  remarkTextareaRefs.value[index] = el;
};

watch(
  () => diseasesArrayHashed.value,
  async (value, oldValue) => {
    // this is called if entries are added programatically outside this cpt, i.e. thru generation by description
    // console.log('diseasesArrayHashed.value: ', value, 'oldValue: ', oldValue);
    if (!diseases.value) {
      return;
    }
    for (let i = remarkTextareaRefs.value.length; i < diseases.value.length; i++) {
      remarkTextareaRefs.value.push(ref(null));
    }
    for (let i = 0; i < diseases.value.length; i++) {
      localName.value = diseases.value[i].name;
      localOnset.value = diseases.value[i].onset;
      localStage.value = diseases.value[i].stage;
      localRemark.value = diseases.value[i].remark;
      try {
        nameErrors.value[i] = (await validateName()).errors;
        onsetErrors.value[i] = (await validateOnset()).errors;
        stageErrors.value[i] = (await validateStage()).errors;
        remarkErrors.value[i] = (await validateRemark()).errors;
      } catch (e) {
        nameErrors.value.push((await validateName()).errors);
        onsetErrors.value.push((await validateOnset()).errors);
        stageErrors.value.push((await validateStage()).errors);
        remarkErrors.value.push((await validateRemark()).errors);
      }
      try {
        localNames.value[i] = diseases.value[i].name;
        localOnsets.value[i] = diseases.value[i].onset;
        localStages.value[i] = diseases.value[i].stage;
        localRemarks.value[i] = diseases.value[i].remark;
      } catch (e) {
        localNames.value.push(diseases.value[i].name);
        localOnsets.value.push(diseases.value[i].onset);
        localStages.value.push(diseases.value[i].stage);
        localRemarks.value.push(diseases.value[i].remark);
      }

      await nextTick();
      // console.log('remarkTextareaRefs.value[i]', remarkTextareaRefs.value[i]);
      await updateMinTextareaHeight(remarkTextareaRefs.value[i]);
    }
    // console.log('diseases.value', diseases.value);
  },
  { deep: true, immediate: true },
);

defineExpose({
  initIfEmpty,
});
</script>

<template>
  <div v-for="(disease, index) in diseases" :key="index" class="pb-1 overflow-visible">
    <div class="grid grid-cols-12 gap-2 overflow-visible">
      <!-- Name (text) / Stage (text, optional) / Symptom onset (select) / Disease onset (select, optional -- currently commented out!) -->
      <div class="col-span-6 grid grid-cols-12 gap-2">
        <!-- Disease name -->
        <div class="col-span-7">
          <div v-show="!props.omitLabels && index === 0">
            <label for="name" class="block text-sm mb-2 text-gray-500 dark:text-gray-200"> Krankheitsname* </label>
          </div>
          <div>
            <!--           {{ nameErrors }}-->
            <input
              v-model="diseases[index].name"
              class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
              placeholder=""
              @click="
                (event) => {
                  event.stopPropagation();
                  emit('click');
                }
              "
              @input="
                async (event) => {
                  localName = event.target.value;
                  let result = await validateName();
                  console.log('result', result);
                  nameErrors[index] = result.errors;
                }
              "
              @change="
                async (event) => {
                  if (nameErrors[index]?.length === 0) {
                    localNames[index] = event.target.value;
                  } else {
                    diseases[index].name = localNames[index];
                  }
                }
              "
            />
            <span v-if="nameErrors[index]?.length > 0" class="text-xs text-red-600">{{ nameErrors[index][0] }}</span>
            <span v-else-if="diseases[index].name.length === 0" class="text-xs text-red-600"
              >Dies ist ein Pflichtfeld</span
            >
          </div>
        </div>

        <!-- Symptom onset -->
        <div class="col-span-5">
          <div v-show="!props.omitLabels && index === 0">
            <label for="hs-select-symptom-onset" class="block text-sm mb-2 text-gray-500 dark:text-gray-200">
              Symptombeginn*
            </label>
          </div>
          <div>
            <select
              class="py-2 px-3 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
              v-model="diseases[index].onset"
              @click="
                (event) => {
                  event.stopPropagation();
                  emit('click');
                }
              "
              @change="
                async (event) => {
                  localOnset = event.target.value;
                  let result = await validateOnset();
                  onsetErrors[index] = result.errors;
                }
              "
            >
              <!-- <option selected></option> -->
              <option value="NO_MANIFEST_SYMPTOMS">Keine manifesten Symptome</option>
              <option value="PERACUTE">Perakut</option>
              <option value="ACUTE">Akut</option>
              <option value="SUBACUTE">Subakut</option>
              <option value="CHRONIC">Chronisch</option>
              <option value="MINUTES">Minuten</option>
              <option value="HOURS">Stunden</option>
              <option value="WEEKS">Wochen</option>
              <option value="MONTHS">Monate</option>
              <option value="YEARS">Jahre</option>
              <option value="DECADES">Jahrzehnte</option>
            </select>
            <span v-if="onsetErrors[index]?.length > 0" class="text-xs text-red-600">{{ onsetErrors[index][0] }}</span>
            <span v-else-if="diseases[index].onset.length === 0" class="text-xs text-red-600"
              >Dies ist ein Pflichtfeld</span
            >
          </div>
        </div>
      </div>

      <div class="col-span-6 grid grid-cols-12 gap-2">
        <!-- Disease stage  -->
        <div class="col-span-4">
          <div v-show="!props.omitLabels && index === 0">
            <label for="stage" class="block text-sm mb-2 text-gray-500 dark:text-gray-200">
              Stadium
              <Tooltip
                :message="`Stadium oder Ausbreitung frei formuliert und mit gewünschter Detailtiefel, z.B. 'lokal fortgeschritten' oder 'hepatisch metastasiert' oder auch 'T4 N2 M1a'`"
              />
            </label>
          </div>
          <div>
            <input
              class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
              placeholder=""
              v-model="diseases[index].stage"
              @click="
                (event) => {
                  event.stopPropagation();
                  emit('click');
                }
              "
              @input="
                async (event) => {
                  localStage = event.target.value;
                  let result = await validateStage();
                  stageErrors[index] = result.errors;
                }
              "
              @change="
                async (event) => {
                  if (stageErrors[index]?.length === 0) {
                    localStages[index] = event.target.value;
                  } else {
                    diseases[index].stage = localStages[index];
                  }
                }
              "
            />
            <span v-if="stageErrors[index]?.length > 0" class="text-xs text-red-600">{{ stageErrors[index][0] }}</span>
          </div>
        </div>

        <!-- Comment  -->
        <div class="col-span-8">
          <div v-show="!omitLabels && index === 0">
            <label for="comment" class="block text-sm mb-2 text-gray-500 dark:text-gray-200">
              Bemerkung
              <Tooltip
                :message="`Weitere Anmerkungen, z.B. zur Lokalisation oder Ätiologie: 'nach Nikotinabusus 60 Py' oder 'auf dem Boden der NASH-Zirrhose' (bei Vorerkrankungen angeben)`"
              />
            </label>
          </div>
          <div>
            <div class="flex items-center justify-evenly">
              <div class="grow">
                <textarea
                  :ref="(el) => setRemarkTextareaRef(el, index)"
                  id="remark"
                  class="py-2 px-3 block resize-none w-full border-gray-200 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                  placeholder=""
                  v-model="diseases[index].remark"
                  :rows="1"
                  @click="
                    (event) => {
                      event.stopPropagation();
                      emit('click');
                    }
                  "
                  @input="
                    async (event) => {
                      await updateMinTextareaHeight(event.target);
                      localRemark = event.target.value;
                      let result = await validateRemark();
                      remarkErrors[index] = result.errors;
                    }
                  "
                  @change="
                    async (event) => {
                      if (remarkErrors[index]?.length === 0) {
                        localRemarks[index] = event.target.value;
                      } else {
                        diseases[index].remark = localRemarks[index];
                      }
                    }
                  "
                />
                <span v-if="remarkErrors[index]?.length > 0" class="text-xs text-red-600">{{
                  remarkErrors[index][0]
                }}</span>
              </div>
              <div class="none">
                <button
                  type="button"
                  id="delete-button"
                  @click="
                    (event) => {
                      event.stopPropagation();
                      emit('click');
                      removeEntry(index);
                    }
                  "
                  v-show="diseases.length > 1"
                  class="inline-flex items-center gap-x-1 text-xs font-medium rounded-full disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  <span class="material-symbols-outlined text-xl text-red-600 hover:text-red-800 block"> delete </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <!-- Add another disease / reset all -->
    <p
      class="col-span-12 items-center"
      :class="{
        'mt-0 mb-3 text-end justify-end': !!diseases && diseases.length > 0,
        'mt-1.5 mb-3 text-start justify-start': !!diseases && diseases.length == 0,
      }"
    >
      <button
        v-show="!!diseases && diseases.length > 0"
        type="button"
        id="delete-button"
        @click="
          async (event) => {
            event.stopPropagation();
            emit('click');
            await resetAll();
          }
        "
        class="group/reset px-4 text-gray-500 hover:text-gray-400 inline-flex items-center gap-x-1 text-xs font-medium rounded-full disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      >
        <span class="material-symbols-outlined text-xl text-red-600 group-hover/reset:text-red-800 block">
          delete
        </span>
        Alle zurücksetzen
      </button>
      <button
        type="button"
        id="add-button"
        @click="
          (event) => {
            event.stopPropagation();
            emit('click');
            addEntry();
          }
        "
        class="group/reset pr-4 text-gray-500 hover:text-gray-400 inline-flex items-center gap-x-1 text-xs font-medium rounded-full disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      >
        <span class="material-symbols-outlined text-xl text-green-500 group-hover/reset:text-green-600 block">
          bug_report
        </span>
        {{ !!diseases && diseases.length > 0 ? 'Weitere hinzufügen' : 'Hinzufügen' }}
      </button>
    </p>
    <!-- End of: Add another disease / reset all -->
  </div>
</template>

<style></style>
