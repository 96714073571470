<!--TODO
TODO v1: table w/ all lab results (and normal range). If new parameter requested, append.
TODO v2: Allow lab test at different dates, e.g. if therapy (also: "wait for xxxx") is established. Same as v1 but ordered by date.
-->

<script setup>
import { nextTick, onMounted, ref, watch } from 'vue';
import Translation from '@/components/interaction_columns/Translation.vue';
import SystemMessageFooter from '@/components/SystemMessageFooter.vue';
import { storeToRefs } from 'pinia';
import { usePatientInteractionStore } from '@/stores';
import { HSAccordion, HSStaticMethods } from 'preline';

const patientInteractionStore = usePatientInteractionStore();

const { labSheetData, id } = defineProps(['labSheetData', 'id']);
const { patientName, patientDob, labIsStreaming, labRemainingStreaming } = storeToRefs(patientInteractionStore);

const accordion = ref(null);
const showFull = ref(false);
const bgColor = ref('bg-white');

onMounted(async () => {
  await nextTick();
  HSStaticMethods.autoInit();
  await nextTick();

  // wait 220 ms
  await new Promise((resolve) => setTimeout(resolve, 220));
  accordion.value = new HSAccordion(document.getElementById(`${id}-hs-basic-with-arrow-heading-one`));

  // console.log('accordion: ', accordion.value);
  // console.log('accordion 0: ', accordion.value[0]);
  // console.log('accordion target: ', accordion.value.target);
  // console.log('accordion el: ', accordion.value.el);

  console.log('labSheetData is: ' + labSheetData + '.');

  showFull.value = true;
  // await accordion.value.hide();
});

async function collapseAccordion() {
  console.log('collapsing');
  if (!showFull.value) {
    return;
  }
  showFull.value = false;
  await accordion.value.hide();
}

async function handleToggled() {
  console.log('showFull before toggle:' + showFull.value);
  showFull.value = !showFull.value;
  if (showFull.value) {
    await accordion.value.show();
  } else {
    await accordion.value.hide();
  }
}

watch(labIsStreaming, async (newValue) => {
  if (newValue) {
    if (showFull.value) {
      return;
    }
    await accordion.value.show();
    showFull.value = true;
  }
});

watch(showFull, (newValue) => {
  if (newValue) {
    bgColor.value = 'bg-white';
    return;
  }
  bgColor.value = 'bg-white';
});
</script>

<template>
  <div
    class="mb-1.5 text-sm hs-accordion-active:bg-white border border-gray-200 rounded-lg p-4 dark:bg-neutral-900 dark:border-gray-700"
    :class="bgColor"
  >
    <div class="hs-accordion-group">
      <div class="hs-accordion active" :id="`${id}-hs-basic-with-arrow-heading-one`">
        <button
          class="hs-accordion-toggle py-3 inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-600 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
          @mouseup.capture="handleToggled"
        >
          <span class="material-symbols-outlined text-xl block hs-accordion-active:hidden"> expand_more </span>
          <span class="material-symbols-outlined text-xl hidden hs-accordion-active:block"> expand_less </span>
          <div>Laborbericht von {{ patientName }} (*{{ patientDob }})</div>
        </button>
        <div
          :id="`${id}-hs-basic-with-arrow-collapse-one`"
          class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
        >
          <div class="text-gray-800 dark:text-neutral-200 overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead class="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th scope="col" class="py-3 text-left text-xs font-medium text-gray-500 uppercase dark:text-gray-400">
                    Name
                  </th>
                  <th scope="col" class="py-3 text-left text-xs font-medium text-gray-500 uppercase dark:text-gray-400">
                    Wert
                  </th>
                  <th scope="col" class="py-3 text-left text-xs font-medium text-gray-500 uppercase dark:text-gray-400">
                    Referenz
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                <tr v-for="item in labSheetData">
                  <td class="py-2 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                    {{ item.display_name }}
                  </td>
                  <td class="py-2 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                    {{ item.value }}
                  </td>
                  <td class="py-2 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                    {{ item.range_lower }} - {{ item.range_upper }} {{ item.unit }}
                  </td>
                </tr>
                <!-- add loading skeleton if is streaming-->
                <tr
                  v-if="labIsStreaming && labRemainingStreaming >= 0"
                  v-for="remaining in labRemainingStreaming"
                  :key="remaining"
                  class="animate-pulse"
                >
                  <td class="py-2 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                    <div class="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></div>
                  </td>
                  <td class="py-2 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                    <div class="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></div>
                  </td>
                  <td class="py-2 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                    <div class="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></div>
                  </td>
                </tr>

                <!-- if labRemainingStreaming is negative, just show one leading indicator. This is used if the exact number of lab params is not known -->
                <tr v-else-if="labIsStreaming && labRemainingStreaming < 0" class="animate-pulse">
                  <td class="py-2 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                    <div class="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></div>
                  </td>
                  <td class="py-2 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                    <div class="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></div>
                  </td>
                  <td class="py-2 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                    <div class="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Button Group -->
  <SystemMessageFooter
    :message="labSheetData"
    messageType="LAB"
    conversation="PATIENT"
    :hideFooter="labIsStreaming || !showFull"
    :showCollapse="true"
    @onCollapse="collapseAccordion"
  />
  <!-- End Button Group -->
</template>

<style scoped></style>
