<script setup lang="ts">
import { useAuthStore, useCaseInteractionStore, usePatientInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, ref } from 'vue';
import ProfileImage from '@/components/ProfileImage.vue';
import ClinicalNotesColumn from '@/views/clinical-information-system/ClinicalNotesColumn.vue';
import ActionColumn from '@/components/interaction_columns/ActionColumn.vue';
import UserInput from '@/views/clinical-information-system/UserInput.vue';
import { HSStaticMethods } from 'preline';
import PatientReportForm from '@/components/PatientReportForm.vue';
import SolveModal from '@/components/SolveModal.vue';

const authStore = useAuthStore();
const patientInteractionStore = usePatientInteractionStore();
const caseInteractionStore = useCaseInteractionStore();
const { user } = storeToRefs(authStore);
const emits = defineEmits(['logout', 'trySolve']);
const sidebar = ref(null);

const userDisplayName = computed(() => {
  if (!user.value) {
    return 'Unbekannter Nutzer';
  }
  return (!!user.value.title ? user.value.title + ' ' : '') + user.value.first_name + ' ' + user.value.last_name;
});

const showingClinicalNotes = ref(false);
const showingLab = ref(false);
const showingExaminations = ref(false);
const showingSidebarEvenIfSmall = ref(false);
const showingPatientReportForm = ref(false);

// onMounted(async () => {
//   await nextTick();
//   await HSStaticMethods.autoInit();
//   await nextTick();
// })

function showHome() {
  showingClinicalNotes.value = false;
  showingLab.value = false;
  showingExaminations.value = false;
  showingPatientReportForm.value = false;
}

function showClinicalNots() {
  showingClinicalNotes.value = true;
  showingLab.value = false;
  showingExaminations.value = false;
  showingPatientReportForm.value = false;
}

function showLab() {
  showingClinicalNotes.value = false;
  showingLab.value = true;
  showingExaminations.value = false;
  showingPatientReportForm.value = false;
}

function showExaminations() {
  showingClinicalNotes.value = false;
  showingLab.value = false;
  showingExaminations.value = true;
  showingPatientReportForm.value = false;
}

function showPhysicianLetter() {
  showingClinicalNotes.value = false;
  showingLab.value = false;
  showingExaminations.value = false;
  showingPatientReportForm.value = true;
}

async function handleLabSubmit(inputText: string) {
  console.debug('Lab request: ' + inputText);
  let content = inputText.trim();
  let parameters = content.split(';');
  await patientInteractionStore.getLabParameters(parameters, false);
}

async function handleExaminationSubmit(inputText: string) {
  console.debug('Examination request: ' + inputText);
  await patientInteractionStore.doExamination(inputText);
}

async function handeLogout() {
  await nextTick();
  await new Promise((r) => setTimeout(r, 200)); // this is needed to first close dropdown, then desktop

  emits('logout');
}

async function trySolve() {
  emits('trySolve');
}

function toggleNavigation() {
  showingSidebarEvenIfSmall.value = !showingSidebarEvenIfSmall.value;
}
</script>

<template>
  <!-- ========== HEADER ========== -->
  <div class="lg:hidden flex flex-wrap md:justify-start md:flex-nowrap min-w-full w-full max-w-full">
    <div class="max-w-4xl mx-auto flex justify-between basis-full items-center w-full" aria-label="Global">
      <!-- Logo & Sidebar Toggle -->
      <div
        class="flex items-center gap-x-3 w-[260px] translate-x-0 end-auto lg:hidden"
        :class="{ 'bg-gray-50': showingSidebarEvenIfSmall }"
      >
        <div class="lg:hidden">
          <!-- Sidebar Toggle -->
          <button
            type="button"
            class="mx-2 my-2 h-[38px] inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            @click="toggleNavigation"
          >
            <span class="material-symbols-outlined"> view_sidebar </span>
            <span v-show="!showingSidebarEvenIfSmall" class="material-symbols-outlined"> chevron_right </span>
            <span v-show="showingSidebarEvenIfSmall" class="material-symbols-outlined"> chevron_left </span>
          </button>
          <!-- End Sidebar Toggle -->
        </div>
      </div>
      <!-- End Logo & Sidebar Toggle -->
    </div>
  </div>
  <!-- ========== END HEADER ========== -->

  <div class="inline-flex h-full min-h-full max-h-full min-w-full w-full max-w-full">
    <!-- ========== MAIN SIDEBAR ========== -->
    <div
      ref="sidebar"
      class="-translate-x-full transition-all duration-300 transform w-[260px] bg-gray-50 dark:bg-neutral-800 h-full min-h-full max-h-full"
      :class="{
        'hidden lg:block lg:translate-x-0 lg:end-auto lg:bottom-0': !showingSidebarEvenIfSmall,
        'block translate-x-0 end-auto bottom-0': showingSidebarEvenIfSmall,
      }"
    >
      <div class="flex flex-col min-h-full h-full max-h-full">
        <!-- Sidebar Header -->
        <div class="p-5 text-center">
          <ProfileImage :image="authStore.userProfileImageSmall" initials="" size="7rem" :showIngameLevel="true" />

          <div class="">
            <!-- More Dropdown -->
            <div class="hs-dropdown relative inline-flex">
              <button
                id="hs-pro-dnsmd"
                type="button"
                class="py-1 px-2 inline-flex justify-center items-center gap-x-1 font-semibold rounded-lg text-gray-800 hover:text-purple-600 focus:outline-none focus:text-purple-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 dark:hover:text-purple-500 dark:focus:text-purple-500"
              >
                {{ userDisplayName }}
                <svg
                  class="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </button>

              <!-- Account Dropdown -->
              <div
                class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-60 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-800"
                aria-labelledby="hs-pro-dnsmd"
              >
                <div class="p-1">
                  <a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                    @mouseup.stop="handeLogout"
                  >
                    <svg
                      class="flex-shrink-0 size-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1="21" x2="9" y1="12" y2="12" />
                    </svg>
                    Abmelden
                  </a>
                </div>
              </div>
              <!-- End Account Dropdown -->
            </div>
            <!-- End More Dropdown -->
          </div>

          <p class="text-xs text-gray-500 dark:text-neutral-500">Mitarbeiter-ID M2389318879</p>
        </div>
        <!-- End Sidebar Header -->

        <!-- Sidebar Content -->
        <div
          class="h-full overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
        >
          <!-- Nav -->
          <nav class="hs-accordion-group pt-0 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
            <ul class="p-4 border-t border-gray-200 space-y-0.5">
              <!-- Link -->
              <li class="hs-accordion active" id="projects-accordion">
                <button
                  type="button"
                  class="hs-accordion-toggle py-1 px-3 flex justify-center items-center gap-x-1 text-xs text-gray-500 rounded-lg hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-200 dark:hover:bg-neutral-700 dark:text-neutral-500 dark:focus:bg-neutral-700"
                >
                  <svg
                    class="hs-accordion-active:rotate-90 flex-shrink-0 size-3 transition"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                  Meine Patienten
                </button>

                <div
                  id="projects-accordion-sub"
                  class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                >
                  <ul class="hs-accordion-group mt-1 space-y-1.5" data-hs-accordion-always-open>
                    <!-- Link -->
                    <li>
                      <a
                        class="flex gap-x-3 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                        href="#"
                      >
                        <ProfileImage
                          v-if="!!patientInteractionStore.currentPatient"
                          :image="patientInteractionStore.patientProfileImageSmall"
                          :initials="patientInteractionStore.patientInitials"
                          size="1.5rem"
                        />
                        <div class="flex-col">
                          <div>
                            {{
                              !!patientInteractionStore.patientName
                                ? patientInteractionStore.patientName
                                : 'Kein Patient'
                            }}
                          </div>
                          <div>
                            {{ !!patientInteractionStore.patientAge ? patientInteractionStore.patientAge + 'j' : '' }}
                            {{
                              !!patientInteractionStore.patientSexSymbol ? patientInteractionStore.patientSexSymbol : ''
                            }}
                          </div>
                        </div>
                      </a>
                    </li>
                    <!-- End Link -->
                  </ul>
                </div>
              </li>
              <!-- End Link -->
            </ul>

            <ul class="p-4 space-y-1.5 border-y border-gray-200 dark:border-neutral-700">
              <!-- Link -->
              <li>
                <button
                  class="flex items-center gap-x-3 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                  @click.stop="showHome"
                >
                  <span class="material-symbols-outlined">home</span>
                  Home
                </button>
              </li>
              <!-- End Link -->

              <!-- Link -->
              <li>
                <button
                  class="flex items-center gap-x-3 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                  @click.prevent="showClinicalNots"
                >
                  <span class="material-symbols-outlined">summarize</span>
                  Klinische Notizen
                </button>
              </li>
              <!-- End Link -->

              <!-- Link -->
              <li>
                <button
                  class="flex items-center gap-x-3 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                  @click.prevent="showLab"
                >
                  <span class="material-symbols-outlined">lab_research</span>
                  Labor
                </button>
              </li>
              <!-- End Link -->

              <!-- Link -->
              <li>
                <button
                  class="flex items-center gap-x-3 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                  @click.prevent="showExaminations"
                >
                  <span class="material-symbols-outlined">radiology</span>
                  Untersuchungen
                </button>
              </li>
              <!-- End Link -->
            </ul>
            <ul class="p-4 space-y-1.5 dark:border-neutral-700">
              <!-- Link -->
              <li>
                <button
                  class="flex items-center gap-x-3 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                  @click.prevent="showPhysicianLetter"
                >
                  <span class="material-symbols-outlined">clinical_notes</span>
                  Anamnesebogen
                </button>
              </li>
              <!-- End Link -->
              <!-- Link -->
              <li>
                <button
                  class="flex items-center gap-x-3 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                  @click.prevent="trySolve"
                >
                  <span class="material-symbols-outlined">lightbulb</span>
                  Verdachtsdiagnose
                </button>
              </li>
              <!-- End Link -->
            </ul>
          </nav>
          <!-- End Nav -->
        </div>
        <!-- End Sidevbar Content -->
      </div>

      <div class="flex-grow mt-auto"></div>

      <!-- Sidebar Footer -->
      <footer class="hidden lg:block py-2 px-5 border-t bg-gray-50 border-gray-200 dark:border-neutral-700">
        <div class="h-8"></div>
      </footer>
      <!-- End Sidebar Footer -->
    </div>
    <!-- ========== END MAIN SIDEBAR ========== -->

    <!-- ========== MAIN CONTENT ========== -->
    <div id="content" class="w-full">
      <!-- Content -->
      <div v-if="showingClinicalNotes" class="flex-col flex min-w-full w-full mx-auto">
        <ClinicalNotesColumn />
      </div>
      <div v-if="showingLab" class="flex-col flex min-w-full w-ful lmx-auto">
        <ActionColumn id="kisLabColumn" :showLab="true" :showExaminations="false" :showDesc="false" />
        <UserInput icon="lab_research" placeholderMessage="Laborwerte anfordern" @onSubmit="handleLabSubmit" />
      </div>
      <div v-if="showingExaminations" class="flex-col flex min-w-full w-full mx-auto">
        <ActionColumn id="kisExmColumn" :showLab="false" :showExaminations="true" :showDesc="false" />
        <UserInput icon="radiology" placeholderMessage="Untersuchung anfordern" @onSubmit="handleExaminationSubmit" />
      </div>
      <div v-if="showingPatientReportForm" class="flex-col flex min-w-full w-fullmx-auto">
        <PatientReportForm />
      </div>
    </div>
    <!-- End Content -->
  </div>
  <!-- ========== END MAIN CONTENT ========== -->
</template>

<style scoped></style>
