<script setup lang="ts">
import { useAuthStore } from '@/stores';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { getLevelFromXp, getThresholdForCurrentLevel, getThresholdForNextLevel } from '@/helper';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { HSStaticMethods, HSTooltip } from 'preline';
import { nextTick } from 'vue';

const { t, locale } = useI18n();

const authStore = useAuthStore();
const { userXp } = storeToRefs(authStore);

const progressTooltip = ref(null);
const progressBar = ref(null);

const currentLevel = computed(() => getLevelFromXp(userXp.value));
const progress = computed(
  () =>
    ((userXp.value - getThresholdForCurrentLevel(currentLevel.value)) /
      (getThresholdForNextLevel(currentLevel.value) - getThresholdForCurrentLevel(currentLevel.value))) *
    100,
);

onMounted(async () => {
  await nextTick();
  HSStaticMethods.autoInit();
  await nextTick();

  progressTooltip.value = new HSTooltip(document.getElementById('progress-tooltip'));
  if (!progressBar.value) {
    console.warn('Cannot find progressBar in IngameProgressCard. Failed to add EventListener');
    return;
  }
  progressBar.value.addEventListener('mouseenter', () => {
    progressTooltip.value.show();
  });
  progressBar.value.addEventListener('mouseleave', () => {
    progressTooltip.value.hide();
  });
});

onUnmounted(() => {
  if (progressBar.value) {
    progressBar.value.removeEventListener('mouseenter', () => {
      progressTooltip.value.show();
    });
    progressBar.value.removeEventListener('mouseleave', () => {
      progressTooltip.value.hide();
    });
  }
});
</script>

<template>
  <!-- Card -->
  <div
    class="z-[60] h-fit w-32 sm:w-64 relative flex flex-col bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
  >
    <div class="pt-2.5 pl-2 sm:pl-4">
      <h2 class="flex sm:gap-x-2 text-xs sm:text-sm font-semibold text-gray-800 dark:text-white items-center">
        {{ $t('message.ingameProgress') }}
        <span class="material-symbols-outlined text-center"> exercise </span>
      </h2>
    </div>

    <!--    {{ userXp }} xp.-->
    <!--        {{progress}} %.-->

    <!-- Body -->
    <div class="p-1.5 pl-0.5 sm:pl-1.5 h-fit">
      <div class="p-2.5 pl-1.5 sm:pl-2.5">
        <div class="inline-flex p-0 m-0 h-fit items-start gap-x-2">
          <span class="font-semibold leading-none pt-1 text-sm text-gray-500 dark:text-gray-200">
            {{ $t('message.ingameLevel') }}
          </span>
          <span class="font-semibold leading-none text-start text-4xl text-gray-800 dark:text-white">
            {{ currentLevel }}
          </span>
        </div>

        <!-- Progress -->
        <div class="hs-tooltip relative" id="progress-tooltip">
          <div class="relative mt-3">
            <button
              class="absolute transition-all duration-2000 ease-in-out hs-tooltip-toggle cursor-pointer top-1/2 w-2 h-5 bg-orange-500 border-2 border-white rounded-sm transform -translate-y-1/2 dark:border-neutral-700"
              :style="{ left: progress + '%' }"
              type="button"
            ></button>
            <div
              ref="progressBar"
              type="button"
              class="flex w-full cursor-pointer h-2 bg-gray-200 rounded-sm overflow-hidden dark:bg-neutral-700"
              role="progressbar"
              :aria-valuenow="progress"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                class="flex flex-col justify-center transition-all duration-2000 ease-in-out transform rounded-sm overflow-hidden bg-orange-500 text-xs text-white text-center whitespace-nowrap"
                :style="'width: ' + progress + '%'"
              ></div>
            </div>
            <span
              class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
              role="tooltip"
            >
              {{ userXp }} XP ({{ progress.toFixed(0) }}%)
            </span>
          </div>
        </div>
        <!-- End Progress -->

        <!--          <div class="col-start-2 text-center">-->
        <!--    <div class="hs-tooltip inline-block">-->
        <!--      <button type="button" class="hs-tooltip-toggle size-10 inline-flex justify-center items-center gap-2 rounded-full bg-gray-50 border border-gray-200 text-gray-600 hover:bg-blue-50 hover:border-blue-200 hover:text-orange-500 focus:outline-none focus:bg-blue-50 focus:border-blue-200 focus:text-orange-500 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:hover:bg-white/10 dark:hover:border-white/10 dark:hover:text-white dark:focus:bg-white/10 dark:focus:border-white/10 dark:focus:text-white">-->
        <!--        <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">-->
        <!--          <path d="m18 15-6-6-6 6"></path>-->
        <!--        </svg>-->
        <!--        <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700" role="tooltip">-->
        <!--          Tooltip on top-->
        <!--        </span>-->
        <!--      </button>-->
        <!--    </div>-->
        <!--  </div>-->

        <!-- Progress Status -->
        <div class="mt-3 flex justify-between items-center">
          <span class="text-xs text-gray-500 dark:text-neutral-500">
            {{ getThresholdForCurrentLevel(currentLevel) }} XP
          </span>
          <span class="text-xs text-gray-500 dark:text-neutral-500">
            {{ getThresholdForNextLevel(currentLevel) }} XP
          </span>
        </div>
        <!-- End Progress Status -->

        <!--        <p class="mt-4 text-sm text-gray-600 dark:text-neutral-400">-->
        <!--        </p>-->
      </div>
    </div>
    <!-- End Body -->
  </div>
  <!-- End Card -->
</template>

<style scoped></style>
