<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue';
import { HSDropdown, HSStaticMethods } from 'preline';
import { useI18n } from 'vue-i18n';
import { HSCopyMarkup } from 'preline';
import { getApiClient } from '@/apiclient/client';
const { t } = useI18n();
import { v4 as uuidv4 } from 'uuid';

const props = defineProps({
  maxNumber: {
    type: Number,
    default: 5,
  },
  contentForExtraction: {
    type: String,
    default: '',
  },
});

const componentId = uuidv4();
const learningObjectives = ref([] as { description: string; importance: number }[]);
const learningObjectiveComplexInputRefs = ref([] as (HTMLElement | null)[]); // may be more than an input field, e.g. input field + importance slider or alike
const isLoading = ref(false);

// define emits for notifying parent component that learning objectives have been changes
const emit = defineEmits(['change', 'requestCompute']);

function getLearningObjectives() {
  return learningObjectives.value;
}

async function extractAndSetLearningObjectives(content: string) {
  if (!content) {
    throw new Error('No content passed for learning objective extraction');
  }

  try {
    isLoading.value = true;
    // extract learning objectives from passed content
    console.debug('Extracting learning objectives from content: ' + content);
    const client = await getApiClient();
    const learningObjectives = await client.evaluation.extractLearningObjectives({ content });
    await setLearningObjectives(learningObjectives, true);
  } catch (error) {
    console.error('Error extracting learning objectives: ' + error);
  } finally {
    isLoading.value = false;
  }
}

function setLearningObjectives(
  newLearningObjectives: { description: string; importance: number }[],
  emitChange = false,
) {
  learningObjectives.value = newLearningObjectives;
  learningObjectives.value.forEach((objective) => {
    learningObjectiveComplexInputRefs.value.push(null);
  });
  if (emitChange) emit('change'); // only emit if changed internally, i.e. thru extract
}

function addObjective() {
  learningObjectives.value.push({ description: '', importance: 100 });
  learningObjectiveComplexInputRefs.value.push(null);
}

defineExpose({
  getLearningObjectives,
  setLearningObjectives,
  extractAndSetLearningObjectives,
});
</script>

<template>
  <div v-if="isLoading" class="flex items-center justify-center h-32">
    <div
      class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
      role="status"
      aria-label="loading"
    ></div>
    <p class="text-gray-800 dark:text-white ml-4">{{ $t('message.learningObjectivesBeingExtracted') }}</p>
  </div>

  <div
    class="flex rounded-lg shadow-sm"
    v-for="(objective, index) in learningObjectives"
    :key="index"
    :ref="learningObjectiveComplexInputRefs[index]"
  >
    <input
      type="text"
      :placeholder="$t('message.enterLearningObjective')"
      @input="emit('change')"
      v-model="learningObjectives[index].description"
      class="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-s-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
    />
    <button
      type="button"
      @click.prevent="
        learningObjectives.splice(index, 1);
        learningObjectiveComplexInputRefs.splice(index, 1);
        emit('change');
      "
      class="w-[2.875rem] h-[2.875rem] shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
    >
      <span class="material-symbols-outlined">delete</span>
    </button>
  </div>

  <div class="mt-3 space-x-4 text-end">
    <button
      :disabled="isLoading"
      type="button"
      @click.prevent="addObjective"
      class="py-1.5 px-2 inline-flex items-center gap-x-1 text-xs font-medium rounded-full border border-dashed border-gray-200 bg-white text-gray-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
    >
      <span class="material-symbols-outlined">add</span>{{ $t('message.addLearningObjective') }}
    </button>

    <button
      :disabled="isLoading"
      type="button"
      @click="emit('requestCompute')"
      class="mt-3 py-1.5 px-2 inline-flex items-center gap-x-1 text-xs font-medium rounded-full border border-dashed border-gray-200 bg-white text-gray-800 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
    >
      <span class="material-symbols-outlined">manufacturing</span>{{ $t('message.extractLearningObjectives') }}
    </button>
  </div>
</template>
