<script setup>
import { ref, onMounted, computed, onBeforeMount, nextTick, watch, onBeforeUnmount, defineProps } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { router } from '@/router';
import { useAlertStore, useAuthStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import BreadcrumpElement from '@/components/BreadcrumpElement.vue';
import { useCourseInteractionStore } from '@/stores/courseInteraction.store';
import TextEditor from '@/views/courses/TextEditor.vue';
import { HSSelect, HSStaticMethods } from 'preline';
import { useI18n } from 'vue-i18n';
import { debounce } from 'lodash';
import draggable from 'vuedraggable';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';

const { t } = useI18n();

const courseStore = useCourseStore();
const {
  currentCourse,
  currentCourseTitle,
  currentCourseDescription,
  currentCourseChapters,
  numberOfChapters,
  numberOfCases,
} = storeToRefs(courseStore);
const courseInteractionStore = useCourseInteractionStore();
const {
  isEnrolled,
  numChaptersCompleted,
  currentCourseAccomplishments,
  numSectionsPerChapter,
  numSectionsStartedPerChapter,
  numSectionsCompletedPerChapter,
  fetchingProgress,
} = storeToRefs(courseInteractionStore);
const alertStore = useAlertStore();
const authStore = useAuthStore();
const { userId, isAdmin: userIsAdmin } = storeToRefs(authStore);
const isLoading = ref(false);
const isEditing = ref(false);
const initialFetchCompleted = ref(false);
const renderEditor = ref(true); // toggle to re-rerender
const savingChangedOrder = ref(false);
const isDraggingEnabled = ref(false);

const courseScrollContainer = ref();
const callbackQueue = ref([]);
const courseTitleEditor = ref(null);
const courseDescriptionEditor = ref(null);
const confirmDeleteChapterModal = ref(null);
const aboutToDeleteChapterWithIndex = ref(null);

const newCourseTitle = ref('');
const newCourseDescription = ref('');

const sortedChapters = ref([]);

const unsavedChangesCounter = ref(0);

const innerHeader = ref(null);
const innerHeaderHeight = ref(0);

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

const adjustHeight = () => {
  if (innerHeader.value) {
    innerHeaderHeight.value = innerHeader.value.offsetHeight;
  }
};

const percentageFetched = computed(() => {
  return Math.round(fetchingProgress.value * 100);
});

const debouncedAdjustHeight = debounce(adjustHeight, 200);

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeight);
});

onBeforeMount(async () => {
  await router.isReady();

  window.addEventListener('resize', debouncedAdjustHeight);

  let courseId = router.currentRoute.value.params.courseId;
  console.log('Premount for: #' + courseId + '#');
  if (courseId === null || courseId === 'null') {
    console.error('courseId cannot be null');

    // // Crete a new course
    // console.log('Create new course');
    // await courseStore.appendEmptyCourse();
    // await router.push('/course/' + courseStore.currentCourse.id);
    // alertStore.success('Neuer Kurs erstellt.');
    // courseId = courseStore.currentCourse.id;
  }
  console.log('Continuing premount for: #' + courseId + '#');
  await courseStore.setCurrentCourse(courseId);
  const savedState = localStorage.getItem(`course-editing-${courseId}`);
  if (savedState !== null && isOwnerOrEditorOrAdmin.value) {
    isEditing.value = JSON.parse(savedState); // Restore the saved state if edit permissions
  }
  console.log('Title: ', currentCourseTitle.value);
  newCourseTitle.value = currentCourseTitle.value;
  newCourseDescription.value = currentCourseDescription.value;
  console.log('#chapters: ' + numberOfChapters.value);
  if (!numberOfChapters.value && isOwnerOrEditorOrAdmin.value) {
    console.log('Empty course - setting edit on');
    isEditing.value = true;
  }

  console.log('Checking if enrolled = there exists an interaction for this course');
  await courseInteractionStore.setCourseInteractionForCurrentCourseIfEnrolled();

  initialFetchCompleted.value = true;
});

onMounted(async () => {
  console.log('CourseDetails mounted');
  if (currentCourseChapters.value.length > 0) {
    sortedChapters.value = getOrderedChapters();
  }
  // watcher: if changes to chapter length completed, trigger scroll
  watch(
    () => currentCourseChapters.value.length,
    async (newLength, oldLength) => {
      sortedChapters.value = getOrderedChapters();
      console.debug('#chapters changed from ' + oldLength + ' to ' + newLength);

      await nextTick();
      for (const func of callbackQueue.value) {
        console.log('executing callback');
        await func();
      }
      callbackQueue.value = [];
    },
  );
  // Watcher to update sortedChapters whenever chapters change
  watch(
    () => currentCourseChapters.value,
    async (newChapters) => {
      sortedChapters.value = getOrderedChapters();

      // Use nextTick to wait for DOM updates before enabling dragging
      await nextTick();

      // Enable dragging after ensuring data and DOM are in sync
      isDraggingEnabled.value = true;
    },
    { immediate: true },
  );
  watch(
    () => isEditing.value,
    async (newVal, oldVal) => {
      await rerenderEditor();
      localStorage.setItem(`course-editing-${courseStore.currentCourse.id}`, JSON.stringify(isEditing.value));
    },
  );
  setTimeout(() => {
    console.log('HSS init');
    HSStaticMethods.autoInit();
  }, 100);
  isDraggingEnabled.value = true;
});

const getOrderedChapters = () => {
  // order currentCourseChapters.value by index
  const chapters = currentCourseChapters.value;
  if (!chapters) {
    return [];
  }
  chapters.sort((a, b) => a.index - b.index);
  return chapters;
};

const enrollButtonText = computed(() => {
  return isEnrolled.value ? t('message.continueCourse') : t('message.enrollCourse');
});

async function onStartCourseClick() {
  console.debug('Starting course ' + currentCourse.value.id);

  isLoading.value = true;
  await courseInteractionStore.enrollForCurrentCourse(t);
  isLoading.value = false;
}

async function onStartChapter(idx) {
  if (savingChangedOrder.value) {
    console.log('saving order change, ignore onStartChapter');
    return;
  }
  console.debug('Starting chapter ' + idx);

  if (isEditing.value) {
    console.debug('Editing mode active, not starting chapter.');
    await onEditChapter(idx);
    return;
  }

  if (!isEnrolled.value) {
    console.debug('User is not enrolled in course yet.');
    alertStore.error('status.error.enrollFirstInCourse');
    // wait for 2 seconds
    await new Promise((r) => setTimeout(r, 2000));
    return;
  }

  isLoading.value = true;
  await courseInteractionStore.startChapterByIndex(idx);
  //await courseStore.setCurrentChapterByIndex(idx);

  console.log('Navigating to chapter ' + courseStore.currentChapterId);
  isLoading.value = false;
  // wait 200 ms
  await new Promise((r) => setTimeout(r, 200));
  await router.push('/chapter/' + courseStore.currentChapterId);
}

async function scrollToBottom() {
  console.log('scrolls: ' + document.body.scrollHeight);
  await nextTick();
  setTimeout(() => {
    courseScrollContainer.value.scrollTo({
      top: courseScrollContainer.value.scrollHeight,
      behavior: 'smooth',
    });
  }, 200);
}

async function onEditChapter(idx) {
  console.debug('Editing chapter ' + idx);
  isLoading.value = true;
  await courseStore.setCurrentChapterByIndex(idx);
  await router.push('/edit-chapter/' + courseStore.currentChapterId);
  console.log('Navigating to edit chapter ' + courseStore.currentChapterId);
  isLoading.value = false;
}

const onMoveChapter = async (event) => {
  if (!isDraggingEnabled.value) return;
  savingChangedOrder.value = true;
  // The event contains useful information such as the new index of the dragged item
  const { oldIndex, newIndex } = event;
  console.log(`Item moved from ${oldIndex} to ${newIndex}`);

  await courseStore.moveChapter(oldIndex, newIndex);
  sortedChapters.value = getOrderedChapters();

  // TODO: do changes by hand to avoid re-fetching all chapters
  await courseInteractionStore.setCourseInteractionForCurrentCourseIfEnrolled();

  savingChangedOrder.value = false;
};

async function onDeleteChapter(event, idx) {
  await event.stopPropagation();

  aboutToDeleteChapterWithIndex.value = idx;
  console.debug('Deleting chapter ' + idx);

  let confirm = await confirmDeleteChapterModal.value.promptUserConformation();
  aboutToDeleteChapterWithIndex.value = null;
  if (!confirm) {
    console.debug('User declined deletion');
    return;
  }

  savingChangedOrder.value = true;

  await courseStore.deleteChapter(idx);
  sortedChapters.value = getOrderedChapters();

  // TODO: do changes by hand to avoid re-fetching all chapters
  await courseInteractionStore.setCourseInteractionForCurrentCourseIfEnrolled();

  savingChangedOrder.value = false;
}

async function onNewChapter() {
  console.debug('Creating new chapter');
  isLoading.value = true;

  callbackQueue.value.push(scrollToBottom);
  await courseStore.appendEmptyChapter();
  isLoading.value = false;
}

async function onCopyLink() {
  console.debug('Copying course link to clipboard');
  // TODO: this seems to be http currently => fix, should be https
  const currentUrl = window.location.href;

  navigator.clipboard
    .writeText(currentUrl)
    .then(() => {
      console.log('URL copied to clipboard:', currentUrl);
      alertStore.success('status.success.copiedLinkToClipboard');
    })
    .catch((error) => {
      console.error('Failed to copy URL to clipboard:', error);
      alertStore.error('status.error.copiedLinkToClipboardError');
    });
}

async function onExportPdf() {
  console.debug('Exporting course progress to PDF');
  alertStore.error('status.error.notSupported');
}

async function onExportSignedPdf() {
  console.debug('Exporting course progress to signed PDF');
  alertStore.error('status.error.notSupported');
}

async function onPreviousCourse() {
  console.debug('Navigating to previous course');
  alertStore.error('status.error.notSupported');
}

async function onNextCourse() {
  console.debug('Navigating to next course');
  alertStore.error('status.error.notSupported');
}

function chapterCompletedProgress(idx) {
  if (!isEnrolled.value) {
    return 0;
  }
  if (!numSectionsCompletedPerChapter.value || !numSectionsPerChapter.value) {
    return 0;
  }
  if (numSectionsPerChapter.value[idx] === 0 || !numSectionsPerChapter.value[idx]) {
    return 0;
  }
  return (numSectionsCompletedPerChapter.value[idx] / numSectionsPerChapter.value[idx]) * 100;
}

function chapterStartedProgress(idx) {
  if (!isEnrolled.value) {
    return 0;
  }
  if (!numSectionsStartedPerChapter.value || !numSectionsPerChapter.value) {
    return 0;
  }
  if (numSectionsPerChapter.value[idx] === 0 || !numSectionsPerChapter.value[idx]) {
    return 0;
  }
  return (numSectionsStartedPerChapter.value[idx] / numSectionsPerChapter.value[idx]) * 100;
}

async function saveChanges() {
  console.debug('Saving changes');
  isLoading.value = true;
  console.log('Editor: ', courseTitleEditor);
  console.log('Editor.value: ', courseTitleEditor.value);
  await courseStore.updateCourseTitleAndDescription(
    currentCourse.value.id,
    courseTitleEditor.value.getRawTextContent(),
    courseDescriptionEditor.value.getRawTextContent(),
  );
  unsavedChangesCounter.value = 0;
  newCourseTitle.value = currentCourseTitle.value;
  newCourseDescription.value = currentCourseDescription.value;
  await rerenderEditor();
  alertStore.success('status.success.changesSaved');
  isLoading.value = false;
}

async function discardChanges() {
  console.debug('Discarding changes');
  isLoading.value = true;
  newCourseTitle.value = currentCourseTitle.value;
  newCourseDescription.value = currentCourseDescription.value;
  await rerenderEditor();
  unsavedChangesCounter.value = 0;
  alertStore.success('status.success.changesDiscarded');
  isLoading.value = false;
}

async function rerenderEditor() {
  renderEditor.value = false;
  await nextTick();
  renderEditor.value = true;
}

const isOwnerOrEditorOrAdmin = computed(() => {
  return (
    currentCourse.value &&
    (courseStore.isOwnerOfCurrentCourse(userId.value) ||
      courseStore.isEditorOfCurrentCourse(userId.value) ||
      userIsAdmin.value)
  );
});
</script>

<template>
  <div class="w-full h-screen">
    <header
      ref="innerHeader"
      class="sticky top-0 flex flex-wrap md:justify-start md:flex-nowrap z-10 w-full bg-white dark:border-none border-b border-gray-200 text-sm pb-4 dark:bg-neutral-900"
    >
      <div class="max-w-[85rem] w-full mx-auto px-4 md:px-6 lg:px-8 flex justify-between">
        <ol class="flex items-center whitespace-nowrap max-w-[85rem] w-full">
          <!-- level 1 breadcrump: all courses -->
          <BreadcrumpElement label="Kurse" to="/home" />
          <!-- level 2 breadcrump: current course -->
          <BreadcrumpElement
            :label="currentCourse ? currentCourse.title : 'Kurs'"
            :to="'/course/' + (currentCourse ? currentCourse.id : '')"
            :showSeparator="false"
          />
        </ol>
      </div>
    </header>

    <!-- Chapter cards -->
    <!-- TODO: list all chapters of the course, each as button/ router link -->
    <!-- TODO: #sections, #cases, progress = #stars reached/ total -->
    <!-- End of chapter cards -->

    <!-- Table Section -->
    <main
      ref="courseScrollContainer"
      class="flex-grow w-full overflow-y-auto"
      :style="{ height: `calc(100dvh - ${innerHeaderHeight + outerHeaderHeight}px)` }"
    >
      <div v-show="!initialFetchCompleted">
        <div class="flex justify-center items-center pt-64 min-h-full relative">
          <div
            class="animate-spin inline-block w-32 h-32 border-4 border-current border-t-transparent text-blue-600 rounded-full"
            role="status"
            aria-label="loading"
          />
          <div class="absolute start-50 top-50">{{ percentageFetched }} %</div>
        </div>
      </div>
      <div
        v-show="initialFetchCompleted"
        class="flex-col flex max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto h-fit min-h-fit overflow-hidden"
      >
        <!-- Card -->
        <div class="-m-1.5 overflow-x-auto overflow-y-visible">
          <div class="p-1.5 min-w-full inline-block align-middle overflow-y-visible">
            <div
              class="bg-white border border-gray-200 rounded-xl shadow-sm overflow-visible dark:bg-neutral-900 dark:border-gray-700"
            >
              <!-- Header -->
              <div
                class="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-gray-700"
              >
                <div class="flex-col">
                  <!-- discard/ save buttons -->
                  <div class="flex justify-right gap-x-2 pb-2">
                    <button
                      v-show="unsavedChangesCounter"
                      type="button"
                      class="py-2 px-3 flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-400 text-white hover:bg-red-500 disabled:opacity-50 disabled:pointer-events-none"
                      @click="discardChanges"
                    >
                      <div
                        v-if="isLoading"
                        class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                        role="status"
                        aria-label="loading"
                      >
                        <span class="sr-only">Loading</span>
                      </div>
                      <div class="flex gap-x-1 text-xs" v-else>Verwerfen</div>
                    </button>
                    <button
                      v-show="unsavedChangesCounter"
                      type="button"
                      class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      @click="saveChanges"
                    >
                      <div
                        v-if="isLoading"
                        class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                        role="status"
                        aria-label="loading"
                      >
                        <span class="sr-only">{{ $t('message.loading') }}</span>
                      </div>
                      <div class="flex gap-x-1 text-xs" v-else>{{ $t('message.save') }}</div>
                    </button>
                  </div>

                  <div>
                    <h2 class="text-xl font-semibold text-gray-800 dark:text-gray-200">
                      <TextEditor
                        v-if="initialFetchCompleted && renderEditor"
                        ref="courseTitleEditor"
                        :allowEdit="isEditing"
                        :allowFormatting="false"
                        :content="newCourseTitle"
                        :placeholderMessage="t('message.courseTitle')"
                        @unsavedChanges="unsavedChangesCounter++"
                        @changesCleared="unsavedChangesCounter--"
                      />
                    </h2>
                    <p class="inline-flex items-center text-sm text-gray-600 dark:text-gray-400">
                      <TextEditor
                        v-if="initialFetchCompleted && renderEditor"
                        ref="courseDescriptionEditor"
                        :allowEdit="isEditing"
                        :allowFormatting="false"
                        :content="newCourseDescription"
                        :placeholderMessage="t('message.courseDescription')"
                        @unsavedChanges="unsavedChangesCounter++"
                        @changesCleared="unsavedChangesCounter--"
                      />
                      {{ newCourseDescription ? '&nbsp;' : '' }}{{ $t('message.chapter', numberOfChapters) }},
                      {{ $t('message.case', numberOfCases) }} .
                    </p>
                  </div>
                </div>

                <!-- buttons -->
                <div>
                  <div class="inline-flex gap-x-2">
                    <!-- Bearbeiten an/ aus-->
                    <div class="flex-col justify-center flex items-center" v-show="isOwnerOrEditorOrAdmin">
                      <p class="flex flex-col items-center">
                        <input
                          v-model="isEditing"
                          :disabled="unsavedChangesCounter || savingChangedOrder"
                          type="checkbox"
                          id="hs-xs-switch"
                          class="relative w-[35px] h-[21px] bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-600 before:inline-block before:w-4 before:h-4 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
                        />
                      </p>
                      <p class="inline-flex items-center">
                        <span
                          v-show="savingChangedOrder"
                          class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                          role="status"
                          aria-label="loading"
                        >
                        </span>
                        <label for="hs-xs-switch" class="text-sm text-gray-500 ms-3 dark:text-gray-400 pr-2">{{
                          $t('message.edit')
                        }}</label>
                      </p>
                    </div>

                    <!-- Freunde einladen -->
                    <div v-show="isEnrolled" class="hs-dropdown relative inline-block [--placement:bottom-right]">
                      <button
                        id="hs-invite-friends-to-course-dropdown"
                        type="button"
                        class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800"
                      >
                        <svg
                          class="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M5 12h14" />
                          <path d="M12 5v14" />
                        </svg>
                        {{ $t('message.inviteFriends') }}
                      </button>
                      <div
                        class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden divide-y divide-gray-200 min-w-48 z-20 bg-white shadow-md rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-neutral-800 dark:border dark:border-gray-700"
                        aria-labelledby="hs-invite-friends-to-course-dropdown"
                      >
                        <div class="py-2 first:pt-0 last:pb-0">
                          <span class="block py-2 px-3 text-xs font-medium uppercase text-gray-400 dark:text-gray-600">
                            {{ $t('message.options') }}
                          </span>
                          <a
                            class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            @click.prevent="onCopyLink"
                          >
                            <svg
                              class="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
                              <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                            </svg>
                            {{ $t('message.copyLink') }}
                          </a>
                        </div>
                      </div>
                    </div>

                    <!-- Fortschritt exportieren -->
                    <div v-show="isEnrolled" class="hs-dropdown relative inline-block [--placement:bottom-right]">
                      <button
                        id="hs-export-course-progress-dropdown"
                        type="button"
                        class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800"
                      >
                        <svg
                          class="flex-shrink-0 size-3.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                          <polyline points="7 10 12 15 17 10" />
                          <line x1="12" x2="12" y1="15" y2="3" />
                        </svg>
                        {{ $t('message.exportProgress') }}
                      </button>
                      <div
                        class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden divide-y divide-gray-200 min-w-48 z-20 bg-white shadow-md rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-neutral-800 dark:border dark:border-gray-700"
                        aria-labelledby="hs-export-course-progress-dropdown"
                      >
                        <div class="py-2 first:pt-0 last:pb-0">
                          <span class="block py-2 px-3 text-xs font-medium uppercase text-gray-400 dark:text-gray-600">
                            {{ $t('message.downloadOptions') }}
                          </span>
                          <a
                            class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            @click.prevent="onExportPdf"
                          >
                            <svg
                              class="size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
                              />
                              <path
                                d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"
                              />
                            </svg>
                            .PDF
                          </a>
                          <a
                            class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            @click.prevent="onExportSignedPdf"
                          >
                            <svg
                              class="size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
                              />
                              <path
                                d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"
                              />
                            </svg>
                            {{ $t('message.signedPDFWithQRCode') }}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div>
                      <button
                        v-if="isLoading"
                        class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      >
                        <a
                          class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                          role="status"
                          aria-label="loading"
                        >
                          <span class="sr-only">{{ $t('message.loading') }}</span>
                        </a>
                      </button>
                      <button
                        class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                        @click="onStartCourseClick"
                        v-else
                      >
                        <svg
                          class="h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          width="16"
                          height="16"
                          viewBox="0 0 16 13"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"
                          />
                        </svg>
                        {{ enrollButtonText }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Header -->

              <!-- Table -->
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <!-- Table head -->
                <thead class="bg-gray-50 dark:bg-neutral-800">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-start">
                      <a class="group inline-flex items-center gap-x-2">
                        <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                          {{ $t('message.chapter') }}
                        </span>
                      </a>
                    </th>

                    <th scope="col" class="px-6 py-3 text-start">
                      <a class="group inline-flex items-center gap-x-2">
                        <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                          {{ $t('message.abstract') }}
                        </span>
                      </a>
                    </th>

                    <th scope="col" class="px-6 py-3 text-start">
                      <a class="group inline-flex items-center gap-x-2">
                        <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                          {{ $t('message.progress') }}
                        </span>
                      </a>
                    </th>

                    <th scope="col" class="px-6 py-3 text-start">
                      <a class="group inline-flex items-center gap-x-2">
                        <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                          {{ $t('message.patient', 2) }}
                        </span>
                      </a>
                    </th>

                    <th scope="col" class="px-6 py-3 text-end"></th>
                  </tr>
                </thead>

                <!-- Table body -->
                <!-- Table body row -->
                <draggable
                  v-if="sortedChapters.length > 0"
                  v-model="sortedChapters"
                  item-key="chapter.id"
                  tag="tbody"
                  class="divide-y divide-gray-200 dark:divide-gray-700"
                  @end="onMoveChapter"
                  :disabled="!isDraggingEnabled || !isEditing || savingChangedOrder"
                  :class="{
                    'cursor-move': isDraggingEnabled && isEditing && !savingChangedOrder,
                    'cursor-pointer': !isDraggingEnabled || !isEditing || savingChangedOrder,
                  }"
                >
                  <template #item="{ element: chapter }">
                    <tr
                      class="min-w-full w-full hover:bg-gray-50 dark:bg-neutral-900 dark:hover:bg-neutral-800"
                      :class="{
                        'bg-white': chapter.index !== aboutToDeleteChapterWithIndex,
                        'bg-red-100': chapter.index === aboutToDeleteChapterWithIndex,
                      }"
                      :key="chapter.id"
                      @click="onStartChapter(chapter.index)"
                    >
                      <td class="size-px whitespace-nowrap">
                        <a
                          class="block relative z-10"
                          :class="{
                            'cursor-move': isDraggingEnabled && isEditing && !savingChangedOrder,
                            'cursor-pointer': !isDraggingEnabled || !isEditing || savingChangedOrder,
                          }"
                        >
                          <div class="px-6 py-2">
                            <p
                              class="block text-sm text-blue-600 decoration-2 hover:underline dark:text-blue-600 cursor-pointer"
                              :class="{ 'text-gray-400': savingChangedOrder }"
                            >
                              {{ chapter.title }}
                            </p>
                          </div>
                        </a>
                      </td>
                      <td class="h-px w-72 min-w-72">
                        <a class="block relative z-10">
                          <div class="px-6 py-2">
                            <p class="text-sm text-gray-500">
                              {{ chapter.description }}
                            </p>
                          </div>
                        </a>
                      </td>
                      <td class="size-px whitespace-nowrap">
                        <a class="block relative z-10">
                          <div class="px-6 py-2 flex gap-x-1">
                            <div v-show="initialFetchCompleted" class="grid min-w-full w-full h-fit items-center">
                              <div
                                class="row-start-1 col-start-1 w-full h-fit bg-transparent z-10 rounded-full overflow-hidden dark:bg-neutral-700 text-xs text-center my-auto"
                                :class="{
                                  'text-gray-600': chapterCompletedProgress(chapter.index) < 50,
                                  'text-white pr-8':
                                    chapterCompletedProgress(chapter.index) >= 50 &&
                                    chapterCompletedProgress(chapter.index) < 60,
                                  'text-white': chapterCompletedProgress(chapter.index) >= 60,
                                }"
                              >
                                {{ chapterCompletedProgress(chapter.index).toFixed(0) }}%
                              </div>
                              <div
                                class="row-start-1 col-start-1 grid w-full h-3.5 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700"
                              >
                                <div
                                  class="row-start-1 col-start-1 flex flex-col justify-center overflow-hidden bg-blue-200 text-xs text-white text-center whitespace-nowrap rounded-[4px]"
                                  :style="'width: ' + chapterStartedProgress(chapter.index) + '%'"
                                  role="progressbar"
                                  :aria-valuenow="chapterStartedProgress(chapter.index)"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                />
                                <div
                                  class="row-start-1 col-start-1 flex flex-col justify-center overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap rounded-[4px]"
                                  :style="'width: ' + chapterCompletedProgress(chapter.index) + '%'"
                                  role="progressbar"
                                  :aria-valuenow="chapterCompletedProgress(chapter.index)"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                />
                              </div>
                            </div>
                          </div>
                        </a>
                      </td>
                      <td class="size-px whitespace-nowrap">
                        <a class="block relative z-10">
                          <div class="px-6 py-2 flex -space-x-1">
                            <div class="hs-tooltip inline-flex" v-for="(case_, idx) in chapter.exemplary_cases">
                              <img
                                class="hs-tooltip-toggle inline-block size-6 rounded-full ring-2 ring-white dark:ring-gray-800"
                                :src="case_.patient.profile_image.image_urls.small"
                                alt="Image Description"
                              />
                              <span
                                class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
                                role="tooltip"
                              >
                                {{ case_.patient.details.first_name + ' ' + case_.patient.details.last_name }}
                              </span>
                            </div>
                          </div>
                        </a>
                      </td>
                      <td class="size-px whitespace-nowrap">
                        <div class="px-6 py-2">
                          <button
                            v-show="isEditing"
                            :disabled="savingChangedOrder"
                            class="material-symbols-outlined text-red-600 hover:text-red-800 text-xl"
                            @click="onDeleteChapter($event, chapter.index)"
                          >
                            delete
                          </button>
                          <!--                          <div class="hs-dropdown relative inline-block [&#45;&#45;placement:bottom-right]">-->
                          <!--                            <button-->
                          <!--                              v-show="isEditing || isEnrolled"-->
                          <!--                              :id="'hs-table-dropdown-' + chapter.index"-->
                          <!--                              type="button"-->
                          <!--                              class="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-lg text-gray-700 align-middle disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"-->
                          <!--                            >-->
                          <!--                              <svg-->
                          <!--                                class="flex-shrink-0 size-4"-->
                          <!--                                xmlns="http://www.w3.org/2000/svg"-->
                          <!--                                width="24"-->
                          <!--                                height="24"-->
                          <!--                                viewBox="0 0 24 24"-->
                          <!--                                fill="none"-->
                          <!--                                stroke="currentColor"-->
                          <!--                                stroke-width="2"-->
                          <!--                                stroke-linecap="round"-->
                          <!--                                stroke-linejoin="round"-->
                          <!--                              >-->
                          <!--                                <circle cx="12" cy="12" r="1" />-->
                          <!--                                <circle cx="19" cy="12" r="1" />-->
                          <!--                                <circle cx="5" cy="12" r="1" />-->
                          <!--                              </svg>-->
                          <!--                            </button>-->
                          <!--                            <div-->
                          <!--                              class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden divide-y divide-gray-200 min-w-40 z-20 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-neutral-800 dark:border dark:border-gray-700"-->
                          <!--                              :aria-labelledby="'hs-table-dropdown-' + chapter.index"-->
                          <!--                            >-->
                          <!--                              <div class="py-2 first:pt-0 last:pb-0">-->
                          <!--                                <span-->
                          <!--                                  class="block py-2 px-3 text-xs font-medium uppercase text-gray-400 dark:text-gray-600"-->
                          <!--                                >-->
                          <!--                                  {{ $t('message.actions') }}-->
                          <!--                                </span>-->
                          <!--                                <a-->
                          <!--                                  v-show="isEnrolled"-->
                          <!--                                  class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"-->
                          <!--                                  href="#"-->
                          <!--                                  @click.prevent="onStartChapter(idx)"-->
                          <!--                                >-->
                          <!--                                  {{ $t('message.continue') }}-->
                          <!--                                </a>-->
                          <!--                                <a-->
                          <!--                                  v-show="isEditing"-->
                          <!--                                  class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"-->
                          <!--                                  href="#"-->
                          <!--                                  @click.prevent="onEditChapter(idx)"-->
                          <!--                                >-->
                          <!--                                  {{ $t('message.edit') }}-->
                          <!--                                </a>-->
                          <!--                                &lt;!&ndash;                              <a&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                                v-show="isEditing"&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                                class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                                href="#"&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                                @click.prevent="onMoveChapterUp(idx)"&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                              >&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                                {{ $t('message.moveUp') }}&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                              </a>&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                              <a&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                                v-show="isEditing"&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                                class="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                                href="#"&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                                @click.prevent="onMoveChapterDown(idx)"&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                              >&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                                {{ $t('message.moveDown') }}&ndash;&gt;-->
                          <!--                                &lt;!&ndash;                              </a>&ndash;&gt;-->
                          <!--                                <a-->
                          <!--                                  v-show="isEditing"-->
                          <!--                                  class="flex text-red-500 items-center gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-red-500 dark:hover:bg-gray-700 dark:hover:text-red-300"-->
                          <!--                                  href="#"-->
                          <!--                                  @click.prevent="onDeleteChapter(idx)"-->
                          <!--                                >-->
                          <!--                                  {{ $t('message.delete') }}-->
                          <!--                                </a>-->
                          <!--                              </div>-->
                          <!--                            </div>-->
                          <!--                          </div>-->
                        </div>
                      </td>
                    </tr>
                  </template>
                </draggable>

                <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                  <tr v-show="isEditing">
                    <td colspan="5">
                      <button
                        type="button"
                        class="min-w-full py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                        @click="onNewChapter"
                      >
                        <div
                          v-if="isLoading"
                          class="animate-spin inline-block w-4 h-4 text-white rounded-full"
                          role="status"
                          aria-label="loading"
                        >
                          <span class="sr-only">Loading</span>
                        </div>
                        <div class="flex min-w-full gap-x-3 items-center justify-center" v-else>
                          <svg
                            class="flex-shrink-0 size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M5 12h14" />
                            <path d="M12 5v14" />
                          </svg>
                          {{ $t('message.addChapter') }}
                        </div>
                      </button>
                    </td>
                  </tr>
                  <!-- End Table body row -->
                </tbody>
                <!-- End Table body -->
              </table>
              <!-- End Table -->

              <!-- Footer -->
              <div
                class="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700"
              >
                <div>
                  <p class="text-sm text-gray-600 dark:text-gray-400">
                    <span class="font-semibold text-gray-800 dark:text-gray-200">
                      {{ numChaptersCompleted }} / {{ numberOfChapters }}</span
                    >
                    {{ $t('message.chaptersCompleted') }}
                  </p>
                </div>

                <!--                <div>-->
                <!--                  <div class="inline-flex gap-x-2">-->
                <!--                    <button-->
                <!--                      type="button"-->
                <!--                      class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800"-->
                <!--                      @click.prevent="onPreviousCourse"-->
                <!--                    >-->
                <!--                      <svg-->
                <!--                        class="flex-shrink-0 size-4"-->
                <!--                        xmlns="http://www.w3.org/2000/svg"-->
                <!--                        width="24"-->
                <!--                        height="24"-->
                <!--                        viewBox="0 0 24 24"-->
                <!--                        fill="none"-->
                <!--                        stroke="currentColor"-->
                <!--                        stroke-width="2"-->
                <!--                        stroke-linecap="round"-->
                <!--                        stroke-linejoin="round"-->
                <!--                      >-->
                <!--                        <path d="m15 18-6-6 6-6" />-->
                <!--                      </svg>-->
                <!--                      {{ $t('message.previousCourse') }}-->
                <!--                    </button>-->

                <!--                    <button-->
                <!--                      type="button"-->
                <!--                      class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800"-->
                <!--                      @click.prevent="onNextCourse"-->
                <!--                    >-->
                <!--                      {{ $t('message.nextCourse') }}-->
                <!--                      <svg-->
                <!--                        class="flex-shrink-0 size-4"-->
                <!--                        xmlns="http://www.w3.org/2000/svg"-->
                <!--                        width="24"-->
                <!--                        height="24"-->
                <!--                        viewBox="0 0 24 24"-->
                <!--                        fill="none"-->
                <!--                        stroke="currentColor"-->
                <!--                        stroke-width="2"-->
                <!--                        stroke-linecap="round"-->
                <!--                        stroke-linejoin="round"-->
                <!--                      >-->
                <!--                        <path d="m9 18 6-6-6-6" />-->
                <!--                      </svg>-->
                <!--                    </button>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <!-- End Footer -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Card -->
    </main>

    <UserConfirmationModal
      ref="confirmDeleteChapterModal"
      prompt_message=""
      :approve_message="$t('message.delete')"
      :discard_message="$t('message.back')"
      overlayId="confirmDeleteChapterModal"
      approve_color="bg-red-500 hover:bg-red-600"
    >
      Möchten Sie das Kapitel wirklich löschen? Dies kann nicht rückgängig gemacht werden!
      <!--    TODO i18n  {{ $t('message.confirmDeleteChapter') }}-->
    </UserConfirmationModal>
  </div>
  <!-- End Table Section -->
</template>

<style></style>
