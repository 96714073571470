<script setup lang="ts">
import { nextTick, onBeforeMount, onMounted, ref } from 'vue';
import { getApiClient } from '@/apiclient/client';
import CaseHighlightCard from '@/components/CaseHighlightCard.vue';
import { formatDate, mapJobStatus, mapTitle } from '@/helper';
import ProfileImage from '@/components/ProfileImage.vue';
import { useAlertStore, useAuthStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { Case } from '@/apiclient';
import TextEditor from '@/views/courses/TextEditor.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import SelectCasesModal from '@/components/SelectCasesModal.vue';
import LearningObjectives from '@/components/inputs/LearningObjectives.vue';
import { v4 as uuidv4 } from 'uuid';

import { useI18n } from 'vue-i18n';
import { HSDropdown, HSStaticMethods, HSTooltip } from 'preline';
import MediaHighlightCard from '@/views/courses/MediaHighlightCard.vue';
import SectionMediaUpload from '@/views/courses/SectionMediaUpload.vue';

const { t } = useI18n();

const { section, chapterId } = defineProps(['section', 'chapterId']);
const caseList = ref();
const cases = ref([] as Case[]);
const media = ref([] as any[]);
const uuid = ref(uuidv4());

// ref for components
const titleEditor = ref(null);
const contentEditor = ref(null);
const learningObjectives = ref(null);

// this is the up-to-date content of the section's content title + editor
// **NOTE**: this is expensive and not needed if extract button not clicked. Therefore other logic used / RS 05.09.24
// const totalContent = computed(() => {
//   const title = titleEditor.value ? titleEditor.value.getHTMLContent() : '';
//   const content = contentEditor.value ? contentEditor.value.getHTMLContent() : '';
//
//   return title + ' ' + content;
// });

const computedContentForExtraction = ref('');

const computeTotalContent = () => {
  const title = titleEditor.value ? titleEditor.value.getHTMLContent() : '';
  const content = contentEditor.value ? contentEditor.value.getHTMLContent() : '';

  return title + ' ' + content;
};

const handleRequestCompute = () => {
  computedContentForExtraction.value = computeTotalContent();
  if (!learningObjectives.value) {
    console.error('null ref in learningObjectives');
    return;
  }
  if (!computedContentForExtraction.value) {
    // empty content, nothing to do
    return;
  }
  learningObjectives.value.extractAndSetLearningObjectives(computedContentForExtraction.value);
};

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const alertStore = useAlertStore();
const courseStore = useCourseStore();
const userConformationModalHandleDeleteCaseFromList = ref();
const mediaUploadModal = ref();
const selectCasesModal = ref(null);
const fullWidthIndex = ref(-1);
const initialFetchCompleted = ref(false);
const sendingTranslationRequest = ref(false);

const emit = defineEmits(['unsavedChanges', 'changesCleared', 'onDeleteSection']);

function signalUnsavedChanges() {
  console.log('unsaved changes forwarded');
  emit('unsavedChanges');
}

function signalChangesCleared() {
  console.log('changes cleared forwarded');
  emit('changesCleared');
}

function setLearningObjectives(sectionLearningObjectives: { description: string; importance: number }[]) {
  if (!sectionLearningObjectives) {
    return;
  }
  if (!learningObjectives.value) {
    console.error('null ref in learningObjectives');
    return;
  }
  console.log('learningObjectives ref: ' + learningObjectives.value);
  console.log('section.learning_objectives: ' + JSON.stringify(section.learning_objectives));
  // note: slice to avoid reactivity issues or in-place changes when field deleted
  learningObjectives.value.setLearningObjectives(sectionLearningObjectives.slice(), false);
}

onBeforeMount(async () => {
  await courseStore.settingCoursePromise;
  await courseStore.settingChapterPromise; // only mount after chapter is loaded to store
  // console.log('section: ' + JSON.stringify(section));
});

onMounted(async () => {
  await nextTick(() => {
    HSTooltip.autoInit();
  });

  console.log('section.case_list_id: ' + section.case_list_id);
  await fetchCaseList();
  await fetchMedia();
  initialFetchCompleted.value = true;

  console.log('# learning objectives: ' + section.learning_objectives.length);
  setLearningObjectives(section.learning_objectives);
});

async function fetchCaseList() {
  if (!section.case_list_id && !section.case_list.id) {
    cases.value = [];
  } else {
    caseList.value = await (
      await getApiClient()
    ).caseLists.getCaseList(
      (section.case_list ? section.case_list.id : null) ? section.case_list.id : section.case_list_id,
    );
    cases.value = caseList.value.cases;
  }

  // console.log('case list: ' + JSON.stringify(caseList.value));
  // console.log('cases: ' + JSON.stringify(cases.value));

  // add fake case to end
  cases.value.push({
    id: null,
    user: user.value,
    details: {
      setting: '',
      date: '',
      time: '',
      specialties: [],
      tags: [],
      situation: '',
    },
    patient: {
      id: null,
      details: {
        first_name: 'Fall hinzufügen',
        last_name: '',
      },
      created_at: '',
    },
    is_ready: true,
    // todays date
    created_at: formatDate(new Date().toDateString()),
  });
}

async function fetchMedia() {
  media.value = await (await getApiClient()).sections.getSectionMediaItems(section.id);
}

async function removeCaseFromList(caseId: string) {
  const userApproved = await userConformationModalHandleDeleteCaseFromList.value
    .promptUserConformation()
    .catch((error) => {
      console.debug('User declined: ' + error);
      return false;
    });
  if (!userApproved) {
    console.debug('User declined.');
    return;
  }
  await (await getApiClient()).caseLists.deleteCaseListCase(section.case_list_id, caseId);
  await fetchCaseList();
}

async function addCasesToList() {
  let selectedCases = await selectCasesModal.value.promptSelection();

  console.log('selectedCases', selectedCases);

  if (!selectedCases) {
    return;
  }

  await (await getApiClient()).caseLists.addCasesToCaseList(section.case_list_id, selectedCases);
  await fetchCaseList();
}

async function removeMediaItem(index: number) {
  console.log('remove media item #', index);
  await alertStore.error('Not supported.');
}

const toggleFullWidth = (index: number) => {
  if (fullWidthIndex.value === index) {
    fullWidthIndex.value = -1;
  } else {
    fullWidthIndex.value = index;
  }
};

const untoggleFullWidth = () => {
  fullWidthIndex.value = -1;
};

const onTranslateSection = () => {
  // TODO i18n
  sendingTranslationRequest.value = true;
  courseStore
    .requestSectionTranslations(section.id, 'deu', ['eng', 'rus', 'aeb', 'pes'])
    .then(() => {
      alertStore.success('Übersetzungsauftrag wurde erfolgreich gestartet.');
    })
    .catch((error) => {
      alertStore.error('Fehler beim Starten des Übersetzungsauftrags: ' + error);
    })
    .finally(() => {
      sendingTranslationRequest.value = false;
    });
};

defineExpose({
  titleEditor,
  contentEditor,
  learningObjectives,
});
</script>

<template>
  <!-- Blog Article -->
  <div
    class="px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-900 dark:border-gray-700 flex justify-center max-w-6xl"
    v-if="!initialFetchCompleted"
  >
    <div class="flex justify-center items-center h-20">
      <div
        class="animate-spin inline-block w-32 h-32 border-4 border-current border-t-transparent text-blue-600 rounded-full"
        role="status"
        aria-label="loading"
      />
    </div>
  </div>
  <div
    v-show="!!initialFetchCompleted"
    class="px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-900 dark:border-gray-700 flex justify-center max-w-6xl"
    @click="untoggleFullWidth"
  >
    <div class="max-w-4xl grow">
      <div class="flex justify-between items-center mb-6">
        <div class="flex w-full sm:items-center gap-x-5 sm:gap-x-3">
          <div class="grow">
            <div class="flex justify-between items-center gap-x-2">
              <!-- Button Group -->
              <div></div>
              <!-- End Button Group -->

              <div class="flex justify-between items-center gap-x-2 overflow-auto">
                <div>
                  <!-- Tooltip -->
                  <div class="hs-tooltip inline-block [--trigger:hover] [--placement:bottom]">
                    <div class="hs-tooltip-toggle sm:mb-1 block text-start cursor-pointer">
                      <span>
                        <span class="text-xs text-gray-500"> by </span>
                        <span class="font-semibold text-gray-800 dark:text-gray-200">
                          {{ section.user.academic_title ? mapTitle(section.user.academic_title) + ' ' : ''
                          }}{{ section.user.first_name }}
                          {{ section.user.last_name }}
                        </span>
                      </span>
                      <div class="text-xs text-gray-500">
                        {{ section.user.job_status ? mapJobStatus(section.user.job_status) : '' }}
                      </div>
                      <span
                        class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-white font-medium text-xs"
                        role="tooltip"
                      >
                        Author of this section
                        <!-- TODO i18n -->
                        <!-- TODO proper profile card or sth alike -->
                      </span>
                    </div>
                  </div>
                  <!-- End Tooltip -->
                  <ul class="text-xs text-gray-500">
                    <li
                      class="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full dark:text-gray-400 dark:before:bg-gray-600"
                    >
                      {{ formatDate(section.created_at) }}
                    </li>
                  </ul>
                </div>
                <div class="flex-shrink-0">
                  <ProfileImage
                    :image="section.user.userProfileImageSmall"
                    initials=""
                    size="4rem"
                    :showIngameLevel="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Avatar Media -->

      <!-- Content -->
      <div class="space-y-5 md:space-y-8">
        <div class="space-y-3">
          <h2 class="text-2xl font-bold md:text-3xl dark:text-white text-center">
            <TextEditor
              ref="titleEditor"
              :content="section.title"
              :allowFormatting="false"
              @unsavedChanges="signalUnsavedChanges"
              @changesCleared="signalChangesCleared"
            />
          </h2>

          <p class="text-base text-justify text-gray-800 dark:text-gray-200">
            <TextEditor
              ref="contentEditor"
              :content="section.contents"
              :allowList="true"
              :allowTable="true"
              @unsavedChanges="signalUnsavedChanges"
              @changesCleared="signalChangesCleared"
            />
          </p>

          <p class="text-base text-justify text-gray-800 dark:text-gray-200 font-semibold pt-8">Medieninhalte</p>
          <div class="grid grid-cols-3 gap-6 auto-rows-min">
            <div
              v-for="(mediaItem, index) in media"
              :key="index"
              class="transition-all duration-500 ease-in-out"
              :class="{
                'col-span-3 row-auto overflow-hidden': fullWidthIndex === index,
              }"
            >
              <MediaHighlightCard
                :mediaId="mediaItem.id"
                :url="mediaItem.media_url"
                :type="mediaItem.media_type"
                :description="mediaItem.description"
                :allowDescriptionEdit="true"
                :maxOnClick="true"
                :isMaximized="fullWidthIndex === index"
                @removeMediaFromList="removeMediaItem"
                @toggleFullWidth="toggleFullWidth(index)"
              />
            </div>
            <SectionMediaUpload :section-id="section.id" @uploadedItem="fetchMedia" />
          </div>

          <p class="text-base text-justify text-gray-800 dark:text-gray-200 font-semibold pt-8 mb-3">Übungsfälle</p>
          <div class="grid grid-cols-3 gap-6" v-if="initialFetchCompleted">
            <CaseHighlightCard
              v-for="case_ in cases"
              :case="case_"
              @removeCaseFromList="removeCaseFromList"
              @selectCases="addCasesToList"
              :asPartOfCaseList="section.case_list_id"
              :openCaseSelectionListIfCaseIsNull="true"
              :sectionId="section.id"
              :chapterId="chapterId"
            />
          </div>

          <!-- Learning objectives sevtion -->
          <p class="text-base text-justify text-gray-800 dark:text-gray-200 font-semibold pt-8 mb-3">Lernziele</p>
          <div class="space-y-3">
            <LearningObjectives
              ref="learningObjectives"
              :maxNumber="5"
              @requestCompute="handleRequestCompute"
              @change="signalUnsavedChanges"
            />
          </div>
        </div>
      </div>
      <!-- End content -->
    </div>
  </div>
  <!-- End Blog Article -->

  <!-- Bottom button group (not sticky as in template) -->
  <div class="inset-x-0 text-center justify-center flex gap-x-2 mb-4">
    <button
      class="flex items-center bg-red-100 hover:bg-red-200 shadow-md text-red-600 hover:text-red-800 rounded-full py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
      @click="emit('onDeleteSection')"
    >
      <span class="material-symbols-outlined text-xl">delete</span>
      {{ $t('message.delete') }}
    </button>
    <button
      class="flex items-center shadow-md rounded-full text-gray-800 bg-white hover:bg-gray-50 py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
      @click="onTranslateSection"
    >
      <span class="material-symbols-outlined text-xl">language</span>
      Übersetzung in alle unterstützten Sprachen veranlassen
      <!--      <Tooltip text="Der Auftrag wird über Nacht durchgeführt" />-->
      <!-- TODO: i18n -->
    </button>
  </div>
  <!-- End Bottom Button Group -->

  <UserConfirmationModal
    ref="userConformationModalHandleDeleteCaseFromList"
    prompt_message=""
    approve_message="Ja, löschen"
    discard_message="Nein, zurück"
    :overlayId="'confirmDiscardChangesModal' + uuid"
    approve_color="bg-red-500 hover:bg-red-600"
  >
    Möchten Sie den Fall wirklich entfernen?
  </UserConfirmationModal>

  <SelectCasesModal
    v-if="!!caseList"
    :overlayId="'selectCases' + uuid"
    ref="selectCasesModal"
    closeMessage="Schließen"
    :caseListId="caseList.id"
  />
</template>

<style scoped></style>
