<script>
import { nextTick, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  data: () => ({
    resolvePromise: undefined,
    rejectPromise: undefined,
    approved: false,
  }),
  props: {
    overlayId: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      default: 'Bestätigung erforderlich',
    },
    prompt_message: {
      type: String,
      required: true,
    },
    approve_message: {
      type: String,
      default: 'Bestätigen',
    },
    approve_color: {
      type: String,
      default: 'bg-blue-600 hover:bg-blue-700',
    },
    discard_color: {
      type: String,
      default: 'bg-gray-200 hover:bg-gray-50',
    },
    discard_message: {
      type: String,
      default: 'Abbrechen',
    },
    header_icon: {
      type: String,
      default: null,
    },
    width: 0.3,
  },
  computed: {
    hashtagOverlayId() {
      return '#' + this.overlayId;
    },
    refName() {
      return 'modalEL_' + this.overlayId;
    },
  },
  mounted() {
    // console.debug('mounted');
    // console.debug('ref: ' + this.$refs[this.refName]);
    // nextTick(() => {  // nextTick: deferred to wait for next DOM update
    //   this.$refs[this.refName].addEventListener('close.hs.overlay', (evt) => {
    //     console.debug('closing dialog')
    //     if (this.approved) {
    //       console.debug('was approved before close')
    //       this.resolvePromise(true)
    //       return
    //     }
    //     console.debug('declined or closed thru backdrop/ esc')
    //     this.resolvePromise(false)
    //     // emit('declined');
    //   })
    // });
  },
  async beforeUnmount() {
    await window.HSOverlay.close(this.$refs[this.refName]);
  },
  methods: {
    async promptUserConformation() {
      console.debug('Initiating user confirmation dialog');
      console.debug('ref: ' + this.$refs[this.refName]);
      await window.HSOverlay.open(this.$refs[this.refName]);
      console.debug('Modal is: ' + this.$refs[this.refName] + ' / ' + this.overlayId + ' / ' + this.refName);
      console.debug('Returning promise');
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async approveModal() {
      console.debug('approved');
      // await window.HSOverlay.close(this.$refs[this.refName]);
      await nextTick();
      await new Promise((resolve) => setTimeout(resolve, 200));
      // TODO: why is the wait needed?! (For testing: decline changes in EditCourseChapter w/o wait => backdrop remains open)
      this.resolvePromise(true);
    },
    async declineModal() {
      console.debug('declined');
      // await window.HSOverlay.close(this.$refs[this.refName]);
      await nextTick();
      await new Promise((resolve) => setTimeout(resolve, 200));
      this.resolvePromise(false);
    },
  },
  components: {
    FontAwesomeIcon,
  },
};

// <script setup>
// import {nextTick, onBeforeUnmount, onMounted, onUnmounted, ref} from "vue";
import { router } from '@/router';

// const emit = defineEmits(['approved', 'declined'])
// const modalEl = ref();
// const resolvePromise = undefined;
// const rejectPromise = undefined;
// const show = ref(false);
//
// const props = defineProps({
//   heading: {
//     type: String,
//     default: "Bestätigung erforderlich",
//   },
//   prompt_message: {
//     type: String,
//     default: 'Bitte bestätigen',
//   },
//   approve_message: {
//     type: String,
//     default: "Bestätigen",
//   },
//   discard_message: {
//     type: String,
//     default: "Abbrechen",
//   },
//   width: {
//     type: Number,
//     default: "0.3"
//   },
// });
// async function approveModal() {
//   console.debug('approved')
//   await HSOverlay.close(modalEl.value);
//   resolvePromise(true);
//   // emit('approved');
//   console.debug('approved2')
// }
//
// async function declineModal() {
//   console.debug('declined')
//   await HSOverlay.close(modalEl.value);
//   // emit('declined');
//   resolvePromise(false);
//   console.debug('declined2')
// }

// onBeforeUnmount(() => {
//   console.debug('before unmount')
// })
//
// onUnmounted(() => {
//   console.debug('unmounted')
// })
//
// onMounted(() => {
//   console.debug('mounted')
//   nextTick(() => {  // nextTick: deferred to wait for next DOM update
//     modalEl.value.addEventListener('close.hs.overlay', (evt) => {
//       console.debug('declined thru backdrop/ esc')
//       resolvePromise(false);
//       // emit('declined');
//     });
//   });
// });
//
</script>

<template>
  <div
    :ref="refName"
    :id="overlayId"
    class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[100] overflow-x-hidden overflow-y-auto"
  >
    <div
      class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-14 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto"
    >
      <div
        class="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-gray-700 dark:shadow-neutral-700/[.7]"
      >
        <div class="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
          <h3 class="font-bold text-gray-800 dark:text-white flex items-center gap-x-4">
            <span v-if="header_icon" class="material-symbols-outlined">
              {{ header_icon }}
            </span>
            {{ heading }}
          </h3>
          <button
            type="button"
            class="hs-dropup-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
            :data-hs-overlay="hashtagOverlayId"
            @click.stop="declineModal"
          >
            <span class="sr-only">Close</span>
            <svg
              class="w-3.5 h-3.5"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <div class="p-4 overflow-y-auto">
          <div class="mt-1 text-gray-800 dark:text-gray-400">
            <div class="modal-content">
              <slot></slot>
            </div>
          </div>
        </div>
        <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
          <button
            type="button"
            class="hs-dropup-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
            :class="discard_color"
            :data-hs-overlay="hashtagOverlayId"
            @click.stop="declineModal"
          >
            {{ discard_message }}
          </button>
          <a
            class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold text-white focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
            :class="approve_color"
            href="#"
            :data-hs-overlay="hashtagOverlayId"
            @click.stop="approveModal"
          >
            {{ approve_message }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
