<script>
import { storeToRefs } from 'pinia';

import { useCaseInteractionStore } from '@/stores/caseInteraction.store';
import { useAlertStore } from '@/stores';
import { nextTick, onMounted, reactive, ref } from 'vue';

import { SoundMeter } from '/src/audio/soundmeter.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faVial, faRadiation, faNotesMedical, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { HSTooltip, HSStaticMethods } from 'preline';

export default {
  components: { FontAwesomeIcon },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    reset: {
      // signals that the parent component wants to reset the transcription to await new input (e.g. submitted)
      default: false,
      type: Boolean,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    labelAbbreviation: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'vial',
    },
  },
  setup(props) {
    const caseInteractionStore = useCaseInteractionStore();
    const alertStore = useAlertStore();
    const { currentCaseInteractionLanguage } = storeToRefs(caseInteractionStore);

    const inputText = ref('');
    const tooltipTextArea = ref(null);
    const tooltipWrappingDiv = ref(null);

    const state = reactive({
      tooltip: null,
      tooltipFixed: false,
      tooltipOpen: false,
    });

    onMounted(async () => {});
    return {
      currentCaseInteractionLanguage,
      alertStore,
      inputText,
      tooltipTextArea,
      tooltipWrappingDiv,
      state,
    };
  },
  methods: {
    faPaperPlane() {
      return faPaperPlane;
    },
    async handleToggled() {
      this.$emit('onToggle');
    },
  },
};
</script>

<template>
  <div
    class="inline-flex justify-center items-center mt-1 pt-1 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
  >
    <!-- Popover -->
    <div :id="`${id}-toggle`">
      <button
        type="button"
        @mousedown="handleToggled"
        class="flex-shrink-0 justify-center items-center h-12 w-12 md:h-16 md:w-16 lg:h-16 lg:w-20"
      >
        <div class="items-center hover:scale-100">
          <!--                          <span class="hidden lg:block">-->
          <div class="material-symbols-outlined text-4xl md:-mt-2 select-none">
            {{ icon }}
          </div>

          <div class="items-center hidden lg:block -mt-2 text-sm select-none">{{ label }}</div>
          <div class="items-center hidden md:block -mt-2 lg:hidden text-sm select-none">
            {{ !!labelAbbreviation ? labelAbbreviation : label }}
          </div>
        </div>
      </button>
    </div>

    <!-- End Popover -->
  </div>
</template>

<style></style>
