<script setup lang="ts">
import ProfileImage from '@/components/ProfileImage.vue';
import { useAuthStore, useOrganizationStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { computed, onMounted, watch } from 'vue';
import ProgressButton from '../ProgressButton.vue';
import { feedbackIntegration } from '@sentry/vue';

const authStore = useAuthStore();
const organizationStore = useOrganizationStore();
const { user } = storeToRefs(authStore);
const { isSignedIn } = storeToRefs(authStore);

const { organization } = storeToRefs(organizationStore);

function handleLogout() {
  authStore.logout();
}

const isOrgManager = computed(() => {
  if (!isSignedIn.value) {
    return false;
  }
  return !!organization.value;
});

onMounted(async () => {
  if (!isSignedIn.value) {
    return;
  }
  await organizationStore.loadOrganization();
});

watch(
  () => isSignedIn,
  async () => {
    if (!isSignedIn.value) {
      return;
    }
    await organizationStore.loadOrganization();
  },
  { immediate: true },
);

async function openReportBugModal() {
  const feedback = feedbackIntegration({
    // Disable injecting the default widget
    autoInject: false,
    theme: 'light',
    useSentryUser: {
      name: 'fullName',
      email: 'email',
    },
    themeLight: {
      accentBackground: '#2563eb',
    },
    themeDark: {
      accentBackground: '#2563eb',
    },
  });

  const form = await feedback.createForm();
  form.appendToDom();
  form.open();
}
</script>

<template>
  <!-- <div class="pt-3 md:pt-0" v-if="!isSignedIn">
    <router-link
      class="inline-flex justify-center items-center gap-x-2 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-white text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-2.5 dark:focus:ring-offset-gray-800"
      to="/account/sign-in"
    >
      <span class="material-symbols-outlined">person</span>
      {{ $t('message.signIn') }}
    </router-link>
  </div> -->

  <ProgressButton
    v-if="!isSignedIn"
    routerLinkTo="/account/sign-in"
    color="blue"
    size="sm"
    :text="$t('message.signIn')"
  ></ProgressButton>

  <div class="hs-dropdown relative inline-flex pr-1" v-else>
    <button
      id="hs-dropdown-custom-trigger"
      type="button"
      class="hs-dropdown-toggle py-1 pl-1 pr-2 inline-flex justify-center items-center gap-1 rounded-full border bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-neutral-800 dark:hover:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
    >
      <ProfileImage
        :image="authStore.userProfileImageSmall"
        :initials="authStore.userInitials"
        :showIngameLevel="true"
      />
      <span
        class="material-symbols-outlined text-2xl block hs-dropdown-open:rotate-180 transition-all transform duration-300 ease-in-out"
      >
        expand_more
      </span>
    </button>

    <div
      class="hs-dropdown-menu transition-[opacity,margin] duration-300 hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[15rem] bg-white shadow-md rounded-lg p-2 mt-2 divide-y divide-gray-200 dark:bg-neutral-800 dark:border dark:border-gray-700 dark:divide-gray-700"
      aria-labelledby="hs-dropdown-with-title"
    >
      <div class="py-2 first:pt-0 last:pb-0">
        <span class="block py-2 px-3 text-xs uppercase text-gray-400 dark:text-gray-500"> Einstellungen </span>
        <router-link
          to="/profile"
          class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
        >
          <span class="material-symbols-outlined"> manage_accounts </span>
          {{ $t('message.profile') }}
        </router-link>

        <router-link
          v-show="isOrgManager"
          to="/dashboard"
          class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
        >
          <span class="material-symbols-outlined"> team_dashboard </span>
          {{ $t('message.dashboard') }}
        </router-link>
      </div>

      <div class="py-2 first:pt-0 last:pb-0">
        <a
          @click="openReportBugModal"
          class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 cursor-pointer"
        >
          <span class="material-symbols-outlined"> bug_report </span>
          {{ $t('message.reportABug') }}
        </a>
        <a
          @click="handleLogout"
          class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-blue-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 cursor-pointer"
        >
          <span class="material-symbols-outlined"> logout </span>
          {{ $t('message.signOut') }}
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
