<script setup lang="ts">
import { onMounted, ref } from 'vue';
import MainHeader from '@/components/headers/MainHeader.vue';
import PartnerCarousel from '@/views/landing/PartnerCarousel.vue';
import PublicFooter from '@/views/landing/PublicFooter.vue';

const imgRuediger = ref();
const imgFrederic = ref();
const imgPatient = ref();
const imgTeam = ref();

onMounted(() => {
  import('@/assets/images/brand-illustrations/nurse_team.png').then((img) => {
    imgTeam.value = img.default;
  });
  import('@/assets/images/brand-illustrations/patient_nurse.png').then((img) => {
    imgPatient.value = img.default;
  });
  import('@/assets/images/about-us/ruediger.jpg').then((img) => {
    imgRuediger.value = img.default;
  });
  import('@/assets/images/about-us/frederic.jpg').then((img) => {
    imgFrederic.value = img.default;
  });
});
</script>

<template>
  <div class="w-full max-w-full h-screen bg-gray-50 dark:bg-neutral-800">
    <header
      class="sticky top-0 flex flex-wrap md:justify-start md:flex-nowrap z-40 w-full border-b dark:border-none border-gray-200 bg-white text-sm py-3 md:py-0 dark:bg-neutral-900"
    >
      <MainHeader
        :showRootLink="true"
        :showHomeLink="true"
        :showNewCaseDropdown="false"
        :showPatientInteractionListDropdown="false"
        :showCourseInteractionsLink="false"
        :showChallengesAndEventsLink="false"
        :showCommunityLink="false"
        :showAboutUsLink="false"
        :showSignInOrEditProfile="true"
      />
    </header>

    <div class="overflow-auto w-full h-screen bg-gray-50 dark:bg-neutral-800">
      <main class="h-fit min-h-fit overflow-visible flex">
        <div class="flex-col flex overflow-visible min-h-fit max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <!-- Title -->
          <div class="flex-col flex overflow-visible min-h-fit max-w-2xl mx-auto text-center mb-10 lg:mb-14">
            <!-- Content -->
            <div class="flex-col flex overflow-visible min-h-fit h-fit space-y-5 md:space-y-8">
              <h2 class="text-2xl font-bold md:text-3xl dark:text-white pt-10">
                Einander <span class="text-blue-600">verstehen</span>
              </h2>

              <p class="text-lg text-gray-800 dark:text-neutral-200 text-justify">
                Statt auf Station 'ins kalte Wasser geworfen zu werden', können nichtmuttersprachliche Pflegekräfte mit
                casuu in einem geschützten Raum, vorm Einsteig im neuen Team und doch praxisnah Fähigkeiten und
                Sprachkenntnisse verbessern.
              </p>

              <p class="text-lg text-gray-800 dark:text-neutral-200 text-justify">
                Das gibt das Selbstvertrauen und die Routine, um sich im neuen Umfeld sicher zu bewegen. So stärkt man
                nicht nur die neuen Teammitglieder: Man stärkt das Team aus Ärzt:innen, Pflegekräften und Angehörigen
                anderer Berufsgruppen insgesamt.
              </p>

              <blockquote class="text-center p-4 sm:px-7">
                <p
                  class="text-xl font-medium text-gray-800 md:text-2xl md:leading-normal xl:text-2xl xl:leading-normal dark:text-neutral-200"
                >
                  Pflegekräfte wissen, wie es den Patienten geht. Sagen dir, was sie brauchen und bewegt, begleiten sie
                  durch die Therapie. Für den Arzt sind sie: Augen, Ohren, Stimme
                </p>
                <div class="inline-flex items-center gap-4 mt-5">
                  <div class="text-gray-800 dark:text-neutral-200">
                    <div>
                      <router-link to="/about-us">Rüdiger Schmitz</router-link>
                    </div>
                    <div class="text-sm text-gray-500 dark:text-neutral-500">Arzt & Mitgründer von casuu</div>
                  </div>
                  <div class="flex-shrink-0">
                    <img class="h-16 w-16 rounded-full" :src="imgRuediger" alt="ruediger@casuu.care" />
                  </div>
                </div>
              </blockquote>

              <p class="text-lg text-gray-800 dark:text-neutral-200 text-justify">
                Und: Wer im Team und auf Augenhöhe arbeitet, der arbeitet zufriedener, besser - und der bleibt dabei.
                Keine Kleinigkeit angesichts von Fachrkräftemangel und demographischem Wandel.
              </p>

              <h2 class="text-2xl font-bold md:text-3xl dark:text-white">
                Einander <span class="text-blue-600">stärken</span>
              </h2>
              <p class="text-lg text-gray-800 dark:text-neutral-200 text-justify">
                Krankheit und Krankenhaus bedeuten Stress für den Patienten. Besonders für ältere oder kommunikativ
                eingeschränkte Menschen ist dies keine leichte Situation. Nicht selten fallen ältere Patienten in das
                Idiom und den Dialekt ihrer Kindheit zurück. Das macht die Verständigung mit Nicht-Muttersprachlern
                gerade dann am schwierigsten, wenn der Patient am meisten darauf angewiesen ist: Um Sicherheit zu geben.
                Und um in seinen Werten und seiner Person gesehen zu werden.
              </p>

              <p class="text-lg text-gray-800 dark:text-neutral-200 text-justify">
                Denn auch das ist die Rolle einer Pflegekraft: Sie ist im engsten Austausch mit Patient:innen und
                vermittelt Sorgen, Werte und Wünsche an das Team. Wer Pflegekräfte und Kommunikation stärkt, stärkt
                deshalb Patient:innen.
              </p>

              <blockquote class="text-center p-4 sm:px-7">
                <p
                  class="text-xl font-medium text-gray-800 md:text-2xl md:leading-normal xl:text-2xl xl:leading-normal dark:text-neutral-200"
                >
                  Auch das kann Unternehmertum sein:<br />Menschen nachhaltig zum Helfen befähigen
                </p>
                <div class="inline-flex items-center gap-4 mt-5">
                  <div class="text-gray-800 dark:text-neutral-200">
                    <div>
                      <router-link to="/about-us">Frederic Madesta</router-link>
                    </div>
                    <div class="text-sm text-gray-500 dark:text-neutral-500">
                      KI-Forscher & Mitgründer von casuu |
                      <a
                        href="https://www.oberhessen-live.de/2018/06/25/eine-mitfahr-app-extra-fuer-schueler-aus-dem-vogelsberg/"
                        class="hover:text-gray-400"
                        >Social Entrepreneur @fairfahrt</a
                      >
                    </div>
                  </div>
                  <div class="flex-shrink-0">
                    <img class="h-16 w-16 rounded-full" :src="imgFrederic" alt="frederic@casuu.care" />
                  </div>
                </div>
              </blockquote>

              <!--          <figure>-->
              <!--            <img class="w-full object-cover rounded-xl" :src="imgTeam"/>-->
              <!--            <figcaption class="mt-3 text-sm text-center text-gray-500 dark:text-neutral-500">-->
              <!--              Durch virtuelle Patientenfälle Routine holen: Das stärkt-->
              <!--              interprofessionelle und internationale Teams - und die vulnerabelsten Patient:innen.-->
              <!--            </figcaption>-->
              <!--          </figure>-->

              <h2 class="text-2xl font-bold md:text-3xl dark:text-white">
                Miteinander <span class="text-blue-600">wachsen</span>
              </h2>

              <p class="text-lg text-gray-800 dark:text-neutral-200 text-justify">
                Packen Sie mit uns an! Damit casuu wächst und nachhaltig wirkt. Ob in unserem
                <a href="/patient-advisory-board" class="hover:text-gray-400">Patient:innenbeirat</a> oder
                <a href="/nurse-expert-board" class="hover:text-gray-400">Expertenbeirat Pflege</a>
                , als
                <a href="/about-us" class="hover:text-gray-400">neuestes Teammitglied</a> oder als institutioneller
                Partner. Wir freuen uns auf und über Ihre Nachricht unter
                <a class="text-blue-600 hover:text-blue-700 dark:text-blue 800" href="mailto:hello@casuu.care"
                  >hello@casuu.care</a
                >.
              </p>
            </div>
            <!-- End Content -->
          </div>
          <!-- End Grid -->
        </div>
        <!-- End Team -->
      </main>

      <footer class="flex-none pb-28">
        <div class="overflow-hidden pb-10 lg:pb-14 items-center">
          <PartnerCarousel
            ref="partnerCarouselAtValues"
            :show-a-i-hub="false"
            :show-and-you="true"
            :show-patient-advisory-board="false"
            :show-microsoft="true"
            :show-awo-bielefeld="true"
            :max-pages-show="1"
            :interval="2000"
          />
        </div>
      </footer>

      <PublicFooter />
    </div>
  </div>
</template>

<style scoped></style>
