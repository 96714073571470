<script setup lang="ts">
import { router } from '@/router';
import DropDownListItem from '../DropDownListItem.vue';
import { useAuthStore } from '@/stores';

const authStore = useAuthStore();
async function onMyCasesClick() {
  await router.isReady();
  await router.push('/users/' + authStore.userId + '/case-interactions');
}
</script>

<template>
  <div
    class="hs-dropdown-menu transition-[opacity,margin] duration-300 hs-dropdown-open:opacity-100 opacity-0 hidden z-10 top-full min-w-[15rem] bg-white md:shadow-2xl rounded-lg py-2 md:p-4 space-y-0.5 mt-2 dark:bg-neutral-800 dark:divide-gray-700 before:absolute before:-top-5 before:left-0 before:h-5"
  >
    <div class="md:grid grid-cols-1 gap-4">
      <div class="flex flex-col mx-1 md:mx-0">
        <DropDownListItem
          :header="$t('message.myCases')"
          :description="$t('message.myCasesDescription')"
          :onClick="onMyCasesClick"
          icon="person"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
