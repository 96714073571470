<script>
// import lamejs from 'lamejs';  # v1.2.1: dependency not working. Known issue.

import { getApiClient } from '@/apiclient/client';
import { RecordRTCPromisesHandler } from 'recordrtc';
import { storeToRefs } from 'pinia';

import { useCaseInteractionStore } from '@/stores/caseInteraction.store';
import { useAlertStore } from '@/stores';
import { ref } from 'vue';

import { SoundMeter } from '/src/audio/soundmeter.js';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const AUDIO_TYPE = 'audio';

export default {
  components: { FontAwesomeIcon },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    reset: {
      // signals that the parent component wants to reset the transcription to await new input (e.g. submitted)
      default: false,
      type: Boolean,
    },
    mockProgress: {
      default: 0,
      type: Number,
    },
  },
  setup() {
    const caseInteractionStore = useCaseInteractionStore();
    const alertStore = useAlertStore();
    const { currentCaseInteractionLanguage } = storeToRefs(caseInteractionStore);

    const volume = ref(0);

    return {
      currentCaseInteractionLanguage,
      alertStore,
      volume,
    };
  },
  data() {
    return {
      recorder: null,
      stream: null,
      isRecording: false,
      isStopped: true,
      isPaused: false,
      transcribedText: '',
      pttStartTime: null,
      pttStopTime: null,
      minPttDuration: 500, // in ms
    };
  },
  methods: {
    faMicrophone() {
      return faMicrophone;
    },
    async toggleRecording() {
      if (this.isRecording) {
        await this.stopRecording();
      } else {
        await this.startRecording();
      }
    },
    async startRecording() {
      console.log('START');
      try {
        this.transcribedText = '';
        this.pttStartTime = new Date();
        this.isRecording = true;
        this.isStopped = false;
        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.recorder = new RecordRTCPromisesHandler(this.stream, {
          type: AUDIO_TYPE,
        });
        this.recorder.startRecording();

        try {
          window.AudioContext = window.AudioContext || window.webkitAudioContext;
          window.audioContext = new AudioContext();
        } catch (e) {
          alert('Web Audio API not supported.');
        }

        this.trackVolume(this.stream);
      } catch (error) {
        this.isRecording = false;
        this.isStopped = true;
        throw new Error(`Error starting recording: ${error.message}`);
      }
      console.log('Recorder: ', this.recorder);
      console.log('As str: ', JSON.stringify(this.recorder));
      console.log('Stream', this.stream);
      console.log('As str: ', JSON.stringify(this.stream));
    },
    trackVolume(stream) {
      // Put variables in global scope to make them available to the
      // browser console.
      const parent = this;
      window.stream = stream;
      const soundMeter = (window.soundMeter = new SoundMeter(window.audioContext));
      soundMeter.connectToSource(stream, function (e) {
        if (e) {
          alert(e);
          return;
        }
        let meterRefresh;
        meterRefresh = setInterval(() => {
          if (!window.soundMeter) {
            clearInterval(meterRefresh);
            return;
          }
          let vol = Number(soundMeter.instant.toFixed(2));
          // conver to number
          parent.volume = vol;
          // slowMeter.value = slowValueDisplay.innerText =
          //     soundMeter.slow.toFixed(2);
          // clipMeter.value = clipValueDisplay.innerText =
          //     soundMeter.clip;
        }, 50);
      });
    },
    async stopRecording() {
      console.log('STOP');
      this.isRecording = false;
      this.isStopped = true;
      this.isPaused = false;
      this.pttStopTime = new Date();
      this.volume = 0.0;

      if (!this.recorder) {
        throw new Error('Cannot stop recording: no recorder');
      }
      try {
        await this.recorder.stopRecording();
        const blob = await this.recorder.getBlob();

        this.stream?.getTracks().forEach((track) => {
          track.stop();
        });
        this.recorder = null;
        this.stream = null;

        window.soundMeter.stop();
        window.soundMeter = null;
        await window.audioContext.close();

        if (this.pttStopTime - this.pttStartTime < this.minPttDuration) {
          console.log('Recording too short. Not sending.');
          this.alertStore.info('Mikrofon-Knopf gedrückt halten, um zu sprechen.');
          return;
        }

        await this.sendToBackend(blob, this.currentCaseInteractionLanguage);
      } catch (error) {
        this.isRecording = false;
        this.isStopped = true;
        throw new Error(`Error stopping recording: ${error.message}`);
      }
    },
    async sendToBackend(blob, case_language) {
      this.transcribedText = await (
        await getApiClient()
      ).stt.speechToText(
        {
          audio: blob,
        },
        case_language,
      );
      if (
        this.transcribedText === 'Untertitel der Amara.org-Community' ||
        this.transcribedText === 'Untertitel im Auftrag des ZDF für funk, 2017' ||
        this.transcribedText === 'Untertitelung aufgrund der Audioqualität nicht möglich'
      ) {
        console.warn('Transcription failed. Sending empty string.');
        this.transcribedText = '';
      }
      if (this.transcribedText.length > 0) {
        await this.$emit('newTranscription', this.transcribedText);
      }
    },
  },
  watch: {
    reset: {
      handler: function (newVal, oldVal) {
        console.debug('Resetting audio recorder');
        if (newVal) {
          this.transcribedText = '';
          this.$emit('resetComplete'); // notify parent that reset is complete
        }
      },
    },
  },
  computed: {
    showHalo() {
      // Determine whether to show the halo based on volume
      return this.isRecording && this.volume > 0;
    },
    haloSize() {
      // Calculate halo size based on volume
      return `${Math.min(184, 112 + (156 - 112) * (this.volume * 10))}px`;
      // return `${Math.min(92, 56 + (78 - 56) * (this.volume * 10))}px`;
    },
    haloScale() {
      return `${Math.min(1.4, 1 + this.volume * 10)}`;
    },
  },
};
</script>

<template>
  <!-- Vol: {{ volume }}. -->
  <div
    class="group relative flex z-[90] items-center justify-center opacity-100 rounded-full h-32 w-32 lg:h-44 lg:w-44 select-none"
    @click.prevent=""
  >
    <div
      class="absolute flex z-[90] items-center justify-center bg-red-400/50 rounded-full h-24 w-24 lg:h-32 lg:w-32"
      :style="{ transform: `scale(${haloScale})` }"
    >
      <div class="grid" :style="{ transform: `scale(${1.1 / haloScale})` }">
        <div class="col-start-1 row-start-1 relative h-24 w-24 lg:h-32 lg:w-32 items-center justify-center">
          <svg class="h-full w-full" width="40" height="40" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <!-- Background Circle -->
            <circle
              v-show="mockProgress > 0"
              cx="18"
              cy="18"
              r="16"
              fill="none"
              class="stroke-current text-transparent shadow-none shadow-gray-400"
              stroke-width="4"
            ></circle>
            <!-- Progress Circle inside a group with rotation -->
            <g class="origin-center -rotate-90 transform">
              <circle
                v-show="mockProgress > 0"
                cx="18"
                cy="18"
                r="16"
                fill="none"
                class="stroke-current"
                :class="[
                  !disabled // if so: grey. Else: blue
                    ? !transcribedText // If filled: light blue. If not: dark blue (not recording) or red (recording)
                      ? isRecording
                        ? 'text-red-500 group-hover:text-red-600 shadow-inner shadow-none shadow-red-400 '
                        : 'text-blue-600 group-hover:text-blue-600 shadow-none shadow-gray-400  '
                      : 'text-blue-300 group-hover:text-blue-300 shadow-none shadow-gray-400  '
                    : 'text-gray-500 group-hover:text-gray-400 focus:ring-2 focus:ring-blue-600 focus:z-10 focus:outline-none shadow-none shadow-gray-400',
                ]"
                stroke-width="4"
                stroke-dasharray="100"
                :stroke-dashoffset="100 - mockProgress"
              ></circle>
            </g>
          </svg>
        </div>
        <!--        :style="{ width: haloSize, height: haloSize }"-->
        <button
          @click.prevent=""
          @mousedown="startRecording"
          @mouseup="stopRecording"
          @touchstart="startRecording"
          @touchend="stopRecording"
          type="button"
          :style="{ transform: `scale(${1 / 1.1})` }"
          class="row-start-1 col-start-1 flex border-none flex-shrink-0 opacity-100 justify-center items-center h-24 w-24 lg:h-32 lg:w-32 rounded-full text-white"
          :class="[
            !disabled // if so: grey. Else: blue
              ? !transcribedText // If filled: light blue. If not: dark blue (not recording) or red (recording)
                ? isRecording
                  ? 'bg-red-500 group-hover:bg-red-600 shadow-inner shadow-none shadow-red-400 '
                  : 'bg-blue-600 group-hover:bg-blue-700 shadow-none shadow-gray-400  '
                : 'bg-blue-300 group-hover:bg-blue-300 shadow-none shadow-gray-400  '
              : 'bg-gray-500 group-hover:bg-gray-400 group-focus:ring-2 group-focus:ring-blue-600 focus:z-10 group-focus:outline-none shadow-none shadow-gray-400',
            mockProgress > 20 ? 'shadow-none' : '',
          ]"
        >
          <div class="items-center opacity-100 group-hover:scale-100">
            <div class="items-center opacity-100">
              <!--            <svg-->
              <!--              xmlns="http://www.w3.org/2000/svg"-->
              <!--              width="128"-->
              <!--              height="64"-->
              <!--              fill="currentColor"-->
              <!--              class="bi bi-mic-fill"-->
              <!--              viewBox="0 0 16 16"-->
              <!--            >-->
              <!--              <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0z" />-->
              <!--              <path-->
              <!--                d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5"-->
              <!--              />-->
              <!--            </svg>-->

              <div class="material-symbols-outlined text-8xl md:text-7xl lg:text-8xl lg:-mt-2 select-none">mic</div>

              <!--              <FontAwesomeIcon :icon="faMicrophone()" class="fa-4x" :beat="isRecording" />-->
            </div>
            <div class="items-center -mt-4 mb-2 hidden lg:block text-sm select-none">Push to talk</div>
            <div class="items-center -mt-2 mb-2 hidden md:block lg:hidden text-sm select-none">PTT</div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
@keyframes pulse {
  0% {
    opacity: 0.95;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.95;
  }
}

.animate-pulse {
  animation: pulse 50s infinite;
}
</style>
