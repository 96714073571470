<!-- DONE: Prio 1: Neuen Fall anlagen -->
<!-- TODO: Prio 2: EA ward round = zufälligen Fall wählen und starten -->

<script setup lang="ts">
import { router } from '@/router';
import DropDownListItem from '../DropDownListItem.vue';

async function createNewCase() {
  await router.push('/new-case');

  await router.go(0);
  // reload page - otherwise, this https://github.com/nuxt/nuxt/issues/13309 is fired when new case is hit after case
  // is started. Error is fired only in this case. Not when on home window without started case.
  // TODO: fix this and let the user return to the case window from the new case window thru the browser back button
}

async function toEmergencyAdmission() {
  await router.push('/wartebereich-zna');
  await router.go(0);
}
</script>

<template>
  <div
    class="hs-dropdown-menu transition-[opacity,margin] duration-300 hs-dropdown-open:opacity-100 opacity-0 hidden z-10 top-full min-w-[15rem] bg-white md:shadow-2xl rounded-lg py-2 md:p-4 space-y-0.5 mt-2 dark:bg-neutral-800 dark:divide-gray-700 before:absolute before:-top-5 before:left-0 before:h-5"
  >
    <div class="md:grid grid-cols-1 gap-4">
      <div class="flex flex-col mx-1 md:mx-0">
        <!-- Spielmodus Notaufnahme -->

        <DropDownListItem
          :header="$t('message.emergencyAdmissionCase')"
          :description="$t('message.emergencyAdmissionCaseDescription')"
          :onClick="toEmergencyAdmission"
          icon="ecg_heart"
        />

        <!-- <DropDownListItem
          header="Best Of (coming soon...)"
          description="Löse einen der beliebtesten casuus"
          icon="grade"
        />

        <DropDownListItem
          header="Tough Nut (coming soon...)"
          description="Löse einen der schwierigsten casuus"
          icon="cognition"
        /> -->

        <DropDownListItem
          :header="$t('message.createNewCase')"
          :description="$t('message.createNewCaseDescription')"
          :onClick="createNewCase"
          icon="person_add"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
