<script setup lang="ts">
const emit = defineEmits(['startMc', 'startOral', 'startTranslateVocab', 'startExplainVocab']);

async function onMc() {
  console.debug('onMc');
  emit('startMc');
}

function onOral() {
  console.debug('onOral');
  emit('startOral');
}

function onTranslateVocab() {
  console.debug('onTranslateVocab');
  emit('startTranslateVocab');
}

function onExplainVocab() {
  console.debug('onExplainVocab');
  emit('startExplainVocab');
}
</script>

<template>
  <div
    ref="quizDropdown"
    id="quiz-dropdown"
    class="hs-dropdown-menu transition-[opacity,margin] duration-300 hs-dropdown-open:opacity-100 opacity-0 hidden z-10 top-full min-w-[15rem] bg-white md:shadow-2xl rounded-lg py-2 md:p-4 space-y-0.5 mt-2 dark:bg-neutral-800 dark:divide-gray-700 before:absolute before:-top-5 before:left-0 before:h-5"
  >
    <div class="md:grid grid-cols-1 gap-4">
      <div class="flex flex-col mx-1 md:mx-0">
        <!--        &lt;!&ndash; Multiple choice &ndash;&gt;-->
        <!--        <a-->
        <!--          class="group flex gap-x-5 text-gray-800 hover:bg-gray-50 rounded-md p-4 dark:text-gray-200 dark:hover:bg-gray-900"-->
        <!--          @click.stop="onMc"-->
        <!--          href="#"-->
        <!--        >-->
        <!--          <svg-->
        <!--            class="flex-shrink-0 w-6 h-6 mt-1"-->
        <!--            xmlns="http://www.w3.org/2000/svg"-->
        <!--            width="16"-->
        <!--            height="16"-->
        <!--            fill="currentColor"-->
        <!--            viewBox="0 0 16 16"-->
        <!--          >-->
        <!--            <path-->
        <!--              d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z"-->
        <!--            />-->
        <!--            <path-->
        <!--              d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"-->
        <!--            />-->
        <!--          </svg>-->
        <!--          <div class="grow">-->
        <!--            <p class="font-medium text-gray-800 dark:text-gray-200">Multiple choice</p>-->
        <!--            <p class="text-sm text-gray-500 group-hover:text-gray-800 dark:group-hover:text-gray-200">-->
        <!--              Beantworte Fragen zu deinem Fall im MC-Stil.-->
        <!--            </p>-->
        <!--          </div>-->
        <!--        </a>-->
        <!--        &lt;!&ndash; End Multiple choice &ndash;&gt;-->

        <!--        &lt;!&ndash; Oral &ndash;&gt;-->
        <!--        <a-->
        <!--          class="group flex gap-x-5 text-gray-800 hover:bg-gray-50 rounded-md p-4 dark:text-gray-200 dark:hover:bg-gray-900"-->
        <!--          @click.stop="onOral"-->
        <!--          href="#"-->
        <!--        >-->
        <!--          <svg-->
        <!--            class="flex-shrink-0 w-6 h-6 mt-1"-->
        <!--            xmlns="http://www.w3.org/2000/svg"-->
        <!--            width="16"-->
        <!--            height="16"-->
        <!--            fill="currentColor"-->
        <!--            viewBox="0 0 16 16"-->
        <!--          >-->
        <!--            <path-->
        <!--              d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"-->
        <!--            />-->
        <!--            <path-->
        <!--              d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"-->
        <!--            />-->
        <!--          </svg>-->
        <!--          <div class="grow">-->
        <!--            <p class="font-medium text-gray-800 dark:text-gray-200">Mündliche</p>-->
        <!--            <p class="text-sm text-gray-500 group-hover:text-gray-800 dark:group-hover:text-gray-200">-->
        <!--              Erhalte und diskutiere offene Fragen zu deinem Fall - ähnlich wie in einer mündlichen Prüfung.-->
        <!--            </p>-->
        <!--          </div>-->
        <!--        </a>-->
        <!--        &lt;!&ndash; End Oral &ndash;&gt;-->

        <!-- Translate Vocabulary -->
        <a
          class="group flex gap-x-5 text-gray-800 hover:bg-gray-50 rounded-md p-4 dark:text-gray-200 dark:hover:bg-gray-900"
          @click.stop="onTranslateVocab"
          href="#"
        >
          <span class="material-symbols-outlined"> dictionary </span>
          <div class="grow">
            <p class="font-medium text-gray-800 dark:text-gray-200">Vokabeln übersetzen</p>
            <p class="text-sm text-gray-500 group-hover:text-gray-800 dark:group-hover:text-gray-200">
              Übe das Übersetzen von fallbezogenen Fachtermini und sprachlichen Wendungen.
            </p>
          </div>
        </a>
        <!-- End Translate Vocabulary -->

        <!-- Explain Vocabulary -->
        <a
          class="group flex gap-x-5 text-gray-800 hover:bg-gray-50 rounded-md p-4 dark:text-gray-200 dark:hover:bg-gray-900"
          @click.stop="onExplainVocab"
          href="#"
        >
          <span class="material-symbols-outlined"> sign_language </span>
          <div class="grow">
            <p class="font-medium text-gray-800 dark:text-gray-200">Fachtermini erläutern</p>
            <p class="text-sm text-gray-500 group-hover:text-gray-800 dark:group-hover:text-gray-200">
              Übe das Erklären und Definieren von fallbezogenen Fachtermini und sprachlichen Wendungen.
            </p>
          </div>
        </a>
        <!-- End Explain Vocabulary -->
      </div>
    </div>
  </div>
</template>

<style scoped></style>
