<script setup lang="ts">
import { useAlertStore, useAuthStore } from '@/stores';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { getLevelFromXp, getThresholdForCurrentLevel, getThresholdForNextLevel } from '@/helper';
import { computed, onMounted, onUnmounted } from 'vue';
import IngameProgressCard from '@/views/home/IngameProgressCard.vue';

const { t, locale } = useI18n();
const props = defineProps({
  includeIngameProgressCardAsTooltip: {
    type: Boolean,
    default: true,
  },
});

const authStore = useAuthStore();
const alertStore = useAlertStore();
const { userXp } = storeToRefs(authStore);

const currentLevel = computed(() => getLevelFromXp(userXp.value));
const progress = computed(
  () =>
    ((userXp.value - getThresholdForCurrentLevel(currentLevel.value)) /
      (getThresholdForNextLevel(currentLevel.value) - getThresholdForCurrentLevel(currentLevel.value))) *
    100,
);

onMounted(async () => {});

onUnmounted(() => {});
</script>

<template>
  <div :class="{ 'hs-tooltip [--placement:bottom] relative': includeIngameProgressCardAsTooltip }">
    <div class="relative size-3.5 hs-tooltip-toggle cursor-pointer">
      <svg
        class="size-full -rotate-90 transition-all duration-2000 ease-in-out transform"
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- Background Circle -->
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="white"
          class="stroke-current text-gray-200 dark:text-neutral-700"
          stroke-width="4"
        ></circle>
        <!-- Progress Circle -->
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          class="stroke-current text-orange-500 dark:text-orange-500"
          stroke-width="4"
          stroke-dasharray="100"
          :stroke-dashoffset="`${100 - progress}`"
          stroke-linecap="round"
        ></circle>
      </svg>

      <div class="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2 flex items-center">
        <span class="text-center text-xs font-bold text-gray-800 dark:text-gray-200">{{ currentLevel }}</span>
      </div>
    </div>
    <span
      v-if="includeIngameProgressCardAsTooltip"
      class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-[50]"
      role="tooltip"
    >
      <IngameProgressCard />
    </span>
  </div>
</template>

<style scoped></style>
