import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

const RESET_AFTER = 7000; // Adjust this value as needed

export const useAlertStore = defineStore({
  id: 'alert',
  state: () => ({
    alerts: [] as Array<{ id: string; message: string; type: 'success' | 'error' | 'info' | 'xp'; title: string }>,
  }),
  actions: {
    success(message: string, title?: string) {
      this.addAlert(message, 'success', title);
    },
    error(message: string, title?: string, error?: Error) {
      this.addAlert(message, 'error', title);
      // Todo: send error to Sentry
    },
    info(message: string, title?: string) {
      this.addAlert(message, 'info', title);
    },
    xp(message: string, title?: string) {
      this.addAlert(message, 'xp', title);
    },
    addAlert(message: string, type: 'success' | 'error' | 'info' | 'xp', title?: string) {
      const id = uuidv4();
      // if title is not provided, use the type as title
      if (!title) {
        title = 'status.' + type + '.alertTitle';
      }
      this.alerts.unshift({ id, message, type, title });
      setTimeout(() => {
        this.clearAlert(id);
      }, RESET_AFTER);
    },
    clearAlert(id: string) {
      this.alerts = this.alerts.filter((alert) => alert.id !== id);
    },
    clear() {
      this.alerts.length = 0;
    },
  },
});
