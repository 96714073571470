<script setup>
import { ref, computed, reactive, onMounted, defineProps, onBeforeUnmount } from 'vue';
import { router } from '@/router';
import {
  useAuthStore,
  usePatientInteractionStore,
  useAttendingInteractionStore,
  useOralExamInteractionStore,
  useMcExamInteractionStore,
  useCaseInteractionStore,
} from '@/stores';
import { storeToRefs } from 'pinia';

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

import SolveModal from '@/components/SolveModal.vue';
import { VocabMode } from '@/helper';
import ReportModal from '@/components/ReportModal.vue';
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';
import TwoColumnLayout from '@/components/TwoColumnLayout.vue';
import ChatBubbleSystem from '@/components/chat_bubbles/ChatBubbleSystem.vue';
import { is } from '@vee-validate/rules';
import { useI18n } from 'vue-i18n';
import ProgressButton from '@/components/ProgressButton.vue';

const { t } = useI18n();

const authStore = useAuthStore();
const patientInteractionStore = usePatientInteractionStore();
const attendingInteractionStore = useAttendingInteractionStore();
const oralExamInteractionStore = useOralExamInteractionStore();
const mcExamInteractionStore = useMcExamInteractionStore();
const caseInteractionStore = useCaseInteractionStore();
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const { user } = storeToRefs(authStore);

const { descMessages, descIsStreaming } = storeToRefs(patientInteractionStore);

const explainMeChatHandle = ref();
const oralExamHandle = ref();
const mcExamHandle = ref();
const solveModalHandle = ref();
const translateVocabHandle = ref();
const explainVocabHandle = ref();
const reportHandle = ref();
const thirdPersonHandles = ref([]);

const isLoading = ref(true);
const userIsReady = ref(false);
const loadingMessage = ref(t('message.loading'));
const originChapterId = ref('');

onMounted(async () => {
  await router.isReady();
  const caseInteractionId = router.currentRoute.value.params.caseInteractionId;
  if (!!router.currentRoute.value.query.originChapterId) {
    console.log(
      'CaseInteraction: originChapterId found in query params: ' + router.currentRoute.value.query.originChapterId,
    );
    originChapterId.value = router.currentRoute.value.query.originChapterId;
  } else {
    console.log('CaseInteraction: No originChapterId found in query params');
  }

  // TODO HTTP 404 if caseInteractionId is not found

  if (
    caseInteractionId !== patientInteractionStore.currentCaseInteractionId ||
    caseInteractionId !== attendingInteractionStore.currentCaseInteractionId ||
    caseInteractionId !== oralExamInteractionStore.currentCaseInteractionId ||
    caseInteractionId !== mcExamInteractionStore.currentCaseInteractionId ||
    caseInteractionId !== caseInteractionStore.currentCaseInteractionId ||
    caseInteractionId !== thirdPersonInteractionStore.currentCaseInteractionId
  ) {
    console.debug('case interaction id mismatch, refreshing stores');

    // refresh stores

    // TODO: proper reset functions !
    await Promise.all([
      caseInteractionStore.reset(),
      patientInteractionStore.reset(),
      attendingInteractionStore.reset(),
      oralExamInteractionStore.reset(),
      mcExamInteractionStore.reset(),
      thirdPersonInteractionStore.reset(),
    ]);

    await Promise.all([
      caseInteractionStore.setCaseInteractionById(caseInteractionId),
      patientInteractionStore.setCaseInteractionById(caseInteractionId),
      attendingInteractionStore.setCaseInteractionById(caseInteractionId),
      oralExamInteractionStore.setCaseInteractionById(caseInteractionId),
      mcExamInteractionStore.setCaseInteractionById(caseInteractionId),
      thirdPersonInteractionStore.setCaseInteractionById(caseInteractionId),
    ]);

    // disable audio autoplay, enable it later if user is ready and clicks on next
    caseInteractionStore.disableAudioAutoplay();
  }

  await loadHistoryOrInitNewInteraction();

  console.log('third persons ', JSON.stringify(thirdPersonInteractionStore.currentThirdPersons));
  console.log('# third persons ', thirdPersonInteractionStore.currentThirdPersons.length);
  for (let i = 0; i < thirdPersonInteractionStore.currentThirdPersons.length; i++) {
    thirdPersonHandles.value.push(ref(null));
  }
});

onBeforeUnmount(async () => {
  await Promise.all([
    caseInteractionStore.reset(),
    patientInteractionStore.reset(),
    attendingInteractionStore.reset(),
    oralExamInteractionStore.reset(),
    mcExamInteractionStore.reset(),
    thirdPersonInteractionStore.reset(),
  ]);
});

async function loadHistoryOrInitNewInteraction() {
  // first we check if there is a history for patient or third person interaction
  let historyLoaded =
    (await patientInteractionStore.fetchHistory()) || (await thirdPersonInteractionStore.fetchHistories());
  console.debug('history available and loaded: ' + historyLoaded);
  if (!historyLoaded) {
    // no history found, so we init the interaction and start streaming
    await caseInteractionStore.goToTask(0, true);
  } else {
    // we have a history, so we enable audio autoplay and forward the user directly to the case interaction
    caseInteractionStore.setWaitingForResponse(false);
    await caseInteractionStore.enableAudioAutoplay();
    userIsReady.value = true;
  }
  isLoading.value = false;
}

async function onTrySolve() {
  console.debug('try solve hit');

  // open modal
  const test = await solveModalHandle.value.pauseForMetaChat(caseInteractionStore.currentObserverInteractionId);
  console.debug('try solve closed with answer ' + test);
}

async function onWriteReport() {
  console.debug('report writing started');

  const test = await reportHandle.value.pauseForMetaChat();
  console.debug('report modal closed with answer ' + test);
}

/**
 * Sets the value of `userIsReady` to `true`, indicating that the user is ready to proceed to the case interaction.
 */
function proceedToCaseInteraction() {
  userIsReady.value = true;
  caseInteractionStore.enableAudioAutoplay();
}
</script>

<template>
  <div
    v-if="isLoading || !userIsReady"
    class="fixed top-0 left-0 w-screen flex items-center justify-center"
    :style="{ height: `calc(100dvh - ${outerHeaderHeight}px)` }"
  >
    <div v-if="!descMessages.length">
      <div
        class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
        role="status"
        aria-label="loading"
      />
    </div>
    <div v-else class="mt-5 space-y-2 max-w-96">
      <ChatBubbleSystem :message="descMessages[0]" :isStreaming="descIsStreaming" />
      <ProgressButton
        :onclick="proceedToCaseInteraction"
        :text="t('message.next')"
        :showProgress="isLoading"
        type="button"
        :disabled="isLoading"
        class="ml-auto"
      />
    </div>
  </div>
  <div
    v-show="userIsReady"
    class="w-full flex flex-col flex-grow overflow-hidden"
    :style="{ height: `calc(100dvh - ${props.outerHeaderHeight}px)` }"
  >
    <SolveModal ref="solveModalHandle" />
    <!--    <ReportModal ref="reportHandle" overlayId="report-modal" />-->
    <TwoColumnLayout
      @startReport="onWriteReport"
      @trySolve="onTrySolve"
      :outerHeaderHeight="props.outerHeaderHeight"
      :originChapterId="originChapterId"
    />
  </div>
</template>

<style></style>
