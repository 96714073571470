<script setup>
import { useAuthStore, useAlertStore, useLanguageStore, useCountryStore } from '@/stores';
import { ref, onMounted, onBeforeMount, reactive, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { getApiClient } from '@/apiclient/client';
import _ from 'lodash';
import ProgressButton from '@/components/ProgressButton.vue';
import LanguageAndCountryDropdown from '@/components/dropdowns/LanguageAndCountryDropdown.vue';
import { ErrorMessage, configure, useForm, useIsSubmitting } from 'vee-validate';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
const { t, locale } = useI18n();

const router = useRouter();
const alertStore = useAlertStore();
const authStore = useAuthStore();
const languageStore = useLanguageStore();
const countryStore = useCountryStore();

const { languages } = storeToRefs(languageStore);
const { countries } = storeToRefs(countryStore);

const isLoading = ref(true);
const isSubmitting = useIsSubmitting();

const currentStep = ref(0);
const numSteps = 2;

onMounted(async () => {
  await languageStore.loadLanguages();
  await countryStore.loadCountries();
  isLoading.value = false;

  await nextTick(() => {
    HSStaticMethods.autoInit();
  });
});

const { meta, errors, handleSubmit, defineField, resetForm } = useForm({
  validationSchema: {
    firstName: 'required|max:255',
    lastName: 'required|max:255',
    nativeLanguage: 'required',
    ingameLanguage: 'required',
    countryOfOrigin: 'required',
  },
  initialValues: {
    firstName: authStore.userFirstName,
    lastName: authStore.userLastName,
    nativeLanguage: authStore.userNativeLanguage,
    ingameLanguage: authStore.userIngameLanguage,
    countryOfOrigin: authStore.userCountryOfOrigin,
  },
});

const onSubmit = handleSubmit.withControlled(async (values) => {
  console.log(values);

  await authStore
    .updateUserDetails({
      first_name: values.firstName,
      last_name: values.lastName,
      native_language: values.nativeLanguage,
      ingame_language: values.ingameLanguage,
      country_of_origin: values.countryOfOrigin,
    })
    .then(async () => {
      console.log('User details updated. Redirecting to home.');
      router.push('/home');
    })
    .catch((error) => {})
    .finally(() => {
      isSubmitting.value = false;
    });
});

const [firstName, firstNameAttrs] = defineField('firstName');
const [lastName, lastNameAttrs] = defineField('lastName');
const [nativeLanguage, nativeLanguageAttrs] = defineField('nativeLanguage');
const [ingameLanguage, ingameLanguageAttrs] = defineField('ingameLanguage');
const [countryOfOrigin, countryOfOriginAttrs] = defineField('countryOfOrigin');

watch(nativeLanguage, (newValue) => {
  // Set i18n locale
  if (newValue) {
    locale.value = newValue;
  }
});
</script>
<template>
  <main class="w-full h-screen max-w-xl mx-auto p-6">
    <form @submit="onSubmit">
      <div class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-800 dark:border-gray-700">
        <div class="p-4 sm:p-7">
          <!-- Stepper -->
          <div data-hs-stepper="">
            <!-- Stepper Nav -->
            <ul class="relative flex flex-row gap-x-2">
              <li
                class="flex items-center gap-x-2 shrink basis-0 flex-1 group"
                data-hs-stepper-nav-item='{
      "index": 1
    }'
              >
                <span class="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
                  <span
                    class="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-blue-600 hs-stepper-active:text-white hs-stepper-success:bg-blue-600 hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 dark:bg-neutral-700 dark:text-white dark:group-focus:bg-gray-600 dark:hs-stepper-active:bg-blue-500 dark:hs-stepper-success:bg-blue-500 dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-completed:group-focus:bg-teal-600"
                  >
                    <span class="hs-stepper-success:hidden hs-stepper-completed:hidden">1</span>
                    <svg
                      class="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </span>
                  <span class="ms-2 text-sm font-medium text-gray-800 dark:text-neutral-200">{{
                    $t('message.aboutYou')
                  }}</span>
                </span>
                <div
                  class="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-blue-600 hs-stepper-completed:bg-teal-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-blue-600 dark:hs-stepper-completed:bg-teal-600"
                ></div>
              </li>

              <li
                class="flex items-center gap-x-2 shrink basis-0 flex-1 group"
                data-hs-stepper-nav-item='{
      "index": 2
    }'
              >
                <span class="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle">
                  <span
                    class="size-7 flex justify-center items-center flex-shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-blue-600 hs-stepper-active:text-white hs-stepper-success:bg-blue-600 hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600 dark:bg-neutral-700 dark:text-white dark:group-focus:bg-gray-600 dark:hs-stepper-active:bg-blue-500 dark:hs-stepper-success:bg-blue-500 dark:hs-stepper-completed:bg-teal-500 dark:hs-stepper-completed:group-focus:bg-teal-600"
                  >
                    <span class="hs-stepper-success:hidden hs-stepper-completed:hidden">2</span>
                    <svg
                      class="hidden flex-shrink-0 size-3 hs-stepper-success:block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </span>
                  <span class="ms-2 text-sm font-medium text-gray-800 dark:text-neutral-200"
                    >{{ $t('message.country') }} & {{ $t('message.language') }}</span
                  >
                </span>
                <div
                  class="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-blue-600 hs-stepper-completed:bg-teal-600 dark:bg-neutral-700 dark:hs-stepper-success:bg-blue-600 dark:hs-stepper-completed:bg-teal-600"
                ></div>
              </li>
            </ul>
            <!-- End Stepper Nav -->

            <!-- Stepper Content -->
            <div class="mt-5 sm:mt-8">
              <!-- Slide 1 -->
              <div
                data-hs-stepper-content-item='{
      "index": 1
    }'
              >
                <div
                  class="p-4 h-96 bg-gray-50 flex justify-center items-center border border-dashed border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
                >
                  <div class="py-2 sm:py-4 px-2">
                    <div class="p-4 space-y-5">
                      <!-- Grid -->
                      <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                        <div class="sm:col-span-3">
                          <label class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
                            {{ $t('message.name') }}
                          </label>
                        </div>
                        <!-- End Col -->
                        <div class="sm:col-span-9">
                          <div class="space-y-3">
                            <div class="grid grid-cols-12 gap-2">
                              <div class="col-span-6">
                                <input
                                  type="text"
                                  v-model="firstName"
                                  v-bind="firstNameAttrs"
                                  class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                                  :class="{
                                    'border-red-500 focus:border-red-500 focus:ring-red-500': errors.firstName,
                                  }"
                                  :placeholder="$t('message.firstName')"
                                />
                                <p class="text-sm text-red-600 mt-2">{{ errors.firstName }}</p>
                              </div>
                              <div class="col-span-6">
                                <input
                                  type="text"
                                  v-model="lastName"
                                  v-bind="lastNameAttrs"
                                  class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                                  :class="{
                                    'border-red-500 focus:border-red-500 focus:ring-red-500': errors.lastName,
                                  }"
                                  :placeholder="$t('message.lastName')"
                                />
                                <p class="text-sm text-red-600 mt-2">{{ errors.lastName }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- End Col -->
                      </div>
                      <!-- End Grid -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Slide 1 -->

              <!-- Slide 2 -->
              <div
                data-hs-stepper-content-item='{
      "index": 2
    }'
                style="display: none"
              >
                <div
                  class="p-4 h-96 bg-gray-50 flex justify-center items-center border border-dashed border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
                >
                  <div class="py-2 sm:py-4 px-2">
                    <div class="p-4 space-y-5">
                      <!-- Grid -->
                      <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                        <div class="sm:col-span-3">
                          <label class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
                            {{ $t('message.yourLanguage') }}
                          </label>
                        </div>
                        <!-- End Col -->
                        <div class="sm:col-span-9">
                          <div class="space-y-3">
                            <LanguageAndCountryDropdown v-if="!isLoading" v-model="nativeLanguage" :items="languages" />
                            <p class="text-sm text-red-600 mt-2">{{ errors.nativeLanguage }}</p>
                          </div>
                        </div>
                        <!-- End Col -->
                      </div>
                      <!-- End Grid -->

                      <!-- Grid -->
                      <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                        <div class="sm:col-span-3">
                          <label class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
                            {{ $t('message.yourLanguageToLearn') }}
                          </label>
                        </div>
                        <!-- End Col -->
                        <div class="sm:col-span-9">
                          <div class="space-y-3">
                            <LanguageAndCountryDropdown v-if="!isLoading" v-model="ingameLanguage" :items="languages" />
                            <p class="text-sm text-red-600 mt-2">{{ errors.ingameLanguage }}</p>
                          </div>
                        </div>
                        <!-- End Col -->
                      </div>
                      <!-- End Grid -->

                      <!-- Grid -->
                      <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                        <div class="sm:col-span-3">
                          <label class="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
                            {{ $t('message.yourCountryOfOrigin') }}
                          </label>
                        </div>
                        <!-- End Col -->
                        <div class="sm:col-span-9">
                          <div class="space-y-3">
                            <LanguageAndCountryDropdown
                              v-if="!isLoading"
                              v-model="countryOfOrigin"
                              :isCountry="true"
                              :items="countries"
                            />
                            <p class="text-sm text-red-600 mt-2">{{ errors.countryOfOrigin }}</p>
                          </div>
                        </div>
                        <!-- End Col -->
                      </div>
                      <!-- End Grid -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Slide 2 -->

              <!-- Button Group -->
              <div class="mt-5 flex justify-between items-center gap-x-2">
                <button
                  type="button"
                  class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                  data-hs-stepper-back-btn=""
                  @click.capture="currentStep--"
                >
                  <svg
                    class="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="m15 18-6-6 6-6"></path>
                  </svg>
                  {{ $t('message.back') }}
                </button>
                <button
                  type="button"
                  class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                  data-hs-stepper-next-btn=""
                  @click.capture="currentStep++"
                >
                  {{ $t('message.next') }}
                  <svg
                    class="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="m9 18 6-6-6-6"></path>
                  </svg>
                </button>
                <button
                  class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                  style="display: block"
                  v-show="currentStep === numSteps - 1"
                >
                  <!-- removed data-hs-stepper-finish-btn="" -->
                  {{ $t('message.complete') }}
                </button>
              </div>
              <!-- End Button Group -->
            </div>
            <!-- End Stepper Content -->
          </div>
          <!-- End Stepper -->
        </div>
      </div>
    </form>
  </main>
</template>
