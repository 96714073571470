<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import ProfileImage from '@/components/ProfileImage.vue';
import { HSDropdown, HSStaticMethods } from 'preline';
import Badge from '@/components/Badge.vue';
import { useAlertStore, useOrganizationStore } from '@/stores';
const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  firstName: {
    type: String,
    default: '',
  },
  lastName: {
    type: String,
    default: '',
  },
  nativeLanguage: {
    type: String,
    default: '',
  },
  email: {
    type: String,
    default: '',
  },
  isManager: {
    type: Boolean,
    default: false,
  },
  isActive: {
    type: Boolean,
    default: true,
  },
  isCurrentUser: {
    type: Boolean,
    default: false,
  },
});

const roleBadges = computed(() => {
  // Return an array of badges
  const badges = [];
  if (props.isManager) {
    badges.push({
      text: 'Manager',
      color: 'blue',
      icon: 'manage_accounts',
    });
  } else {
    badges.push({
      text: 'Benutzer',
      color: 'green',
      icon: 'person',
    });
  }
  return badges;
});
const activeBadge = computed(() => {
  // Return an array of badges
  let badge;
  if (props.isActive) {
    badge = {
      text: 'Ja',
      color: 'green',
      icon: 'check',
    };
  } else {
    badge = {
      text: 'Nein',
      color: 'gray',
      icon: 'close',
    };
  }
  return badge;
});

const dropdownId = computed(() => {
  return 'dropdown-' + props.id;
});

onMounted(async () => {
  await nextTick(() => {
    HSStaticMethods.autoInit();
  });
});

const organizationStore = useOrganizationStore();

function deactivateMember(userId) {
  console.log('deactivate member', userId);
  organizationStore.deactivateMember(userId);
}

function activateMember(userId) {
  organizationStore.activateMember(userId);
}

function toggleActive(userId) {
  if (props.isActive) {
    deactivateMember(userId);
  } else {
    activateMember(userId);
  }
}
</script>

<template>
  <tr class="divide-x divide-gray-200 dark:divide-neutral-700">
    <td class="size-px whitespace-nowrap px-4 py-1 relative group">
      <div class="w-full flex items-center gap-x-3">
        <ProfileImage :firstName="props.firstName" :lastName="props.lastName" :showIngameLevel="true" />
        <div class="grow">
          <span class="text-sm font-medium text-gray-800 dark:text-neutral-200">
            {{ props.firstName }} {{ props.lastName }}{{ props.isCurrentUser ? ' (Ich)' : '' }}
          </span>
        </div>
      </div>
    </td>
    <td class="size-px whitespace-nowrap px-4 py-1">
      <span class="text-sm text-gray-600 dark:text-neutral-400">
        {{ props.nativeLanguage }}
      </span>
    </td>
    <td class="size-px whitespace-nowrap px-4 py-1">
      <Badge v-for="badge in roleBadges" :text="badge.text" :color="badge.color" :icon="badge.icon" />
    </td>

    <td class="size-px whitespace-nowrap px-4 py-1">
      <Badge :text="activeBadge.text" :color="activeBadge.color" :icon="activeBadge.icon" />
    </td>

    <td class="size-px whitespace-nowrap px-4 py-1">
      <span class="text-sm text-gray-600 dark:text-neutral-400">
        {{ props.email }}
      </span>
    </td>

    <td class="size-px whitespace-nowrap px-4 py-1">
      <!-- Dropdown -->
      <div v-if="!props.isCurrentUser" class="hs-dropdown relative inline-flex [--placement:bottom-right]">
        <button
          :id="dropdownId"
          type="button"
          class="size-7 inline-flex justify-center items-center gap-x-2 rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
        >
          <span class="material-symbols-outlined">more_vert</span>
        </button>
        <div
          class="hs-dropdown-menu z-10 hs-dropdown-open:opacity-100 w-60 transition-[opacity,margin] duration opacity-0 hidden bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
          :aria-labelledby="dropdownId"
        >
          <div class="p-1">
            <button
              disabled
              type="button"
              class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
            >
              <span class="material-symbols-outlined">monitoring</span>
              Analyse anzeigen
            </button>
            <button
              disabled
              type="button"
              class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
            >
              <span class="material-symbols-outlined">description</span>
              Report herunterladen
            </button>
            <button
              type="button"
              @click="toggleActive(props.id)"
              class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
            >
              <div v-if="props.isActive" class="flex items-center gap-x-3">
                <span class="material-symbols-outlined">person_cancel</span>
                Benutzer deaktivieren
              </div>
              <div v-else class="flex items-center gap-x-3">
                <span class="material-symbols-outlined">person_check</span>
                Benutzer aktivieren
              </div>
            </button>

            <div class="my-1 border-t border-gray-200 dark:border-neutral-700"></div>

            <button
              type="button"
              class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal text-red-600 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-red-500 dark:hover:bg-neutral-800 dark:focus:bg-neutral-700"
            >
              <span class="material-symbols-outlined">delete</span>
              Benutzer löschen
            </button>
          </div>
        </div>
      </div>
      <!-- End Download Dropdown -->
    </td>
  </tr>
</template>
