<!--
Layout of main part of main page:
- Three columns, individually scrollable
- Lab (left) and examination reports (right) can be expanded or collapsed
- Large screen: all expanded by default
- Table: only middle column = chat expanded. Only either (lab) or right (exm) can be expanded, the other
-   automatically collapses if done so.
- Mobile: only one of the three columns can be expanded at a time, the other two collapse automatically

Remaining TODO s:
- behaviour (e.g. max num of visible columns) should depend on screen size desktop (3) / tablet (2) / mobile (1)
-->

<script setup>
import InteractionColumn from '@/components/interaction_columns/InteractionColumn.vue';
import ActionColumn from '@/components/interaction_columns/ActionColumn.vue';
import ChatHistory from '@/components/interaction_columns/ChatHistory.vue';
import { computed, nextTick, onMounted, ref } from 'vue';
import { HSOverlay, HSStaticMethods } from 'preline';
import InteractionInput from '@/components/inputs/InteractionInput.vue';
import ButtonWithTextareaTooltip from '@/components/inputs/ButtonWithTextareaTooltip.vue';
import ButtonWithActionButtonTooltip from '@/components/inputs/ButtonWithActionButtonTooltip.vue';
import { storeToRefs } from 'pinia';
import CaseInteractionHeader from '@/components/CaseInteractionHeader.vue';
import {
  useAttendingInteractionStore,
  useAuthStore,
  useCaseInteractionStore,
  useMcExamInteractionStore,
  useOralExamInteractionStore,
  usePatientInteractionStore,
} from '@/stores';
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';
import { VocabMode } from '@/helper';
import OralExamChatColumn from '@/components/interaction_columns/OralExamChatColumn.vue';
import VocabModal from '@/components/VocabModal.vue';
import McExamChatColumn from '@/components/interaction_columns/McExamChatColumn.vue';
import MetaChatModal from '@/components/MetaChatModal.vue';
import MockDesktop from '@/views/clinical-information-system/MockDesktop.vue';
import PatientView from '@/views/clinical-information-system/PatientView.vue';
import ButtonToggle from '@/components/inputs/ButtonToggle.vue';

const authStore = useAuthStore();
const patientInteractionStore = usePatientInteractionStore();
const attendingInteractionStore = useAttendingInteractionStore();
const oralExamInteractionStore = useOralExamInteractionStore();
const mcExamInteractionStore = useMcExamInteractionStore();
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const caseInteractionStore = useCaseInteractionStore();

const emit = defineEmits(['startReport', 'trySolve']);

import { defineProps } from 'vue';
import ButtonWithQuizButtonTooltip from '@/components/inputs/ButtonWithQuizButtonTooltip.vue';

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  originChapterId: {
    type: String,
    required: false,
    default: '',
  }, // if case started from any section of a chapter: used to route back to the origin chapter after evaluation
});

const {
  chatIsStreaming: patientChatIsStreaming,
  labIsStreaming,
  examinationIsStreaming,
  descIsStreaming,
} = storeToRefs(patientInteractionStore);
const { chatIsStreaming: attendingChatIsStreaming } = storeToRefs(attendingInteractionStore);
const { anyChatIsStreaming: anyThirdPersonChatIsStreaming } = storeToRefs(thirdPersonInteractionStore);
const { somthingIsStreaming } = storeToRefs(caseInteractionStore);
const { subtitlesEnabled: showSubtitlesUserDefault } = storeToRefs(authStore);

const mockDesktopHandle = ref(null);

const inputText = ref('');
const showSubtitles = ref(true);
const selectedPerson = ref(null);
const playingAudio = ref(false);

const onStartReport = () => emit('startReport');
const onTrySolve = () => emit('trySolve');
const id = 'TwoColumnLayout';

onMounted(async () => {
  await nextTick();
  showSubtitles.value = !!showSubtitlesUserDefault.value;
  HSStaticMethods.autoInit();
  await nextTick();
});

async function showHistory(show) {
  console.log('showHistory: ', show);
  if (show) {
    console.log('opening history offcanvas');
    await HSOverlay.open('#hs-overlay-chat-history');
    console.log('opened history offcanvas');
    return;
  }
  console.log('closing history offcanvas');
  await HSOverlay.close('#hs-overlay-chat-history');
  console.log('closed history offcanvas');
}

async function showActionColumn(show) {
  console.log('showActionColumn: ', show);
  if (show) {
    console.log('opening action offcanvas');
    await HSOverlay.open('#hs-overlay-action-column');
    console.log('opened action offcanvas');
    return;
  }
  console.log('closing action offcanvas');
  await HSOverlay.close('#hs-overlay-action-column');
  console.log('closed action offcanvas');
}

function onHandleChatSubmit(payload) {
  inputText.value = payload;
}

const inputPlaceholderMessage = computed(() => {
  if (selectedPerson.value && !!selectedPerson.value.placeholderMessage) {
    return selectedPerson.value.placeholderMessage;
  }
  return 'Etwas sagen...';
});

function onPersonSelected(person) {
  console.log('Two column layout received person: ', person);
  selectedPerson.value = person;
}

function onPlayingAudio(playing) {
  console.log('Two column layout received playing audio: ', playing);
  playingAudio.value = playing;
}

const someChatOrDescIsStreaming = computed(() => {
  return (
    patientChatIsStreaming.value ||
    descIsStreaming.value ||
    attendingChatIsStreaming.value ||
    anyThirdPersonChatIsStreaming.value
  );
});

const someActionIsStreaming = computed(() => {
  return labIsStreaming.value || examinationIsStreaming.value || descIsStreaming.value;
});

const oralExamHandle = ref(null);
const mcExamHandle = ref(null);
const translateVocabHandle = ref(null);
const explainVocabHandle = ref(null);

async function loadHistoryOrInitNewOralExamInteraction() {
  // first we check if there is a history for this patient interaction
  const historyLoaded = await oralExamInteractionStore.fetchHistory();
  console.debug('history for oral exam available and loaded: ' + historyLoaded);
  if (!historyLoaded) {
    // no history found, so we init the oral exam interaction and start streaming
    await oralExamInteractionStore.getExaminerWelcomeMessage();
  }
}

async function onOralExam() {
  console.debug('oral exam started');
  await loadHistoryOrInitNewOralExamInteraction();

  // open modal
  const test = await oralExamHandle.value.pauseForMetaChat();
  console.debug('oral exam closed with answer ' + test);
}

async function loadHistoryOrInitNewMcExamInteraction() {
  // first we check if there is a history for this patient interaction
  const historyLoaded = await mcExamInteractionStore.fetchHistory();
  console.debug('history for mc exam available and loaded: ' + historyLoaded);
  if (!historyLoaded) {
    // no history found, so we init the mc exam interaction and start streaming
    await mcExamInteractionStore.getExaminerWelcomeMessage();
  }
}

async function onMcExam() {
  console.debug('mc exam started');
  await loadHistoryOrInitNewMcExamInteraction();

  // open modal
  // const test = await explainMeChatHandle.value.pauseForMetaChat();
  const test = await mcExamHandle.value.pauseForMetaChat();
  console.debug('mc exam closed with answer ' + test);
}

async function onTranslateVocab() {
  console.debug('vocab translation test started');

  const test = await translateVocabHandle.value.pauseForMetaChat();
  console.debug('vocab translation test closed with answer ' + test);
}

async function onExplainVocab() {
  console.debug('vocab/ expert term definition test started');

  const test = await explainVocabHandle.value.pauseForMetaChat();
  console.debug('test closed with answer ' + test);
}

async function openMockDesktop() {
  await mockDesktopHandle.value.pauseForModal();
}

async function closeMockDesktop() {
  await mockDesktopHandle.value.close();
}
</script>

<template>
  <!-- case interaction header with stepper_items -->
  <div class="flex flex-col w-full overflow-hidden" :style="{ height: `calc(100dvh - ${props.outerHeaderHeight}px)` }">
    <!-- note: h-full not working here. h-screen does, but is too large due to outer header => calc -->
    <header class="flex-none">
      <CaseInteractionHeader
        @onOralExam="onOralExam"
        @onMcExam="onMcExam"
        @onTranslateVocab="onTranslateVocab"
        @onExplainVocab="onExplainVocab"
        :originChapterId="props.originChapterId"
      />
    </header>

    <!-- main content -->
    <main class="flex-grow overflow-auto">
      <div class="grid overflow-hidden grid-cols-3 md:grid-cols-4 lg:gap-8 gap-2 shadow-gray-200 h-full w-full">
        <div class="col-start-1 hidden sm:block max-h-full overflow-hidden">
          <ActionColumn
            id="actionAsColumn"
            @startReport="onStartReport"
            @trySolve="onTrySolve"
            :showDesc="true"
            :showLab="false"
            :showExaminations="false"
          />
        </div>
        <div class="col-start-1 md:col-start-2 col-span-3 md:col-span-3">
          <InteractionColumn :inputText="inputText" :showSubtitles="showSubtitles" @personSelected="onPersonSelected" />
        </div>
      </div>
    </main>
    <!-- end of main content -->

    <!-- footer -->
    <footer class="flex-none pl-0 sm:pl-2 bg-white lg:gap-8 gap-2 flex items-center">
      <div class="hidden sm:w-1/4 sm:flex flex-col">
        <div class="hidden sm:flex flex-shrink rounded-lg">
          <!--        <ButtonWithTextareaTooltip-->
          <!--          :id="id + 'LabOrder'"-->
          <!--          :disabled="someActionIsStreaming"-->
          <!--          :isLoading="labIsStreaming"-->
          <!--          placeholderMessage="Laborwerte anfordern"-->
          <!--          @onSubmit="onHandleLabSubmit"-->
          <!--          label="Labor"-->
          <!--          icon="lab_research"-->
          <!--        />-->
          <!--        <ButtonWithTextareaTooltip-->
          <!--          :id="id + 'ExmOrder'"-->
          <!--          :disabled="someActionIsStreaming"-->
          <!--          :isLoading="examinationIsStreaming"-->
          <!--          placeholderMessage="Untersuchung anfordern"-->
          <!--          @onSubmit="onHandleExaminationSubmit"-->
          <!--          label="Diagnostik"-->
          <!--          labelAbbreviation="Dx"-->
          <!--          icon="radiology"-->
          <!--        />-->

          <ButtonToggle
            :id="id + 'KIS'"
            label="KIS-PC"
            labelAbbreviation="KIS"
            icon="desktop_windows"
            @onToggle="openMockDesktop"
          />
          <!--          <ButtonWithActionButtonTooltip-->
          <!--            :id="id + 'Action'"-->
          <!--            :disabled="someActionIsStreaming"-->
          <!--            :isLoading="descIsStreaming"-->
          <!--            @startReport="onStartReport"-->
          <!--            @trySolve="onTrySolve"-->
          <!--            @onSubmit=""-->
          <!--            label="Aktion"-->
          <!--            icon="pill"-->
          <!--          />-->
          <ButtonWithQuizButtonTooltip
            :id="id + 'Quiz'"
            :disabled="someActionIsStreaming"
            :isLoading="descIsStreaming"
            @onTranslateVocab="onTranslateVocab"
            @onExplainVocab="onExplainVocab"
            @onSubmit=""
            label="Vokabel-trainer"
            labelAbbreviation="Vokabel"
            icon="quiz"
          />
        </div>
      </div>
      <div class="w-full z-[90] sm:w-3/4 relative flex flex-col">
        <InteractionInput
          :placeholderMessage="inputPlaceholderMessage"
          :disabled="somthingIsStreaming"
          @onSubmit="onHandleChatSubmit"
          @onShowHistory="showHistory"
          @onShowActionColumn="showActionColumn"
          @onToggleMockDesktop="openMockDesktop"
          @onToggleSubtitles="(show) => (showSubtitles = show)"
          :allowAudioInput="true"
        />
      </div>
    </footer>
  </div>
  <!-- end of footer -->

  <!-- chat history offcanvas -->
  <div
    id="hs-overlay-chat-history"
    class="hs-overlay hs-overlay-open:flex flex-col-reverse overflow-y-auto grow hs-overlay-open:translate-x-0 hidden translate-x-full fixed top-0 end-0 transition-all duration-300 transform h-full max-w-md w-full z-[80] bg-gray-50 border-e dark:bg-neutral-800 dark:border-neutral-700 [--body-scroll:true] [--overlay-backdrop:false]"
    tabindex="-1"
  >
    <div class="translate-z-0">
      <div class="p-4 flex-col bg-gray-50">
        <div class="text-gray-800 dark:text-neutral-400 flex-col">
          <div class="relative flex flex-col">
            <ChatHistory :selectedPerson="selectedPerson" @playingAudio="onPlayingAudio" />
          </div>
        </div>
      </div>
      <div
        class="flex grow min-h-[180px] justify-between items-center py-3 px-4 border-b dark:border-neutral-700 bg-gray-50 sticky bottom-0"
      >
        <h3 class="font-bold text-gray-800 dark:text-white"></h3>
        <button
          type="button"
          class="flex justify-center items-center h-7 w-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
          data-hs-overlay="#hs-overlay-chat-history"
        >
          <span class="sr-only">Close modal</span>
        </button>
      </div>
    </div>
  </div>
  <!-- end of chat history offcanvas -->

  <!-- action column offcanvas -->
  <div
    id="hs-overlay-action-column"
    class="hs-overlay flex-col-reverse overflow-y-auto grow hs-overlay-open:translate-x-0 hidden -translate-x-full fixed top-0 start-0 transition-all duration-300 transform h-full max-w-xs sm:max-w-0 w-full z-[80] bg-gray-50 border-e dark:bg-neutral-800 dark:border-neutral-700 [--body-scroll:true] [--overlay-backdrop:false]"
    tabindex="-1"
  >
    <div class="translate-z-0">
      <div class="p-4 flex-col bg-gray-50">
        <div class="text-gray-800 dark:text-neutral-400 flex-col">
          <div class="relative flex flex-col">
            <ActionColumn
              id="actionAsOffcanvas"
              @startReport="onStartReport"
              @trySolve="onTrySolve"
              :showDesc="true"
              :showLab="false"
              :showExaminations="false"
            />
          </div>
        </div>
      </div>
      <div
        class="flex grow min-h-[180px] justify-between items-center py-3 px-4 border-b dark:border-neutral-700 bg-gray-50 sticky bottom-0"
      >
        <h3 class="font-bold text-gray-800 dark:text-white"></h3>
        <button
          type="button"
          class="flex justify-center items-center h-7 w-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
          data-hs-overlay="#hs-overlay-action-column"
        >
          <span class="sr-only">Close modal</span>
        </button>
      </div>
    </div>
  </div>
  <!-- end of action column offcanvas -->

  <MockDesktop ref="mockDesktopHandle" overlayId="mockDesktop">
    <PatientView @logout="closeMockDesktop" @trySolve="onTrySolve" />
  </MockDesktop>

  <MetaChatModal ref="oralExamHandle" heading="Mündliches Prüfungsgespräch" overlayId="oral-modal">
    <OralExamChatColumn />
  </MetaChatModal>
  <MetaChatModal ref="mcExamHandle" heading="Multiple Choice-Probeprüfung" overlayId="mc-modal">
    <McExamChatColumn />
  </MetaChatModal>
  <VocabModal
    ref="translateVocabHandle"
    overlayId="translate-vocab-modal"
    :showSolutions="false"
    :mode="VocabMode.TRANSLATE"
  />
  <VocabModal
    ref="explainVocabHandle"
    overlayId="explain-vocab-modal"
    :showSolutions="false"
    :mode="VocabMode.EXPLAIN"
    :nVocabs="5"
  />
</template>

<style></style>
