<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onMounted, ref, watch, Ref } from 'vue';
import { useAlertStore, useAuthStore, useCaseStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import BreadcrumpElement from '@/components/BreadcrumpElement.vue';
import EditCourseSection from '@/views/courses/EditCourseSection.vue';
import EditCourseTitleAndDescription from '@/views/courses/EditCourseTitleAndDescription.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import { router } from '@/router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const courseStore = useCourseStore();
const authStore = useAuthStore();
const { currentCourse, currentChapter, currentChapterSections } = storeToRefs(courseStore);
const { user, userId, isAdmin: userIsAdmin } = storeToRefs(authStore);
const alertStore = useAlertStore();
const isLoading = ref(false);
const scrollContainer = ref();
const userConformationModalHandle = ref(null);
const confirmSectionDeleteModal = ref(null);

const editCourseTitleAndDescription = ref();
const editCourseTitleAndDescriptionUnsavedChangesCounter = ref(0);
const editCourseSections = ref<Array<any>>([]);
const editCourseSectionsUnsavedChangesCounter = ref([] as number[]);

const scrollspyElements = ref([]);
const activeScrollspyElement = ref(0);
const callbackQueue = ref<(() => Promise<void>)[]>([]);

const sortedSections = ref([]);
const chapterId = ref('');

const keepMounted = ref(true);

const unsavedChanges = computed(() => {
  return (
    editCourseTitleAndDescriptionUnsavedChangesCounter.value > 0 ||
    editCourseSectionsUnsavedChangesCounter.value.reduce((a, b) => a + b, 0) > 0
  );
});

const getOrderedSections = () => {
  if (!currentChapterSections.value) {
    return [];
  }
  const sections = currentChapterSections.value.slice();
  sections.sort((a, b) => a.index - b.index);
  appendDummySectionForButton(sections);
  return sections;
};

onBeforeMount(async () => {
  await router.isReady();
  chapterId.value = router.currentRoute.value.params.chapterId;
  console.log('Premount for chapter: #' + chapterId.value + '#');

  // console.log('State of courseStore: ', courseStore.currentCourse.id);
  // console.log(courseStore.currentChapterId)

  await courseStore.settingCoursePromise;
  await courseStore.setCurrentChapter(chapterId.value);

  // check user permissions
  if (!isCourseOwnerOrEditorOrAdmin.value && !isChapterOwnerOrAdmin.value) {
    alertStore.error('status.error.noPermission');
    await router.push('/home');
    return;
  }
});

function mountingHelper() {
  if (!storeLoaded.value) {
    return;
  }
  scrollspyElements.value = document.querySelectorAll('[data-hs-scrollspy-group]');
  // scrollspyElementActive.value = Array(scrollspyElements.value.length).fill(false);
  activeScrollspyElement.value = 0;
  // console.log('scrollspyElements', scrollspyElements.value);

  resetChangesCounters();
  console.log('editCourseSectionsUnsavedChangesCounter', editCourseSectionsUnsavedChangesCounter.value);
  console.log('editCourseSections', editCourseSections.value);

  sortedSections.value = getOrderedSections();
  editCourseSections.value = sortedSections.value.map(() => ref(null));

  isLoading.value = false;
}

onMounted(async () => {
  isLoading.value = true;

  await nextTick(async () => {
    mountingHelper();
  });

  watch(
    () => storeLoaded.value,
    async (newVal: boolean) => {
      if (newVal) {
        mountingHelper();
      }
    },
    { immediate: true },
  );

  // watcher: if changes to sections completed, trigger scroll
  watch(
    () => sortedSections.value.length,
    async (newLength: number, oldLength: number) => {
      await mountingHelper();
      console.debug('section number changed from ' + oldLength + ' to ' + newLength);

      for (const func of callbackQueue.value) {
        console.log('executing callback');
        await func();
      }
      callbackQueue.value = [];
    },
  );
});

const pageId = () => {
  return 'chapter-' + courseStore.currentChapterId;
};

const pageHeading = () => {
  return courseStore.currentChapterTitle;
};

const hashtagedId = () => {
  return '#' + pageId();
};

const scrollspyId = () => {
  return '#scrollspy-' + pageId();
};

const headingToId = (heading: string) => {
  // Replace special characters with their corresponding CSS escaped characters
  let selector = heading.replace(/[^a-zA-Z0-9-]/g, (match) => {
    return match.charCodeAt(0).toString(16).toUpperCase() + ' ';
  });

  selector = selector.trim();
  selector = selector.replace(/\s+/g, '-');
  selector = 'section-' + selector; // Add a prefix to avoid starting with a number

  return selector;
};

async function scrollToBottom() {
  console.log('scrolls: ' + document.body.scrollHeight);
  await nextTick();
  setTimeout(() => {
    scrollContainer.value.scrollTo({
      top: scrollContainer.value.scrollHeight,
      behavior: 'smooth',
    });
  }, 1000);
}

async function onNewSection() {
  // editCourseSections.value = editCourseSections.value.filter(ref => ref !== null);

  console.debug('Adding new section');
  isLoading.value = true;

  callbackQueue.value.push(scrollToBottom);
  await courseStore.appendEmptySection();
  editCourseSectionsUnsavedChangesCounter.value.push(0);
  await mountingHelper();
  isLoading.value = false;
}

const onDeleteSection = async (sectionId: number) => {
  // editCourseSections.value = editCourseSections.value.filter(ref => ref !== null);

  console.debug('Deleting section: ' + sectionId);
  isLoading.value = true;

  let confirm = await confirmSectionDeleteModal.value.promptUserConformation();
  if (!confirm) {
    console.debug('User declined deletion');
    isLoading.value = false;
    return;
  }

  await courseStore.deleteSection(sectionId);
  await mountingHelper();

  isLoading.value = false;
};

const storeLoaded = computed(() => {
  return currentCourse.value !== null && currentChapter.value !== null && currentChapterSections.value !== null;
});

function appendDummySectionForButton(sections: any[]) {
  // Add a dummy section to the end of the sections array
  // This is used to display the "Add new section" button
  sections.push({
    id: null,
    created_at: null,
    chapter_id: currentChapter.id,
    index: sections.length,
    title: t('message.newSection'),
    contents: '',
    case_list_id: null,
  });
}

function isActive(index: number) {
  // Check if the element has the hs-scrollspy-active class
  // console.log('isActive:', scrollspyElements.value[index] ? scrollspyElements.value[index].classList : 'no element');
  // if (scrollspyElements.value[index] && scrollspyElements.value[index].classList.contains('active')) {
  //   console.log('Active scrollspy element: ' + index);
  // }
  return scrollspyElements.value[index] && scrollspyElements.value[index].classList.contains('active');
}

function monitorScroll() {
  for (let i = 0; i < scrollspyElements.value.length; i++) {
    if (isActive(i)) {
      activeScrollspyElement.value = i;
    }
  }
}

async function onSaveChanges() {
  if (!unsavedChanges.value) {
    return;
  }
  if (!currentChapter.value) {
    alertStore.error('Internal error: Chapter not set.');
    return;
  }
  isLoading.value = true;

  // console.log('Testing: ', editCourseSections.value[0].titleEditor.getRawTextContent());

  try {
    console.log('start try');
    console.log('num changes chapter:', editCourseTitleAndDescriptionUnsavedChangesCounter.value);
    if (editCourseTitleAndDescriptionUnsavedChangesCounter.value > 0) {
      let id = currentChapter.value.id;
      console.log('id', id);
      let title = editCourseTitleAndDescription.value.titleEditor.getRawTextContent();
      console.log('title', title);
      let subtitle = editCourseTitleAndDescription.value.subtitleEditor.getRawTextContent();
      console.log('Would save: ' + title + ' and ' + subtitle + ' for id ' + id);
      await courseStore.updateChapterTitleAndDescription(id, title, subtitle);
      editCourseTitleAndDescription.value.titleEditor.resetEmitState();
      editCourseTitleAndDescription.value.subtitleEditor.resetEmitState();
      editCourseTitleAndDescriptionUnsavedChangesCounter.value = 0;
    }

    console.log('start for');
    console.log(editCourseSections.value.length);
    for (let index = 0; index < editCourseSections.value.length; index++) {
      console.log('num changes for ', index, ':', editCourseSectionsUnsavedChangesCounter.value[index]);
      if (editCourseSectionsUnsavedChangesCounter.value[index] > 0) {
        let id = sortedSections.value[index].id;
        console.log('id', id);
        console.log(editCourseSections.value);
        console.log(editCourseSections.value[index]);
        // console.log(editCourseSections.value[index].titleEditor);
        console.log(editCourseSections.value[index].value[0].titleEditor);

        // depending on how the ref is set, the value is either in the ref or in the ref.value:
        // either: editCourseSections.value[index].value[0].titleEditor etc (for push ref)
        // or: editCourseSections.value[index].titleEditor etc (for listRef solution for draggable)
        // DO NOT DELETE THIS NOTE !

        let title = editCourseSections.value[index].value[0].titleEditor.getRawTextContent();
        console.log('title', title);
        let content = editCourseSections.value[index].value[0].contentEditor.getHTMLContent();
        console.log('content', content);

        let learningObjectivesDescriptions =
          editCourseSections.value[index].value[0].learningObjectives.getLearningObjectives();
        console.log('Learning objectives: ' + JSON.stringify(learningObjectivesDescriptions));

        console.log('Would save: ' + title + ' and ' + content + ' for section #' + index + 'with id ' + id);
        await courseStore.updateSection(id, index, title, content, learningObjectivesDescriptions);
        editCourseSections.value[index].value[0].titleEditor.resetEmitState();
        editCourseSections.value[index].value[0].contentEditor.resetEmitState();
        editCourseSectionsUnsavedChangesCounter.value[index] = 0;
      }
    }
    alertStore.success('status.success.changesSaved');
  } catch (error) {
    alertStore.error('status.error.saveChangesError');
  } finally {
    isLoading.value = false;
  }
}

async function remount() {
  console.log('remounting');
  keepMounted.value = false;
  await nextTick();
  keepMounted.value = true;
}

function resetChangesCounters() {
  console.log('resetting counters');
  console.log('storeLoaded', storeLoaded.value);
  editCourseTitleAndDescriptionUnsavedChangesCounter.value = 0;
  editCourseSectionsUnsavedChangesCounter.value.length = 0;
  for (let i = 0; i < sortedSections.value.length - 1; i++) {
    // -1: skip dummy element at the end (placeholder for add section button)
    editCourseSectionsUnsavedChangesCounter.value.push(0);
    // editCourseSections.value.push(ref(null));
  }
}

async function onDiscardChanges() {
  if (!unsavedChanges.value) {
    return;
  }
  isLoading.value = true;
  console.debug('Discarding changes');
  // const userApproved = await userConformationModalHandle.value.promptUserConformation().catch((error: any) => {
  //   console.debug('User declined: ' + error);
  //   isLoading.value = false;
  //   return false;
  // });
  // if (!userApproved) {
  //   console.debug('User declined.');
  //   isLoading.value = false;
  //   return;
  // }
  await nextTick();
  resetChangesCounters();
  console.log('Resetting...');
  console.log('...now');
  await remount();
  isLoading.value = false;
  console.log('done');
}

const onMoveSection = async (event: any) => {
  // editCourseSections.value = editCourseSections.value.filter(ref => ref !== null);

  console.log('Moved section');
  console.log(event);
  console.log('from ' + event.oldIndex + ' to ' + event.newIndex);
  isLoading.value = true;

  await courseStore.moveSection(event.oldIndex, event.newIndex);

  isLoading.value = false;
};

// const listRef = (el: any) => {
//   if (el) {
//     editCourseSections.value.push(el);
//   }
// };
// => wrong this accumulates tons of null refs

const isChapterOwnerOrAdmin = computed(() => {
  return currentChapter.value && (courseStore.isOwnerOfCurrentChapter(userId.value) || userIsAdmin.value);
});

const isCourseOwnerOrEditorOrAdmin = computed(() => {
  return (
    currentCourse.value &&
    (courseStore.isOwnerOfCurrentCourse(userId.value) ||
      courseStore.isEditorOfCurrentCourse(userId.value) ||
      userIsAdmin.value)
  );
});

const setListRef = (el, index) => {
  // editCourseSections.value = editCourseSections.value.filter(ref => ref !== null);
  if (!!el) {
    // Add the reference to the corresponding index
    editCourseSections.value[index] = el;
  } else {
    // When the element is removed (null), clear the reference
    editCourseSections.value[index] = null;
  }
};
</script>

<template>
  <div
    :id="pageId()"
    class="max-h-full justify-center min-w-full min-h-full h-full flex-col overflow-auto"
    @scroll="monitorScroll()"
    ref="scrollContainer"
    v-if="storeLoaded && keepMounted"
  >
    <!--    #{{ editCourseSections.length }}#-->
    <!--    #{{ editCourseSections }}#-->
    <!--                {{ editCourseSectionsUnsavedChangesCounter }}-->
    <!-- Navigation + save button -->
    <h2
      class="min-w-full sticky top-0 text-base text-gray-800 dark:text-gray-200 bg-white dark:bg-neutral-900 pb-4 justify-center flex z-20"
    >
      <!-- breadcrumb + scrollspy -->
      <div class="max-w-[85rem] w-full mx-auto px-4 md:px-6 lg:px-8 flex justify-between">
        <ol class="flex items-center whitespace-nowrap">
          <!-- level 1 breadcrump: all courses -->
          <BreadcrumpElement label="Kurse" to="/home" />
          <!-- level 2 breadcrump: current course -->
          <BreadcrumpElement :label="currentCourse.title" :to="'/course/' + currentCourse.id" />
          <!-- level 3 breadcump: current section with scrollspy -->
          <li class="inline-flex items-center min-w-fit w-fit text-sm z-40">
            <div class="hs-dropdown relative inline-flex min-w-fit w-fit [--placement:top-left]">
              <button
                id="hs-breadcrumb-dropdown"
                type="button"
                class="z-20 hs-dropdown-toggle py-1.5 px-2 inline-flex min-w-fit w-fit items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 shadow-sm hover:bg-gray-50 text-gray-500 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:text-blue-600"
              >
                {{ currentChapter.title }}:
                {{
                  sortedSections[activeScrollspyElement] ? sortedSections[activeScrollspyElement].title : 'No section'
                }}
                <svg
                  class="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="12" cy="5" r="1"></circle>
                  <circle cx="12" cy="19" r="1"></circle>
                </svg>
              </button>
              <div
                class="hs-dropdown-menu hs-dropdown-open:opacity-100 min-w-fit w-fit hidden z-20 transition-[margin,opacity] opacity-0 duration-300 mb-2 bg-white shadow-md rounded-lg p-2 dark:bg-neutral-800 dark:border dark:border-gray-700 dark:divide-gray-700"
                aria-labelledby="hs-breadcrumb-dropdown"
              >
                <div
                  class="sticky top-20 mt-1"
                  :data-hs-scrollspy="scrollspyId()"
                  :data-hs-scrollspy-scrollable-parent="hashtagedId()"
                >
                  <a
                    v-for="(section, index) in sortedSections"
                    :key="section.index"
                    data-hs-scrollspy-group=""
                    :id="scrollspyElements[index]"
                    :href="'#' + headingToId(section.title)"
                    class="text-sm font-medium leading-6 text-neutral-700 hover:text-neutral-900 focus:outline-none hs-scrollspy-active:text-blue-600 focus:text-blue-600 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-blue-600 dark:hs-scrollspy-active:text-blue-400 active': true"
                  >
                    <p>
                      <!-- NOTE: does not work without the href! -->
                      {{ section.title }}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ol>
        <!-- discard/ save buttons -->
        <div class="flex justify-right gap-x-2">
          <button
            v-show="unsavedChanges"
            type="button"
            class="py-2 px-3 flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-400 text-white hover:bg-red-500 disabled:opacity-50 disabled:pointer-events-none"
            @click="onDiscardChanges"
          >
            <div
              v-if="isLoading"
              class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
              role="status"
              aria-label="loading"
            >
              <span class="sr-only">Loading</span>
            </div>
            <div class="flex gap-x-3" v-else>{{ $t('message.discardChanges') }}</div>
          </button>
          <button
            v-show="unsavedChanges"
            type="button"
            class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            @click="onSaveChanges"
          >
            <div
              v-if="isLoading"
              class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
              role="status"
              aria-label="loading"
            >
              <span class="sr-only">{{ $t('message.loading') }}</span>
            </div>
            <div class="flex gap-x-3" v-else>{{ $t('message.saveChanges') }}</div>
          </button>
        </div>
      </div>
    </h2>
    <!-- end of header -->

    <!-- sections -->
    <div class="py-4">
      <EditCourseTitleAndDescription
        ref="editCourseTitleAndDescription"
        :chapter="currentChapter"
        @unsavedChanges="editCourseTitleAndDescriptionUnsavedChangesCounter += 1"
        @changesCleared="editCourseTitleAndDescriptionUnsavedChangesCounter -= 1"
      />
    </div>
    <div id="scrollspy-2" class="space-y-4 justify-center">
      <div
        class="mx-auto flex-col justify-center max-w-6xl"
        v-for="(section, index) in sortedSections"
        :key="section.index"
      >
        <EditCourseSection
          :ref="editCourseSections[index]"
          v-if="section.id !== null"
          :section="section"
          :chapterId="chapterId"
          @unsavedChanges="editCourseSectionsUnsavedChangesCounter[index] += 1"
          @changesCleared="editCourseSectionsUnsavedChangesCounter[index] -= 1"
          @onDeleteSection="onDeleteSection(section.id)"
        />
      </div>

      <!--      <draggable-->
      <!--        v-if="sortedSections.length > 0"-->
      <!--        v-model="sortedSections"-->
      <!--        item-key="index"-->
      <!--        tag="div"-->
      <!--        @end="onMoveSection"-->
      <!--        class="mx-auto flex-col justify-center max-w-6xl"-->
      <!--        :disabled="false"-->
      <!--        :scroll-sensitivity="200"-->
      <!--        :force-fallback="true"-->
      <!--      >-->
      <!--        <template #item="{ element: section, index }">-->
      <!--          <div>-->
      <!--            <EditCourseSection-->
      <!--              :ref="(el) => setListRef(el, index)"-->
      <!--              :index="index"-->
      <!--              v-if="section.id !== null"-->
      <!--              :section="section"-->
      <!--              @unsavedChanges="editCourseSectionsUnsavedChangesCounter[index] += 1"-->
      <!--              @changesCleared="editCourseSectionsUnsavedChangesCounter[index] -= 1"-->
      <!--              @onDeleteSection="onDeleteSection(section.id)"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </draggable>-->
      <div class="mx-auto flex-col justify-center max-w-6xl">
        <div
          class="px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-900 dark:border-gray-700 flex justify-center max-w-6xl"
        >
          <button
            type="button"
            class="rounded-lg border border-transparent min-w-full py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            @click="onNewSection"
          >
            <div
              v-if="isLoading"
              class="animate-spin inline-block w-4 h-4 text-white rounded-full"
              role="status"
              aria-label="loading"
            >
              <span class="sr-only">{{ $t('message.loading') }}</span>
            </div>
            <div class="flex min-w-full gap-x-3 items-center justify-center" v-else>
              <svg
                class="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5v14" />
              </svg>
              {{ $t('message.addParagraph') }}
            </div>
          </button>
        </div>
      </div>

      <UserConfirmationModal
        ref="userConformationModalHandle"
        prompt_message=""
        :approve_message="$t('message.yes')"
        :discard_message="$t('message.no')"
        overlayId="confirmDiscardChangesModal"
        approve_color="bg-red-500 hover:bg-red-600"
      >
        {{ $t('message.discardChangesConfirmation') }}
      </UserConfirmationModal>
      <UserConfirmationModal
        ref="confirmSectionDeleteModal"
        prompt_message=""
        :approve_message="$t('message.delete')"
        :discard_message="$t('message.back')"
        overlayId="confirmSectionDeleteModal"
        approve_color="bg-red-500 hover:bg-red-600"
      >
        {{ $t('message.discardChangesConfirmation') }}
      </UserConfirmationModal>
    </div>
  </div>
  <div v-else>
    <div class="pt-20 flex justify-center items-center h-full">
      <div
        class="animate-spin inline-block w-32 h-32 border-4 border-current border-t-transparent text-blue-600 rounded-full"
        role="status"
        aria-label="loading"
      />
    </div>
  </div>
</template>

<style scoped></style>
