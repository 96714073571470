<script setup>
// TODO i18n
// TODO - 2: eine org kann mehrere subscriptions haben. Die Member sind aber nicht den verschiedenen Subscriptions zugeorndet. Ist das Absciht?

import { useAuthStore } from '@/stores';
import { ref, onMounted, onBeforeMount, reactive } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
import 'vue-advanced-cropper/dist/style.css';
import UserListItem from '@/components/dashboard/UserListItem.vue';
import _ from 'lodash';
import SimpleStatsCard from '@/components/dashboard/SimpleStatsCard.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { is } from '@vee-validate/rules';

const alertStore = useAlertStore();
const authStore = useAuthStore();
const isLoading = ref(true);
const users = ref([]);
const numUserSeatsUsed = ref([]);
const numManagerSeatsUsed = ref([]);
const organization = ref(null);
const seats = ref({
  n_users: 0,
  n_managers: 0,
  n_invited: 0,
  n_invited_users: 0,
});
const subscriptions = ref([]);

const getDate = (datetime) => {
  const date = new Date(datetime);
  return new Intl.DateTimeFormat('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);
};

const isActive = (subscription) => {
  const date = new Date(subscription.expires_at); // Convert provided datetime to a Date object
  const today = new Date(); // Get the current date and time
  today.setHours(0, 0, 0, 0);
  return date >= today;
};

const percentageUserSeatsUsed = (index) => {
  return Math.round(
    (numUserSeatsUsed.value[index] / subscriptions.value[index].organization_subscription_plan.n_user_seats) * 100,
  );
};

const calculateTakenUserSeatsPerSubscription = () => {
  numUserSeatsUsed.value = [];

  subscriptions.value.forEach((subscription, index) => {
    if (!isActive(subscription)) numUserSeatsUsed.value.push(null);
    console.log(subscription.organization_subscription_plan.n_user_seats);
    const maxSeats = subscription.organization_subscription_plan.n_user_seats;
    console.log(seats.value.n_active_users);
    const activeUsers = seats.value.n_active_users;

    // Calculate the seats taken for this subscription (capped by the max seats allowed)
    const seatsUsed = Math.min(activeUsers, maxSeats);

    numUserSeatsUsed.value.push(seatsUsed);
    seats.value.n_active_users -= seatsUsed;
  });
};

const percentageManagerSeatsUsed = (index) => {
  return Math.round(
    (numManagerSeatsUsed.value[index] / subscriptions.value[index].organization_subscription_plan.n_manager_seats) *
      100,
  );
};

const calculateTakenManagerSeatsPerSubscription = () => {
  numManagerSeatsUsed.value = [];

  subscriptions.value.forEach((subscription, index) => {
    if (!isActive(subscription)) numManagerSeatsUsed.value.push(null);
    console.log(subscription.organization_subscription_plan.n_manager_seats);
    const maxSeats = subscription.organization_subscription_plan.n_manager_seats;
    const activeManagers = seats.value.n_active_managers;

    // Calculate the seats taken for this subscription (capped by the max seats allowed)
    const seatsUsed = Math.min(activeManagers, maxSeats);

    numManagerSeatsUsed.value.push(seatsUsed);
    seats.value.n_active_managers -= seatsUsed;
  });
};

onMounted(async () => {
  isLoading.value = true;

  if (!authStore.isSignedIn) {
    return;
  }

  try {
    const apiClient = await getApiClient();

    // Get the organization
    organization.value = await apiClient.users
      .listManagedOrganizationsOfUser(authStore.userId)
      .then((organizations) => {
        return organizations[0]; // Assuming only one organization for now
      });

    // Define the async tasks in an array of promises
    const tasks = [
      // Load users of the organization
      apiClient.organizations.listOrganizationMembers(organization.value.id).then((result) => {
        result.forEach((user) => {
          users.value.push(user);
        });
      }),

      // Load organization seats
      apiClient.organizations.getOrganizationSeats(organization.value.id).then((result) => {
        seats.value = result;
      }),

      // Load organization subscriptions
      apiClient.organizations.listOrganizationSubscriptions(organization.value.id).then((result) => {
        result.forEach((sup) => {
          subscriptions.value.push(sup);
          // const deepCopy = JSON.parse(JSON.stringify(sup));
          // const deepCopy2 = JSON.parse(JSON.stringify(deepCopy));
          // deepCopy.expires_at = '2023-07-07T08:58:37Z';
          // subscriptions.value.unshift(deepCopy);
          // subscriptions.value.push(deepCopy2);
        });
      }),
    ];

    // Wait for all tasks to complete (success or failure)
    await Promise.all(tasks);
  } catch (error) {
    console.error('Error:', error);
    alertStore.error('Fehler beim Laden der Daten');
  } finally {
    // Ensure isLoading is set to false after all tasks are done
    isLoading.value = false;

    calculateTakenUserSeatsPerSubscription();
    calculateTakenManagerSeatsPerSubscription();
  }
});
</script>
<template>
  <div v-if="isLoading">
    <div class="flex justify-center items-center pt-64 min-h-full relative">
      <div
        class="animate-spin inline-block w-32 h-32 border-4 border-current border-t-transparent text-blue-600 rounded-full"
        role="status"
        aria-label="loading"
      />
    </div>
  </div>
  <main id="content" class="pt-10 px-20 flex-col" v-else>
    <!-- Card -->
    <div class="flex-col" v-for="(subscription, index) in subscriptions">
      <div
        class="flex mt-4 flex-col bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
      >
        <!-- Body -->
        <div class="h-full p-6">
          <svg
            class="w-[34px] h-[30px]"
            width="34"
            height="30"
            viewBox="0 0 34 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="7"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              :class="{
                'fill-blue-200 dark:fill-blue-500': isActive(subscription),
                'fill-gray-200 dark:fill-gray-500': !isActive(subscription),
              }"
            />
            <rect
              y="10"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              :class="{
                'fill-blue-300 dark:fill-blue-600': isActive(subscription),
                'fill-gray-300 dark:fill-gray-600': !isActive(subscription),
              }"
            />
            <rect
              x="14"
              y="10"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              :class="{
                'fill-blue-500 dark:fill-blue-700': isActive(subscription),
                'fill-gray-500 dark:fill-gray-700': !isActive(subscription),
              }"
            />
          </svg>

          <!-- Grid -->
          <div class="mt-3 grid grid-cols-2 gap-x-2">
            <div>
              <div class="flex items-center gap-x-2">
                <h2 class="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                  {{ subscription.organization_subscription_plan.name }}
                </h2>
                <span
                  class="inline-flex items-center gap-1.5 py-1.5 px-2 text-xs font-medium rounded-full"
                  :class="{
                    'bg-blue-100 text-blue-800 dark:bg-blue-500/10 dark:text-blue-500': isActive(subscription),
                    'bg-gray-100 text-gray-800 dark:bg-gray-500/10 dark:text-gray-500': !isActive(subscription),
                  }"
                >
                  <span
                    class="size-1.5 inline-block rounded-full"
                    :class="{
                      'bg-blue-800 dark:bg-blue-500': isActive(subscription),
                      'bg-gray-800 dark:bg-gray-500': !isActive(subscription),
                    }"
                  ></span>
                  {{ isActive(subscription) ? 'Active' : 'Inactive' }}
                </span>
              </div>

              <p class="mt-1.5 text-sm text-gray-500 dark:text-neutral-500">
                {{ isActive(subscription) ? 'Expires on' : 'Expired on' }} {{ getDate(subscription.expires_at) }}
              </p>
            </div>
            <!-- End Col -->
          </div>
          <!-- End Grid -->

          <!-- Progress -->
          <div class="my-4">
            <div class="flex justify-between items-center gap-x-2 mb-1">
              <h4 class="font-medium text-gray-800 dark:text-neutral-200">User-Seats</h4>
              <p class="text-sm text-gray-500 dark:text-neutral-500">
                {{ isActive(subscription) ? numUserSeatsUsed[index] : '--' }} of
                {{ subscription.organization_subscription_plan.n_user_seats }} used
              </p>
            </div>
            <div
              class="flex w-full h-2.5 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700"
              role="progressbar"
              :aria-valuenow="isActive(subscription) ? percentageUserSeatsUsed(index) : 100"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                class="flex flex-col justify-center overflow-hidden text-xs text-white text-center rounded-full whitespace-nowrap"
                :class="{
                  'bg-blue-600': isActive(subscription),
                  'bg-gray-600': !isActive(subscription),
                }"
                :style="{ width: isActive(subscription) ? `${percentageUserSeatsUsed(index)}%` : '100%' }"
              ></div>
            </div>
          </div>
          <!-- End Progress -->

          <!-- Progress -->
          <div class="my-4">
            <div class="flex justify-between items-center gap-x-2 mb-1">
              <h4 class="font-medium text-gray-800 dark:text-neutral-200">Manager-Seats</h4>
              <p class="text-sm text-gray-500 dark:text-neutral-500">
                {{ isActive(subscription) ? numManagerSeatsUsed[index] : '--' }} of
                {{ subscription.organization_subscription_plan.n_manager_seats }} used
              </p>
            </div>
            <div
              class="flex w-full h-2.5 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700"
              role="progressbar"
              :aria-valuenow="isActive(subscription) ? percentageManagerSeatsUsed(index) : 100"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                class="flex flex-col justify-center overflow-hidden text-xs text-white text-center rounded-full whitespace-nowrap"
                :class="{
                  'bg-blue-600': isActive(subscription),
                  'bg-gray-600': !isActive(subscription),
                }"
                :style="{ width: isActive(subscription) ? `${percentageManagerSeatsUsed(index)}%` : '100%' }"
              ></div>
            </div>
          </div>
          <!-- End Progress -->
        </div>
        <!-- End Body -->
      </div>
      <!-- End Card -->
    </div>
  </main>
</template>
