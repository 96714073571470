<!--
// TODO: explain and exam column sometimes get blocked when ending cases and reloading WHILE streming (main column likewise?) -- FIX THIS
-->

<script setup>
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useAuthStore, useOralExamInteractionStore } from '@/stores';

import { getApiClient } from '@/apiclient/client';
import ChatInput from '@/components/inputs/InteractionInput.vue';
import ChatBubbleProfessor from '@/components/chat_bubbles/ChatBubbleProfessor.vue';
import ChatBubbleUser from '@/components/chat_bubbles/ChatBubbleUser.vue';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import ChatBubblePatient from '@/components/chat_bubbles/ChatBubblePatient.vue';

const authStore = useAuthStore();
const { user, hideChatHistory: initiallyHideChatHistory } = storeToRefs(authStore);

const oralExamInteractionStore = useOralExamInteractionStore();
const { currentOralExamInteractionId, chatIsStreaming, chatMessages } = storeToRefs(oralExamInteractionStore);
const hideChatHistory = ref(initiallyHideChatHistory.value);

async function handleSubmit(inputText) {
  console.debug('User input: ' + inputText.value);
  console.debug(' for oral exam interaction ' + currentOralExamInteractionId.value);
  const content = inputText.value;

  await oralExamInteractionStore.say(content);
  // create preliminary messages = user input + template for model output
  // await oralExamInteractionStore.createPreliminaryChatMessage(
  //     content,
  //     "ORAL"
  // )
  //
  // const url = '/oral-exam-interactions/' + currentOralExamInteractionId.value
  // await (await getStreamingClient()).streamFetchRequest(
  //     'POST',
  //     url,
  //     {
  //       "type": "ORAL",
  //       "content": content
  //     },
  //     oralExamInteractionStore.appendToLastChatMessageAndRefetchOnceFinished
  // )
  // let the store fetch the last message (=user input + model output)
  // and replace the last, incomplete object its chat message stream

  console.debug(JSON.stringify(chatMessages.value));
}

async function toggleHideHistory() {
  hideChatHistory.value = !hideChatHistory.value;
}
</script>

<template>
  <!-- NOTE: added p-4 here as cannot wrap <slot></slot> in MetaChatModal w/o destroying scrolling behaviour here -->
  <!-- <div class="flex-grow overflow-y-auto p-4"> -->
  <div class="overflow-y-auto flex-col-reverse flex p-4">
    <!-- this fixes scroll to bottom; source: https://stackoverflow.com/a/72644230 -->
    <div class="translate-z-0">
      <!-- part of the scroll stick -->

      <div class="space-y-2" v-for="(message, index) in chatMessages">
        <div
          class="flex flex-col space-y-2 mt-2 items-center justify-items-center"
          v-if="index === chatMessages.length - 1 && chatMessages.length > 2"
        >
          <button
            type="button"
            class="py-3 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg border border-transparent text-blue-600 hover:bg-blue-100 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-600 dark:hover:bg-blue-800/30 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            @click="toggleHideHistory"
          >
            Gesprächsverlauf {{ hideChatHistory ? 'zeigen...' : 'verbergen' }}
          </button>
        </div>

        <div v-if="message.type === 'ORAL_REACT'" class="space-y-2">
          <div>
            <ChatBubbleProfessor :message="message" :isLatestBubble="index === chatMessages.length - 1" />
          </div>
        </div>
        <div
          v-if="message.type === 'ORAL'"
          v-show="!hideChatHistory || index === chatMessages.length - 1"
          class="space-y-2 mt-2"
        >
          <div>
            <ChatBubbleUser :message="message" conversation="ORAL-EXAM" />
          </div>
          <div>
            <ChatBubbleProfessor :message="message" :isLatestBubble="index === chatMessages.length - 1" />
          </div>
        </div>
      </div>

      <!-- before refactor: -->
      <!--    <div class="mt-5 space-y-2" v-for="message in chatMessages">-->
      <!--      &lt;!&ndash; {{message.content}} &ndash;&gt;-->
      <!--      <div v-if="message.role === 'attending' && message.type === 'say'">-->
      <!--        <ChatBubbleAttending :message="message"/>-->
      <!--      </div>-->
      <!--      <div v-else-if="message.role === 'user' && message.type === 'say'">-->
      <!--        <ChatBubbleUser :message="message"/>-->
      <!--      </div>-->
    </div>
  </div>

  <div>
    <ChatInput
      :placeholderMessage="[oralExamInteractionStore.examinerName] + ' etwas antworten/ nachfragen...'"
      :disabled="chatIsStreaming"
      @onSubmit="handleSubmit"
      :allowAudioInput="true"
    />
  </div>
</template>

<!--<template>-->
<!--  <div class="flex-grow overflow-y-auto p-4">-->

<!--    <div class="mt-5 space-y-2" v-for="message in chatMessages">-->
<!--      &lt;!&ndash; {{message.content}} &ndash;&gt;-->
<!--      <div v-if="message.role === 'professor' && message.type === 'say'">-->
<!--        <ChatBubbleProfessor :message="message"/>-->
<!--      </div>-->
<!--      <div v-else-if="message.role === 'user' && message.type === 'say'">-->
<!--        <ChatBubbleUser :message="message"/>-->
<!--      </div>-->

<!--    </div>-->
<!--  </div>-->

<!--  <div class="p-4">-->
<!--    <ChatInput-->
<!--        :placeholderMessage="[professor.name] + ' antworten...'"-->
<!--        @onSubmit="handleSubmit"-->
<!--    />-->
<!--  </div>-->

<!--</template>-->

<style scoped></style>
