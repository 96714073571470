<script setup lang="ts">
import { getApiClient } from '@/apiclient/client';
import TextEditor from '@/views/courses/TextEditor.vue';
import { ref } from 'vue';

const { chapter } = defineProps(['chapter']);
const emit = defineEmits(['unsavedChanges', 'changesCleared']);
const titleEditor = ref(null);
const subtitleEditor = ref(null);

function signalUnsavedChanges() {
  console.log('unsaved changes forwarded');
  emit('unsavedChanges');
}

function signalChangesCleared() {
  console.log('changes cleared forwarded');
  emit('changesCleared');
}

defineExpose({
  titleEditor,
  subtitleEditor,
});
</script>

<template>
  <!-- Blog Article -->
  <div
    class="px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-900 dark:border-gray-700 flex justify-center max-w-6xl"
  >
    <div class="max-w-4xl">
      <!-- Content -->
      <div class="space-y-5 md:space-y-8">
        <div class="space-y-3 justify-center">
          <h2 class="text-4xl text-center font-bold md:text-5xl dark:text-white">
            <TextEditor
              ref="titleEditor"
              :content="chapter.title"
              :allowFormatting="false"
              @unsavedChanges="signalUnsavedChanges"
              @changesCleared="signalChangesCleared"
            />
          </h2>

          <p class="text-base text-gray-800 text-center dark:text-gray-200">
            <TextEditor
              ref="subtitleEditor"
              :allowFormatting="false"
              :content="chapter.description"
              @unsavedChanges="signalUnsavedChanges"
              @changesCleared="signalChangesCleared"
            />
          </p>
        </div>
      </div>
      <!-- End content -->
    </div>
  </div>
  <!-- End Blog Article -->
</template>

<style scoped></style>
